// @flow

import React, { useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HashLoader } from 'react-spinners';

import styles from './account.module.scss';
import Section from '../../components/Section';
// import VerificationInput from '../../components/VerificationCode';
import * as TypeIcons from '../../components/TypeIcons';
import renderHistoryTransaction from './transactions-full';

import metaValues from '../../../config';
import AccountCart from '../../components/AccountCart';
import AccountStat from '../../components/AccountStat';

import ElectricStation from '../../assets/svg/electric-station.svg';

const renderChargeTransactions = (transactions: any = []) =>
  transactions.map(transaction => {
    if (!transaction) {
      return null;
    }

    const {
      id,
      createdAt,
      stopAt,
      isActive,
      kwt: totalKwt,
      Station: {
        title,
        StationPorts: [{ title: portTitle, portType, connectorId }]
      },
      a,
      v,
      data
    } = transaction;

    const { totalMinutesTime, totalIdleMinutesTime } = data || {
      totalKwt: 0,
      totalMinutesTime: 0,
      totalIdleMinutesTime: 0
    };

    // const totalDuration = moment.duration(totalMinutesTime, 'minutes');
    // const totalHours = totalDuration.hours();
    // const totalMinutes = totalDuration.minutes();

    const timeTotal = moment
      .utc()
      .startOf('day')
      .add(totalMinutesTime, 'minutes')
      .format('HH:mm');

    const timeIdle = moment
      .utc()
      .startOf('day')
      .add(totalIdleMinutesTime, 'minutes')
      .format('HH:mm');

    const transactionDateStart = moment(createdAt).format('HH:mm, DD-MM-YYYY');
    const transactionDateStop = moment(stopAt).format('HH:mm, DD-MM-YYYY');

    const Icon = TypeIcons[portType];
    const chargingStyle = a > 0 ? styles.chargeActive : styles.chargeIdle;
    const chargingIcon = a > 0 ? '#55af57' : '#77b9f2';

    return (
      <li key={id}>
        <div className={styles.charge}>
          <div>
            <Icon
              color={isActive ? chargingIcon : 'lightgray'}
              isActive={isActive && a > 0}
              className={styles.icon}
            />
            {isActive && (
              <center className={chargingStyle}>
                {a > 0 ? 'Charging' : 'Idle'}
              </center>
            )}
          </div>
          <div className={styles.info}>
            {isActive && (
              <div>
                <h4>
                  {title} (port{connectorId})
                </h4>
                <ol>
                  <li className={styles.date}>
                    {transactionDateStart} started
                  </li>
                  <li>
                    <span>{totalKwt}</span> KWh / <span>{a}</span>A /
                    <span>{v}</span>V
                  </li>
                  <li>
                    Port - {portTitle} ({connectorId})
                  </li>
                  <li>Duration - {timeTotal}</li>
                  <li>Idle time - {timeIdle}</li>
                </ol>
              </div>
            )}
            {!isActive && (
              <div className={styles.historyItem}>
                <div>
                  <h4>
                    {title} (port{connectorId})
                  </h4>
                  <span>{totalKwt}</span> KWh
                </div>
                <div>
                  Port - {portTitle} ({connectorId})<br />
                  Start - {transactionDateStart}
                  <br />
                  Finish - {transactionDateStop}
                  <br />
                  Duration - {timeTotal}
                  <br />
                  Idle time - {timeIdle}
                  <br />
                </div>
              </div>
            )}
          </div>
        </div>
      </li>
    );
  });

const renderActiveTransactions = (ChargeOCPPTransactions: any) => {
  const activeCharges = ChargeOCPPTransactions
    ? ChargeOCPPTransactions.filter(x => x.isActive)
    : null;

  return (
    <div className={styles.transactions}>
      {ChargeOCPPTransactions && ChargeOCPPTransactions.length > 0 && (
        <div>
          {activeCharges && activeCharges.length > 0 && (
            <div>
              <h2 className={styles.activeCharge}>Active charging sessions</h2>
              <ul>{renderChargeTransactions(activeCharges)}</ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const AccountScreen = (): any => {
  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;
  const { userData, isFulfilled } = useSelector(({ user }: any) => user);
  const [showFull, setShowFull] = useState();
  const [currency, setCurrency] = useState('UAH');
  const navigate = useNavigate();

  if (!isFulfilled || !userData) {
    return (
      <Section className={styles.container}>
        <center className={styles.padding50}>
          <HashLoader />
        </center>
      </Section>
    );
  }

  const {
    ChargeOCPPTransactions = [],
    manageStations = [],
    CurrencyAccounts = []
  } = userData;

  return (
    <Section className={styles.container}>
      <Helmet>
        <title>Account - {defaultTitle}</title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={defaultTitle} />
      </Helmet>

      <div className={styles.link} onClick={() => navigate(-1)}>
        Back
      </div>
      <div className={styles.grid}>
        <div>
          <h1>Account</h1>
          <p>Basic account information</p>
          <AccountCart
            className={styles.accountCart}
            userData={userData}
            currency={currency}
            CurrencyAccounts={CurrencyAccounts}
          />
          <div className={styles.currencyBlock}>
            <div>
              Account currency
              <select
                name="currencyCode"
                defaultValue={currency}
                onChange={({ target: { value } }) => {
                  setCurrency(value);
                }}
              >
                {CurrencyAccounts.map(({ Currency: item }) => (
                  <option
                    key={item.code}
                    value={item.code}
                    selected={item.code === currency}
                  >
                    {item.code}
                  </option>
                ))}
              </select>
            </div>
            {CurrencyAccounts && CurrencyAccounts.length && (
              <div>
                {CurrencyAccounts.map(
                  ({ balance, Currency: { code, sign } }) => (
                    <div>
                      {code} - {balance}
                      {sign}
                    </div>
                  )
                )}
              </div>
            )}
          </div>

          {manageStations.length > 0 && (
            <div className={styles.manageStation}>
              <img src={ElectricStation} alt="Station management" />
              <div className={styles.count}>{manageStations.length}</div>
              <div>
                <p>You have {manageStations.length} station(s) under control</p>
                <Link to="/manage">Go to station management</Link>
              </div>
            </div>
          )}

          <ul>
            <li>
              <Link to="/account/manage">Profile</Link>
            </li>
            <li>
              <Link to="/account/top-up">Top-up</Link>
            </li>
            <li>
              <Link to="/account/payments">Payment operations</Link>
            </li>
            <li>
              <Link to="/account/cards">Cards management</Link>
            </li>
            <li>
              <Link to="/stations/">Charging stations</Link>
            </li>
          </ul>

          {/* <VerificationInput
            length={6}
            onComplete={value => {
              console.log('complete verification');
              console.log(value);
            }}
          />
          */}

          <h3>Recent stations:</h3>
          <ul>
            <li>
              <Link to="/station/TEST-UGV">TEST UGV</Link>
            </li>
            <li>
              <Link to="/station/POLTAVA">UGV Fast Charger</Link>
            </li>
            <li>
              <Link to="/station/1557">Home Charger AE</Link>
            </li>
          </ul>

          {renderActiveTransactions(ChargeOCPPTransactions)}
        </div>

        <div>
          <AccountStat userData={userData} />
          {renderHistoryTransaction({
            ChargeOCPPTransactions,
            showFull,
            setShowFull
          })}
        </div>
      </div>
    </Section>
  );
};

export default AccountScreen;
