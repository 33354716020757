// @flow

import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';

import validationSchema from './validation-schema';
import Button from '../../Button';
import Textarea from '../../Textarea';
import { renderInput } from '../../render-methods';

import { setStationData } from '../../../../lib/api';

import styles from './station-form.module.scss';

/**
 * Props
 */
type Props = {
  data: any,
  values: any
};

const StationForm = (props: Props): any => {
  const { values: initialValues, data } = props;
  const { API_URL } = data;
  const [isSubmitting, setSubmitting] = useState(false);

  const renderSubmitButton = (disabled: boolean) => (
    <Button disabled={disabled || isSubmitting} loading={isSubmitting}>
      Save station information
    </Button>
  );

  const handleSave = async (values: any) => {
    setStationData({
      API_URL,
      values
    });
  };

  return (
    <Formik
      validateOnChange
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values: any, actions: any) => {
        setSubmitting(true);
        await handleSave(values);
        actions.setSubmitting(false);
        setSubmitting(false);
      }}
    >
      {() => (
        <Form className={styles.content}>
          <Field name="title">{renderInput}</Field>
          <Field name="key">
            {(properties: any) =>
              renderInput({
                ...properties,
                placeholder: 'Connection key'
              })
            }
          </Field>
          <Field name="description">
            {({ field }: any) => {
              const { onChange, value, name } = field;
              return (
                <Textarea
                  onChange={onChange}
                  value={value}
                  name={name}
                  className={styles.textarea}
                />
              );
            }}
          </Field>
          {renderSubmitButton(false)}
        </Form>
      )}
    </Formik>
  );
};

export default StationForm;
