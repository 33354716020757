// @flow

import React from 'react';
import moment from 'moment';
import styles from './account.module.scss';
import Button from '../../components/Button';
import * as TypeIcons from '../../components/TypeIcons';

import RenderLightChargeTransactions from './transactions-light';

const renderChargeTransactions = (transactions: any = []) =>
  transactions.map(transaction => {
    if (!transaction) {
      return null;
    }

    const {
      id,
      createdAt,
      totalAmount,
      stopAt,
      isActive,
      Station: {
        title,
        StationPorts: [{ title: portTitle, portType, connectorId }]
      },
      a,
      kwt: totalKwt,
      v,
      moneyData
    } = transaction;

    const { totalDuration, totalIdle } = moneyData || {
      totalKwt: 0,
      totalDuration: 0,
      totalIdle: 0
    };

    const kwt = Number(totalKwt / 1000).toFixed(2);

    // const totalDuration = moment.duration(totalMinutesTime, 'minutes');
    // const totalHours = totalDuration.hours();
    // const totalMinutes = totalDuration.minutes();

    const timeTotal = moment
      .utc()
      .startOf('day')
      .add(totalDuration, 'seconds')
      .format('HH:mm');

    const timeIdle = moment
      .utc()
      .startOf('day')
      .add(totalIdle, 'seconds')
      .format('HH:mm');

    const transactionDateStart = moment(createdAt).format('HH:mm, DD-MM-YYYY');
    const transactionDateStop = moment(stopAt).format('HH:mm, DD-MM-YYYY');

    const Icon = TypeIcons[portType];
    const chargingStyle = a > 0 ? styles.chargeActive : styles.chargeIdle;
    const chargingIcon = a > 0 ? '#55af57' : '#77b9f2';

    return (
      <li key={id}>
        <div className={styles.charge}>
          <div className={styles.icon}>
            <Icon
              color={isActive ? chargingIcon : 'lightgray'}
              isActive={isActive && a > 0}
              className={styles.icon}
            />
            {isActive && (
              <center className={chargingStyle}>
                {a > 0 ? 'Charging' : 'Idle'}
              </center>
            )}
          </div>
          <div className={styles.info}>
            {isActive && (
              <div>
                <h4>
                  {title} (port{connectorId})
                </h4>
                <ol>
                  <li className={styles.date}>
                    {transactionDateStart} started
                  </li>
                  <li>
                    <span>{kwt}</span> KWh / <span>{a}</span>A /<span>{v}</span>
                    V
                  </li>
                  <li>
                    Port - {portTitle} ({connectorId})
                  </li>
                  <li>Duration - {timeTotal}</li>
                  <li>Idle time - {timeIdle}</li>
                </ol>
              </div>
            )}
            {!isActive && (
              <div className={styles.historyItem}>
                <div>
                  <h4>
                    {title} (port{connectorId})
                  </h4>
                  ₴<span>{totalAmount}</span>/<span>{kwt}</span> KWh
                  <div className={styles.showMore}>more details</div>
                </div>
                <div>
                  Port - {portTitle} ({connectorId})<br />
                  Start - {transactionDateStart}
                  <br />
                  Finish - {transactionDateStop}
                  <br />
                  Duration - {timeTotal}
                  <br />
                  Idle time - {timeIdle}
                  <br />
                </div>
              </div>
            )}
          </div>
        </div>
      </li>
    );
  });

const renderHistoryTransaction = ({
  ChargeOCPPTransactions,
  showFull,
  setShowFull
}: any): any => {
  const historyCharges = ChargeOCPPTransactions
    ? ChargeOCPPTransactions.filter(x => !x.isActive)
    : null;

  return (
    <div className={styles.transactions}>
      {ChargeOCPPTransactions && ChargeOCPPTransactions.length > 0 && (
        <div>
          {historyCharges && historyCharges.length > 0 && (
            <div>
              <h2 className={styles.historyCharge}>Charging history</h2>
              <Button onClick={() => setShowFull(!showFull)}>
                {showFull ? 'Show light information' : 'Show full information'}
              </Button>
              <ul className={styles.chargeHistory}>
                {showFull && renderChargeTransactions(historyCharges)}
                {!showFull && (
                  <RenderLightChargeTransactions data={historyCharges} />
                )}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default renderHistoryTransaction;
