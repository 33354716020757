// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';

import { version } from '../../../../package.json';
import styles from './footer.module.scss';

const Footer = (): any => {
  const common = useSelector(({ common: comm }: any) => comm);

  const { theme } = common;

  const heart = (
    <span role="img" className={styles.emojii} aria-label="Heart">
      ❤️
    </span>
  );

  const footerStyle =
    theme === 'light' ? styles.footerLight : styles.footerDark;

  return (
    <div className={footerStyle}>
      <div className={styles.content}>
        <div className={styles.text}>
          Our platform is purpose-built to solve the biggest challenge in the EV
          industry, fragmentation. We do that by consolidating and simplifying
          the technology ecosystem. We are here to transform charging for good.
        </div>
        <div className={styles.title}>
          Made with
          {heart}&nbsp; in Kharkiv, Ukraine (с) 2020-2023 by&nbsp;
          <a href="https://www.reactapp.top" target="_blank" rel="noreferrer">
            REACTAPP.TOP
          </a>
          <br />v{version}
        </div>
      </div>
    </div>
  );
};

export default Footer;
