import _ from 'lodash';
import React from 'react';
import styles from './input.module.scss';

type Props = {|
  type: string,
  tip?: string,
  name?: string,
  value?: string,
  pattern?: string,
  // eslint-disable-next-line react/require-default-props
  fullWidth?: boolean,
  className?: string,
  placeholder: string,
  hasError?: boolean,
  onChange?: any
|};

const Input = (props: Props): any => {
  const {
    hasError = false,
    onChange = () => {},
    tip = '',
    pattern = '',
    name = '',
    value = '',
    className = '',
    fullWidth = false
  } = props;

  const fullWidthStyle = fullWidth ? styles.fullWidth : '';

  const styleName =
    `${styles.inputGroup} ${className} ${fullWidthStyle}`.trim();

  return (
    <div className={styleName}>
      <input
        {..._.omit(props, ['onChange', 'hasError', 'fullWidth'])}
        className={hasError ? styles.hasError : ''}
        pattern={pattern}
        id={name}
        value={value}
        onChange={onChange}
      />
      {tip && <div className={styles.inputTip}>{tip}</div>}
    </div>
  );
};

export default Input;
