// @flow

import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import { PulseLoader } from 'react-spinners';
import Carousel, { Modal, ModalGateway } from 'react-images';

import Button from '../Button';
import styles from './dnd.module.scss';

import { common } from '../../../lib/api';

type Image = {
  id: number,
  width: number,
  height: number,
  size?: number,
  src: string
};

const DragAndDrop = (props: any): any => {
  const { images = [], onChange, API_URL } = props;
  const [isUploading, setUploading] = useState(false);
  const [isError, setError] = useState();
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleFileDrop = async (acceptedFiles: any) => {
    const formData = new FormData();

    acceptedFiles.forEach(file => {
      formData.append('file', file);
      formData.append('title', file.name.replace(/\.[^/.]+$/, ''));
    });

    try {
      setUploading(true);
      const result = await common.upload(formData, API_URL);

      result.forEach(file => {
        const { src, size, width, height, id } = file;

        const isExist = images.filter(x => !!x).find(x => x.src === src);

        if (!isExist) {
          images.push({
            id,
            src,
            size,
            width,
            height
          });
        }
      });

      setError();
      setUploading(false);

      if (onChange) {
        onChange(images);
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleRemove = (key: any) => {
    const response = images.filter(x => x.id !== key);

    if (onChange) {
      onChange(response);
    }
  };

  const openLightbox = (id: any) => {
    const index = images.findIndex(x => x.id === id);
    setPhotoIndex(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => setViewerIsOpen(false);

  const renderImage = (imageData: Image) => {
    const { id, src } = imageData;
    const imageSource = src || imageData;
    return (
      <div key={id} className={styles.imageItem}>
        <Button
          className={styles.deleteButton}
          onClick={() => handleRemove(id)}
        />
        <img
          key={id}
          src={imageSource}
          alt=""
          onClick={() => openLightbox(id)}
        />
      </div>
    );
  };

  const renderImagesArea = () => {
    const imgs = images.filter(x => !!x);

    return (
      <div className={styles.imagesArea}>
        <div className={styles.imageGrid}>
          {imgs && imgs.length > 0 && _.map(imgs, renderImage)}
        </div>

        <ModalGateway>
          {viewerIsOpen && (
            <Modal onClose={closeLightbox}>
              <Carousel currentIndex={photoIndex} views={imgs} />
            </Modal>
          )}
        </ModalGateway>
      </div>
    );
  };

  const {
    additionalText,
    errorMessageText,
    showLoader,
    buttonTitle,
    multiple = false
  } = props;

  if (isError) {
    console.error(isError);
  }

  return (
    <Dropzone
      className={styles.dropArea}
      activeClassName={styles.activeDropArea}
      disabledClassName={styles.disabledDropArea}
      draggable="false"
      disabled={showLoader}
      onDrop={handleFileDrop}
      multiple={multiple}
    >
      {({ getRootProps, getInputProps }) => (
        <div className={styles.dndContainer}>
          {renderImagesArea()}
          {isError && <div>{isError.message}</div>}

          <div {...getRootProps()} className={styles.dndArea} draggable="false">
            <div className={styles.dndInner}>
              {additionalText && (
                <p className={styles.dndText}>{additionalText}</p>
              )}
              <input {...getInputProps()} />
              <div className={styles.btnContainer}>
                <Button size={showLoader ? 'disabledTransparent' : 'medium'}>
                  {buttonTitle}
                </Button>
              </div>
              {isUploading && !isError && (
                <div className={styles.loadingIndicator}>
                  <PulseLoader color="#ffb700" size={10} />
                </div>
              )}
              {errorMessageText && (
                <p className={styles.errorText}>{errorMessageText}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default DragAndDrop;
