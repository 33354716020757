// @flow
/* global process */

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import { PulseLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { trips } from '../../../lib/api';
import styles from './trips.module.scss';
import Button from '../../components/Button';

import translation from '../../../../translate';

const { API_URL = 'https://api.ev-ua.net' } = process.env;

const StoryScreen = (): any => {
  const navigate = useNavigate();
  const common = useSelector(({ common: comm }: any) => comm);
  const preloadTrips = useSelector(({ evtrips }: any) => evtrips);
  const { API_URL: stateAPIURL, language } = common;

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(preloadTrips);

  const renderStories = (list: any): any => {
    const listStories = list || [];

    return (
      <ul className={styles.list}>
        {listStories.map(
          ({
            title,
            images,
            id,
            createdAt,
            description,
            EVRoutePoints,
            EVRouteCountries
          }) => {
            const date = moment(createdAt).format('DD/MM/YYYY, HH:mm');
            const image = images[0] ? images[0].src : '';
            const link = `/${language}/ev-trips/${id}`;

            return (
              <li key={id}>
                {image && (
                  <div className={styles.image}>
                    <Link to={link}>
                      <img src={image} alt={title} />
                    </Link>
                  </div>
                )}
                <div className={styles.info}>
                  <h1>
                    <Link to={link}>{title}</Link>
                  </h1>
                  <div className={styles.description}>{description}</div>
                  <div className={styles.countries}>
                    {EVRouteCountries.map(item => (
                      <span key={item}>{item}</span>
                    ))}
                  </div>
                  <span className={styles.points}>
                    <span>{EVRoutePoints.length}</span>&nbsp;
                    {translation('_CHARGE_POINT', language)}
                  </span>

                  <div className={styles.date}>{date}</div>
                </div>
              </li>
            );
          }
        )}
      </ul>
    );
  };

  const fetchData = async () => {
    setLoading(true);
    const response = await trips.list({}, API_URL || stateAPIURL);
    setLoading(false);

    if (Array.isArray(response)) {
      setData(response);
    }

    return true;
  };

  useEffect(() => {
    const fetch = async () => {
      // You can await here
      await fetchData();
    };

    if (!Array.isArray(data)) {
      fetch();
    }
  }, []);

  return (
    <section className={styles.content}>
      <Helmet>
        <title>EV-Trips - EV-UA.NET</title>
        <meta property="og:title" content="EV-UA.NET" />
        <meta property="og:image" content="/assets/svg/logo.svg" />
        <meta
          property="description"
          content="Charging network in Ukraine - you can be part of the future"
        />
      </Helmet>

      <Button
        className={styles.addButton}
        onClick={() => {
          navigate(`/${language}/stories/add/`);
        }}
      >
        {translation('_ADD_TRIP', language)}
      </Button>
      <h2>{translation('_EV_TRIPS', language)}:</h2>

      {isLoading && <PulseLoader color="#ffb700" size={10} />}

      {Array.isArray(data) && !isLoading && renderStories(data)}
    </section>
  );
};

const fetchData = async (): any => {
  const response = await trips.list({}, API_URL);
  if (response && Array.isArray(response)) {
    return { evtrips: response };
  }

  return [];
};

StoryScreen.fetchData = fetchData;

export default StoryScreen;
