// import React, { useRef, useState } from 'react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import { Editor } from '@tinymce/tinymce-react';
import YouTube from 'react-youtube';

import Section from '../../components/Section';
import Button from '../../components/Button';

import metaValues from '../../../config';

// import EV from '../../assets/img/ev-station.jpg';
// import avatar from '../../assets/svg/avatar.svg';

import styles from './example.module.scss';

/*
const renderTestimonial = () => (
  <div className={styles.testimonial}>
    <img src={EV} className={styles.image} alt="iphone" />

    <div className={styles.author}>
      <img src={avatar} className={styles.avatar} alt="avatar" />

      <div className={styles.text}>
        <div className={styles.date}>01/07/2022, 14:42</div>
        <h4>Some user</h4>

        <p>Very powerfull station - quickly, easy and nicely!</p>
      </div>
    </div>
  </div>
);
*/

const shuffle = a => {
  let j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }

  return a;
};

const fullVideo = [
  '_xe-vBr8VMs', // Moszna
  'DscFFgWtITE', // Primosten
  'iR_YqCfDVVQ', // Gosau
  'au-LN3XHTqU', // Hallstatt
  'jhbCMV5NM6E', // Bled
  'Mdah2qvikww', // Knyaz
  'wOhEf_H_jNs', // Bastei
  '37efoUcOzts', // Rhine
  'v9iWgpf2rcc', // Furkapass
  'D4jQFRb1GKI', // Thun
  '91imRFP0zuo', // Lauterbrunnen
];

const shortVideo = [
  // shorts
  'UJQPKqO__gE', // SHORT Primosten
  'nQxYwn34tNg', // SHORT Krka
  'zNkYzCv5ZpE', // SHORT Bled
  'KIr6MNXNp4U', // SHORT Hallstatt
  'PSiApGwJQQY', // SHORT Trogir
  'mQfusLJ_LdY', // SHORT Bastei
  'ifMuy8Ttxl8', // SHORT Rhine
  'HNUNzc61z5I', // SHORT Knyaz
  'Hh_kPlYD9fk' // SHORT Lauterbrunnen
];

const videoPull = shuffle(
  [].concat(shortVideo, shortVideo, shortVideo, fullVideo)
);

const ExampleScreen = () => {
  const title = 'Example';
  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;

  const [mainEvent, setEvent] = useState();
  const [counter, setCounter] = useState(0);
  const [curr, setCurr] = useState(0);
  const [videoId, setVideoId] = useState(videoPull[curr]);

  // const editorRef = useRef(null);

  const opts = {
    width: 640,
    height: 390,
    playerVars: {
      autoplay: 1
    }
  };

  useEffect(() => {
    if (mainEvent) {
      mainEvent.target.playVideo();
    }
  }, [mainEvent]);

  const setCurrentVideo = currId => {
    setCurr(currId);
    setCounter(counter + 1);
    setVideoId(videoPull[currId]);
    console.log('onEnd event');
    mainEvent.target.playVideo();
  };

  return (
    <section>
      <Helmet>
        <title>
          {title} - {defaultTitle}
        </title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={title} />
      </Helmet>

      <Section className={styles.content}>
        <Link to="/account">Back</Link>
        <h1>{title}</h1>
        <p>
          Current video - {videoPull[curr]} ({curr}/{videoPull.length})
        </p>
        <p>Current count - {counter}</p>
        <Button
          onClick={() => {
            const nextId = curr === 0 ? videoPull.length - 1 : curr - 1;
            setCurrentVideo(nextId);
          }}
        >
          Prev video
        </Button>
        &nbsp; &nbsp;
        <Button
          onClick={() => {
            const nextId = curr === videoPull.length - 1 ? 0 : curr + 1;
            setCurrentVideo(nextId);
          }}
        >
          Next video
        </Button>
        <YouTube
          videoId={videoId}
          opts={opts}
          onEnd={() => {
            const nextId = curr === videoPull.length - 1 ? 0 : curr + 1;
            setCurrentVideo(nextId);
          }}
          onReady={event => {
            setEvent(event);
            console.log('onReady event');
          }}
        />
        {/*
        <Editor
          apiKey="laxg7r1snq00yi1lne299pnfmrvs1udegx2z9ywdk5hd4y96"
          onInit={(evt, editor) => {
            editorRef.current = editor;
          }}
          initialValue="<p>This is the initial content of the editor.</p>"
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'openlink',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'help',
              'wordcount'
            ],
            toolbar:
              'undo redo | blocks | code | source | image |' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
        />

        <ul className={styles.list}>
          <li>{renderTestimonial()}</li>
          <li>{renderTestimonial()}</li>
        </ul>

        */}
      </Section>
    </section>
  );
};

export default ExampleScreen;
