// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';

// import _ from 'lodash';
import { animateScroll as scroll } from 'react-scroll';
import HeadRoom from 'react-headroom';
import _ from 'lodash';
import * as Scroll from 'react-scroll';
import GoogleLogin from 'react-google-login';

import styles from './header.module.scss';

import translation from '../../../../translate';

import {
  googleLoginAction,
  languageChange,
  themeChange,
  userLoggedOut
} from '../../store/actions/auth';

// import MenuIcon from '../../assets/svg/menu.svg';
import LogoIcon from '../../assets/svg/logo.svg';
import UserIcon from '../../assets/svg/avatar.svg';

const isServer = typeof window === 'undefined';

const scrollOption = {
  duration: 200,
  delay: 50,
  smooth: true
};

const handleScrollClick = () => scroll.scrollToTop(scrollOption);
// const handleToFormClick = () => scroller.scrollTo('scrollToForm', scrollOption);

const Header = (): any => {
  // $FlowFixMe
  const dispatch = useDispatch();
  const common = useSelector(({ common: comm }: any) => comm);
  const userStorage = useSelector(({ user }: any) => user);
  const { API_URL } = common;

  const { language, theme } = common;

  // console.log('-- header --');
  // console.log(userStorage);

  const userData = _.get(userStorage, 'userData');

  const {
    firstName,
    lastName,
    isPremium,
    manageStations = [],
    isPending
  } = userData || {};

  const handleGoogleLogin = (data: any) => {
    dispatch(googleLoginAction(API_URL, data));
  };

  const handleLogout = () => {
    dispatch(userLoggedOut(API_URL));
    // history('/');
  };

  const handleUrlChange = (lng: string) => {
    if (!isServer) {
      const { pathname } = window.location;
      const newPathname = pathname.replace(/^\/[a-z]{2}/, `/${lng}`);
      window.history.pushState({}, '', newPathname);
    }
  };

  const languages = ['en', 'ua', 'de', 'pl'];
  const currLanguage =
    languages.filter(lng => common.language === lng)[0] || '';

  return (
    <div>
      <div className={styles.topBar} onClick={handleScrollClick}>
        <div className={styles.arrow} onClick={handleScrollClick} />
      </div>
      <HeadRoom
        pinStart={30}
        className={styles.headroom}
        style={{
          transition: 'all 0.5s ease-in-out'
        }}
      >
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <header className={styles.header}>
              <Link to={`/${common.language}/`} className={styles.logo}>
                <img
                  src={LogoIcon}
                  className={styles.logoIcon}
                  alt="EV-Map builder"
                />
                {/* <LogoIcon className={styles.logoIcon} /> */}
              </Link>
              <div className={styles.languageBlock}>
                <span>{currLanguage.toUpperCase()}</span>
                <ul className={styles.languages}>
                  {languages.map(lng => (
                    <li
                      key={lng}
                      className={lng === common.language ? styles.active : ''}
                      onClick={() => {
                        handleUrlChange(lng);
                        dispatch(languageChange(lng));
                      }}
                    >
                      {lng.toUpperCase()}
                    </li>
                  ))}
                </ul>
              </div>
              {/*
              <ul className={styles.menuItems}>
                {menuItems.map(({ link, title }) => (
                  <li key={title}>
                    <Link
                      to={link}
                      onClick={() =>
                        Scroll.animateScroll.scrollToTop({ duration: 100 })
                      }
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
              */}

              {isPending && <PulseLoader color="#ffb700" size={10} />}

              {!isPending && firstName && (
                <div className={styles.userButton}>
                  <div className={styles.avatar}>
                    <img src={UserIcon} alt="avatar" />
                  </div>
                  <div className={styles.user}>
                    <div className={styles.name}>
                      {firstName} {lastName}
                    </div>
                    <p onClick={() => handleLogout()}>
                      {translation('_LOGOUT', language)}
                    </p>
                  </div>
                  <div className={styles.userMenu}>
                    <ul>
                      {firstName && (
                        <div>
                          <li>
                            <Link
                              to="/account"
                              onClick={() =>
                                Scroll.animateScroll.scrollToTop({
                                  duration: 100
                                })
                              }
                            >
                              {translation('_ACCOUNT', language)}
                            </Link>
                          </li>
                          <div className={styles.premiumBlock}>
                            <Link
                              to={`/${common.language}/premium`}
                              onClick={() =>
                                Scroll.animateScroll.scrollToTop({
                                  duration: 100
                                })
                              }
                            >
                              {isPremium && (
                                <p className={styles.premium}>
                                  {translation('_PREMIUM_ACCOUNT', language)}
                                </p>
                              )}
                              {!isPremium && (
                                <p className={styles.regular}>
                                  {translation('_REGULAR_ACCOUNT', language)}
                                </p>
                              )}
                            </Link>
                          </div>
                        </div>
                      )}

                      <li className={styles.separator}>
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            dispatch(
                              themeChange(theme === 'light' ? 'dark' : 'light')
                            );
                          }}
                        >
                          Switch to {theme === 'light' ? 'dark' : 'light'} theme
                        </div>
                      </li>

                      <li className={styles.separator}>
                        {translation('_EV_TRIPS', language)}
                      </li>

                      <li>
                        <Link
                          to={`/${common.language}/ev-hotels`}
                          onClick={() =>
                            Scroll.animateScroll.scrollToTop({
                              duration: 100
                            })
                          }
                        >
                          {translation('_EV_HOTELS', language)}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${common.language}/stories`}
                          onClick={() =>
                            Scroll.animateScroll.scrollToTop({
                              duration: 100
                            })
                          }
                        >
                          {translation('_MY_TRIPS', language)}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${common.language}/map-route`}
                          onClick={() =>
                            Scroll.animateScroll.scrollToTop({
                              duration: 100
                            })
                          }
                        >
                          {translation('_TRIP_PLANNER', language)}
                        </Link>
                      </li>

                      {manageStations.length > 0 && (
                        <div>
                          <li className={styles.separator}>Management</li>
                          <li>
                            <Link
                              to="/manage"
                              onClick={() =>
                                Scroll.animateScroll.scrollToTop({
                                  duration: 100
                                })
                              }
                            >
                              {translation('_MY_STATIONS', language)}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/energy"
                              onClick={() =>
                                Scroll.animateScroll.scrollToTop({
                                  duration: 100
                                })
                              }
                            >
                              {translation('_MY_ENERGY', language)}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/manage/tarifs"
                              onClick={() =>
                                Scroll.animateScroll.scrollToTop({
                                  duration: 100
                                })
                              }
                            >
                              {translation('_MY_TARIFFS', language)}
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/charging"
                              onClick={() =>
                                Scroll.animateScroll.scrollToTop({
                                  duration: 100
                                })
                              }
                            >
                              {translation('_CHARGING', language)}
                            </Link>
                          </li>
                        </div>
                      )}

                      <li className={styles.separator}>Other</li>
                      <li>
                        <Link
                          to={`/${common.language}/ev-friendly`}
                          onClick={() =>
                            Scroll.animateScroll.scrollToTop({
                              duration: 100
                            })
                          }
                        >
                          {translation('_EV_FRIENDS', language)}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/${common.language}/map`}
                          onClick={() =>
                            Scroll.animateScroll.scrollToTop({
                              duration: 100
                            })
                          }
                        >
                          {translation('_STATIONS_MAP', language)}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              {!isPending && !firstName && (
                <div className={styles.loginButton}>
                  <GoogleLogin
                    className={styles.googleBtn}
                    clientId="950726623238-iu1u46r5iapfbs4krnsigciabsgkdhog.apps.googleusercontent.com"
                    buttonText="Login with Google"
                    onSuccess={handleGoogleLogin}
                    onFailure={handleGoogleLogin}
                    cookiePolicy="single_host_origin"
                  />
                </div>
              )}
            </header>
          </div>
        </div>
      </HeadRoom>
    </div>
  );
};

export default Header;
