// @flow

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
// import { Link, useParams, useNavigate } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HashLoader } from 'react-spinners';
import * as Scroll from 'react-scroll';
import _ from 'lodash';

import Section from '../../../components/Section';
import Ports from '../../../components/Ports';
import metaValues from '../../../../config';
import StationForm from '../../../components/_smart/StationForm';

import { getStationInfo, setStationPorts } from '../../../../lib/api';

import styles from './station-manage.module.scss';

const ManageStationScreen = (): any => {
  const { stationId } = useParams();
  // const history = useNavigate();

  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;
  const user = useSelector(({ user: userData }: any) => userData);
  const { API_URL } = useSelector(({ common: comm }: any) => comm);
  const { isFulfilled } = user;
  const [station, setStation] = useState();
  const [isSubmit, setSubmit] = useState(false);
  const [showSaved, setShowSaved] = useState(false);

  const handleSave = async (payload: any) => {
    await setStationPorts({ API_URL, values: payload });
  };

  useEffect(() => {
    // if (!stationInfo) {
    //   history('/manage');
    // }

    const fetchData = async () => {
      const data = await getStationInfo({ API_URL, slug: stationId });
      console.log(data);
      setStation(data);
    };

    if (stationId) {
      console.log('fetch data');
      fetchData();
    }
  }, []);

  const ports = _.get(station, 'StationPorts', []);
  const stationInformation = station;

  if (!isFulfilled || !ports.length) {
    return (
      <Section>
        <div className={styles.content}>
          <Link to="/manage">Back to account</Link>
          <h1>Station management</h1>

          <center>
            <HashLoader />
          </center>
        </div>
      </Section>
    );
  }

  return (
    <Section>
      <Helmet>
        <title>Manage station - {defaultTitle}</title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={defaultTitle} />
      </Helmet>

      <div className={styles.content}>
        <Link
          to="/manage"
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to stations
        </Link>
        <h1>Information</h1>
        {/*
        <Link
          to={`/manage/tarifs/${stationId}`}
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Manage tarifs
        </Link>
      */}

        <StationForm values={stationInformation} data={{ API_URL }} />
        <Ports
          ports={ports}
          submitting={isSubmit}
          showSaved={showSaved}
          onSubmit={async values => {
            setSubmit(true);
            await handleSave({ stationId, config: values });
            setShowSaved(true);
            setSubmit(false);
            setTimeout(() => setShowSaved(false), 2000);
          }}
        />
      </div>
    </Section>
  );
};

ManageStationScreen.fetchData = () => {
  console.log('prefetch');
};

export default ManageStationScreen;
