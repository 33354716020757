import typeToReducer from 'type-to-reducer';

import { SET_STATIONS } from '../actions/types';
// import { DEFAULT_ASYNC_STATE } from './constants';

const DEFAULT_STATE = {};

// const DEFAULT_STATE = {
//   ...DEFAULT_ASYNC_STATE
// };

const stationReducer = typeToReducer(
  {
    [SET_STATIONS]: (state, action) => ({
      ...state,
      ...action.payload
    })
  },

  // {
  //   [SET_STATIONS]: {
  //     PENDING: () => ({
  //       ...DEFAULT_STATE,
  //       isPending: true
  //     }),
  //     REJECTED: (state, action) => ({
  //       ...DEFAULT_STATE,
  //       isRejected: true,
  //       isPending: false,
  //       error: action.payload
  //     }),
  //     FULFILLED: (state, action) => ({
  //       ...DEFAULT_STATE,
  //       ...state,
  //       isFulfilled: true,
  //       isPending: false,
  //       ...action.payload
  //     }),
  //     RESET: () => DEFAULT_STATE
  //   }
  // },
  DEFAULT_STATE
);

export default stationReducer;
