import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { PulseLoader } from 'react-spinners';
import { useParams, Link } from 'react-router-dom';
import simplifyRoute from 'simplify-js';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Modal } from 'react-responsive-modal';
import * as Scroll from 'react-scroll';

import translation from '../../../../translate';

import Input from '../../components/Input';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import ButtonSwitch from '../../components/ButtonSwitch';
import Section from '../../components/Section';

import MapView from './_map-view';
import Points from './_points';
import Route from './_route';
import PlaceComponent from './_place';
import styles from './_map.module.scss';

import { routes } from '../../../lib/api';

const points = [];

const renderUserRoutes = ({ userRoutes, isLoading, onClick, onDelete }) => {
  if (isLoading) {
    return (
      <div>
        <PulseLoader color="#ffb700" size={10} />
      </div>
    );
  }

  if (userRoutes && userRoutes.length > 0) {
    return (
      <ul className={styles.routeList}>
        {userRoutes.map(route => {
          const date = moment(route.createdAt).format('DD.MM.YYYY HH:mm');

          return (
            <li key={route.id}>
              <Link to={`/map-route/${route.id}`} target="_blank">
                <span className={styles.icon} />
              </Link>
              <span className={styles.title} onClick={() => onClick(route)}>
                {route.title} <span>{route.createdAt ? `(${date})` : ''}</span>
              </span>
              <span
                className={styles.deleteBtn}
                onClick={() => onDelete(route.id)}
              >
                x
              </span>
            </li>
          );
        })}
      </ul>
    );
  }

  return null;
};

const optimizeRoute = listPoints => {
  if (listPoints && listPoints.length < 50) {
    return listPoints;
  }

  // console.log(listPoints);

  const xyRoute = listPoints.map(({ lat, lng }) => ({ x: lat, y: lng }));
  const simplifiedRoute = simplifyRoute(xyRoute, 0.001)
    .map(({ x, y }) => ({
      lat: x,
      lng: y
    }))
    .filter(x => !!x)
    .filter(({ lat, lng }) => lat && lng);

  // console.log(simplifiedRoute);

  const tempResult = simplifiedRoute
    .map(({ lat, lng }) => {
      const latValue = typeof lat === 'function' ? lat() : lat;
      const lngValue = typeof lng === 'function' ? lng() : lng;

      try {
        const result = {
          lat: Number(latValue.toFixed(5)),
          lng: Number(lngValue.toFixed(5))
        };

        return result;
      } catch (e) {
        console.error({ lat, lng });
        console.error(e);
        return null;
      }
    })
    .filter(x => !!x);

  // console.log(simplifiedRoute);
  console.log(
    `[ROUTE OPTIMIZATION] Before - ${listPoints.length} point, after - ${tempResult.length} points`
  );

  return tempResult;
};

const deg2rad = deg => deg * (Math.PI / 180);

const getDistanceFromLatLonInKm = (
  { lat: lat1, lng: lon1 },
  { lat: lat2, lng: lon2 }
) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km

  return d;
};

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};

const RenderSuggestions = () => (
  <div className={styles.suggestions}>
    <br />
    <h2>To build comfortable route:</h2>
    <br />
    <ul>
      <li>1. You need to add key points (towns) to build the route</li>
      <li>2. Choose average distance on one charge (100-500km)</li>
      <li>3. Choose minimal power of the charger (50-300kW)</li>
      <li>4. Choose distance limit to search for chargers (1-10km)</li>
      <li>5. Learn the route and save it for future use</li>
    </ul>
  </div>
);

const MapRouteScreen = () => {
  const { id: preloadId } = useParams();
  const [directions, setDirections] = useState();
  const [id, setId] = useState();
  const [loadTitle, setLoadTitle] = useState();
  const [title, setTitle] = useState();
  const [places, setPlaces] = useState(points);
  const [loadedPlaces, setLoadedPlaces] = useState();
  const [isSaving, setSaving] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [userRoutes, setUserRoutes] = useState();
  const [markers, setMarkers] = useState();
  const [showModal, setShowModal] = useState();
  const [showLoadModal, setShowLoadModal] = useState();
  const [minimalPower, setMinimalPower] = useState();
  const [avoidTolls, setAvoidTolls] = useState(false);
  const [avoidHighways, setAvoidHighways] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const { API_URL, language } = useSelector(state => state.common);
  const { isLoggedIn, userData } = useSelector(({ user }) => user);
  const { googleKey } = userData || {};
  const { isPremium: isPremiumUser, id: userId } = userData || {};
  const [distanceLimit, setDistanceLimit] = useState(isPremiumUser ? 2 : 10);
  const [rangeLimit, setRangeLimit] = useState(isPremiumUser ? 100 : 200);
  const saveEnabled = !_.isEqual(places, loadedPlaces) || title !== loadTitle;

  const handleDelete = index => {
    const newPlaces = places.filter((item, i) => i !== index);

    setPlaces(newPlaces);
    setDirections(null);
    // directionsRenderer.setMap(null);
    // directionsRenderer.set('directions', null);
  };

  const handleLoad = routeInfo => {
    const { id: routeId, title: routeTitle } = routeInfo;
    const route = userRoutes.find(item => item.id === routeId);

    if (route) {
      console.log('handleLoad', routeId);
      // console.log(routeInfo);
      setInProgress(true);

      const { points: pInfo, options = {} } = routeInfo;
      const {
        avoidTolls: tollsValue = true,
        rangeLimit: rangeValue = isPremiumUser ? 100 : 200,
        minimalPower: powerValue = 100
      } = options;

      // console.log(options);

      setDirections(null);
      setId(routeId);
      setTitle(routeTitle);
      setLoadTitle(routeTitle);
      setPlaces(pInfo);
      // setMarkers();
      setLoadedPlaces(pInfo);
      setShowLoadModal(false);
      setAvoidTolls(tollsValue);
      setRangeLimit(rangeValue);
      setMinimalPower(powerValue);

      window.history.replaceState(
        null,
        routeTitle,
        `/${language}/map-route/${routeId}`
      );

      Scroll.animateScroll.scrollToTop({ duration: 100 });

      setInProgress(false);
    }
  };

  const handleDeleteRoute = async (routeId: string) => {
    await routes.remove({ API_URL, id: routeId });
    const newRoutes = userRoutes.filter(item => item.id !== routeId);
    setUserRoutes(newRoutes);
    console.log(`delete route id - ${routeId}`);
  };

  const handleSave = async payload => {
    const { title: newTitle, directions: directionPool } = payload;

    setSaving(true);

    const pool = directionPool
      .map(({ steps }) => steps.map(({ lat_lngs }) => lat_lngs))
      .flat()
      .flat();

    const optimizedRoute = optimizeRoute(pool);

    // eslint-disable-next-line no-param-reassign
    payload.directions = optimizedRoute;

    const data = await routes.saveRoute(payload, API_URL);

    if (!id && data && data.id) {
      setId(data.id);
    }

    if (newTitle) {
      setLoadTitle(newTitle);
    }

    // update routes list

    setSaving(false);
    const newRoutes = [].concat(data, userRoutes);
    setUserRoutes(newRoutes);
    // setLoadedPlaces(places);
    setShowModal(false);
  };

  const renderSaveModal = () => (
    <Modal
      open={showModal}
      animationDuration={0}
      onClose={() => setShowModal(false)}
      classNames={{
        overlay: styles.modalOverlay,
        modal: styles.modal
      }}
      center
    >
      <h2>{translation('_SAVE_TITLE', language)}</h2>
      <p>{translation('_SAVE_ROUTE_TEXT', language)}</p>

      {directions && (
        <div className={styles.saveBlock}>
          <center>
            <Input
              value={title}
              name="route-name"
              placeholder="Route name"
              onChange={({ target: { value } }) => setTitle(value)}
            />
            <Button
              disabled={!saveEnabled}
              loading={isSaving}
              onClick={() => {
                handleSave({
                  API_URL,
                  id,
                  title,
                  directions,
                  options: {
                    minimalPower,
                    rangeLimit,
                    distanceLimit,
                    avoidTolls,
                    avoidHighways
                  },
                  points: places
                });
              }}
            >
              Save route
            </Button>
          </center>
        </div>
      )}
    </Modal>
  );

  const renderLoadModal = () => (
    <Modal
      open={showLoadModal}
      animationDuration={0}
      onClose={() => setShowLoadModal(false)}
      classNames={{
        overlay: styles.modalOverlay,
        modal: styles.modal
      }}
      center
    >
      <h2>{translation('_LOAD_TITLE', language)}</h2>
      <br />
      <div
        dangerouslySetInnerHTML={{
          __html: translation('_LOAD_DESC', language)
        }}
      />

      {userRoutes && userRoutes.length > 0 && (
        <div className={styles.savedArea}>
          {renderUserRoutes({
            userRoutes,
            isLoading,
            onDelete: handleDeleteRoute,
            onClick: handleLoad
          })}
        </div>
      )}

      {userRoutes && userRoutes.length === 0 && (
        <p>{translation('_NO_ROUTES', language)}</p>
      )}
    </Modal>
  );

  const fetchData = async () => {
    if (userId) {
      setLoading(true);
      const data = await routes.list(API_URL);
      setUserRoutes(data);
      setLoading(false);
    }
  };

  const routeDraw = [];

  const matchDirection = async (directionsData = []) => {
    setInProgress(true);

    if (directionsData.length > 0) {
      directionsData.forEach(item => {
        const { steps } = item;
        steps.forEach(step => {
          const { lat_lngs: latLngs } = step;

          latLngs.forEach(({ lat, lng }) => {
            const lt = Number(lat().toFixed(6));
            const lg = Number(lng().toFixed(6));

            routeDraw.push({ lat: lt, lng: lg });
          });
        });
      });

      const optimizedRoute = optimizeRoute(routeDraw);

      console.log('routes.match');

      // TODO: await call to api
      const response = await routes.match(
        {
          route: optimizedRoute,
          places,
          rangeLimit,
          distanceLimit,
          powerLimit: minimalPower
        },
        API_URL
      );

      const newMarkers = response
        .map(({ suggestions }) =>
          suggestions.map(item => {
            const { latitude, longitude } = item;

            return {
              lat: latitude,
              lng: longitude,
              ...item
            };
          })
        )
        .flat();

      const uniqueResults = [];

      newMarkers.forEach(item => {
        const isExists = uniqueResults.find(
          x => x.lat === item.lat && x.lng === item.lng
        );

        if (!isExists) {
          uniqueResults.push(item);
        }
      });

      console.debug(`Before unique sorting - ${newMarkers.length}`);
      console.debug(`After unique sorting - ${uniqueResults.length}`);

      setMarkers(newMarkers);
      setInProgress(true);
    }
  };

  const findNearPoint = point => {
    // console.log('marker click');

    const { lat, lng } = point;
    // console.log(point);

    let insertIndex = -1;
    let minPlaceDistance = 10000;

    places.forEach(onePlace => {
      const { lat: placeLat, lng: placeLng } = onePlace;
      const distance = getDistanceFromLatLonInKm(
        { lat, lng },
        { lat: placeLat, lng: placeLng }
      );

      if (distance < minPlaceDistance) {
        minPlaceDistance = distance;
        insertIndex = places.indexOf(onePlace);
      }
    });

    if (insertIndex > -1) {
      if (insertIndex === places.length - 1) {
        insertIndex -= 1;
      }

      if (!point.title) {
        if (point.isTesla) {
          // eslint-disable-next-line no-param-reassign
          point.title = `${point.name}, Tesla Supercharger (${point.maxPower}kW)`;
        } else {
          // eslint-disable-next-line no-param-reassign
          point.title = `${point.city} (${point.network}, ${point.maxPower}kW)`;
        }
      }

      const newPlaces = [].concat(places);
      newPlaces.splice(insertIndex + 1, 0, {
        lat: point.lat,
        lng: point.lng,
        title: point.title
      });

      setPlaces(newPlaces);
      setDirections(null);
    }
  };

  useEffect(() => {
    if (
      !points.length &&
      preloadId &&
      userRoutes &&
      userRoutes.length > 0 &&
      !inProgress
    ) {
      const route = userRoutes.find(x => x.id === preloadId);

      if (route) {
        handleLoad(route);
      }
    }
  }, [userRoutes, inProgress]);

  useEffect(() => {
    if (!userRoutes && userId) {
      fetchData();
    }

    return () => {
      setUserRoutes([]);
      setId(null);
      setMarkers();
      setTitle('');
    };
  }, [userId, preloadId]);

  const debouncedRangeLimit = useDebouncedValue(rangeLimit, 1000);
  const debouncedDistanceLimit = useDebouncedValue(distanceLimit, 1000);

  // console.log('debouncedRangeLimit', debouncedRangeLimit);
  // console.log('debouncedDistanceLimit', debouncedDistanceLimit);
  // console.log(directions);

  useEffect(() => {
    // TODO: place where we can call API
    // or separate useEffect on places change
    // console.log(places);
    // console.log('directions', directions);

    if (directions && userId) {
      if (!inProgress) {
        matchDirection(directions);
      } else {
        setTimeout(() => {
          matchDirection(directions);
        }, 1000);
      }
    }
  }, [
    minimalPower,
    // rangeLimit, // add debounce here
    // distanceLimit, // add debounce here
    debouncedRangeLimit,
    debouncedDistanceLimit,
    avoidTolls,
    avoidHighways
  ]);

  if (!isLoggedIn || !userId) {
    return (
      <Section>
        <div className={styles.content}>
          <center
            dangerouslySetInnerHTML={{
              __html: translation('_MAP_ROUTE_LOGGED', language)
            }}
          />

          <div className={styles.notAuth}>
            <RenderSuggestions />
          </div>
        </div>
      </Section>
    );
  }

  return (
    <Section fullwidth>
      <Helmet>
        <title>{translation('_PAGE_HEAD_TITLE', language)} - EV-UA.net</title>
        <meta
          name="description"
          content="You can easy build your routes and see chargers on the route in one place"
        />
        <meta
          property="keywords"
          content="charging map, route builder, charging route map, charging route points"
        />
        <meta property="og:title" content="Route map builder" />
      </Helmet>

      <div className={styles.content}>
        {renderSaveModal()}
        {renderLoadModal()}

        {places && places.length > 1 && (
          <MapView
            wayPoints={places}
            markers={markers}
            avoidTolls={avoidTolls}
            isPremiumUser={isPremiumUser}
            isCalculated={directions}
            avoidHighways={avoidHighways}
            onAddMarkerClick={findNearPoint}
            onCalculated={async routeInfo => {
              // console.log(routeInfo);
              if (routeInfo) {
                // console.log('routeInfo');
                // console.log(routeInfo);
                setDirections(routeInfo);
                await matchDirection(routeInfo);
              }
            }}
          />
        )}

        <Section className={styles.mainSection}>
          {places && places.length <= 1 && <RenderSuggestions />}

          <div className={styles.info}>
            <div className={styles.points}>
              <Button
                className={styles.loadSaveBtn}
                onClick={() => {
                  setShowLoadModal(true);
                }}
              >
                {translation('_LOAD', language)}
              </Button>

              {directions && (
                <Button
                  className={styles.loadSaveBtn}
                  onClick={() => setShowModal(true)}
                >
                  {translation('_SAVE', language)}
                </Button>
              )}

              <h3>{translation('_MAP_ROUTE_ADD_ROUTE_POINT', language)}</h3>
              <PlaceComponent
                googleMapsApiKey={googleKey}
                onSelect={place => {
                  const newPlaces = [].concat(places, place);
                  setPlaces(newPlaces);
                  // TODO: recalculating route
                  setDirections(null);
                }}
              />

              {places.length <= 1 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: translation('_MAP_ROUTE_ADD_ROUTE_TEXT', language)
                  }}
                />
              )}

              {places.length > 1 && (
                <div className={isPremiumUser ? '' : styles.premiumFeature}>
                  {!isPremiumUser && (
                    <p className={styles.noticePremium}>
                      Only <Link to="/premium">Premium</Link> users can edit
                      these values and recalculate the route
                    </p>
                  )}

                  <div className={styles.powerLimit}>
                    <h4>{translation('_MAP_ROUTE_POWER_TYPE', language)}</h4>
                    <ButtonSwitch
                      pressed
                      className={styles.buttonSwitch}
                      // onChange={() => {
                      //   setMinimalPower(1);
                      // }}
                    >
                      CCS2
                    </ButtonSwitch>

                    <ButtonSwitch className={styles.buttonSwitch}>
                      GB/T
                    </ButtonSwitch>
                    <ButtonSwitch className={styles.buttonSwitch}>
                      CHADEMO
                    </ButtonSwitch>
                    <ButtonSwitch className={styles.buttonSwitch}>
                      Type 2
                    </ButtonSwitch>
                  </div>

                  <div className={styles.powerLimit}>
                    <h4>{translation('_MAP_ROUTE_POWER_LIMIT', language)}</h4>
                    <ButtonSwitch
                      pressed={minimalPower === 1}
                      className={styles.buttonSwitch}
                      onChange={() => {
                        setMinimalPower(1);
                      }}
                    >
                      All
                    </ButtonSwitch>

                    <ButtonSwitch
                      pressed={minimalPower === 50}
                      className={styles.buttonSwitch}
                      onChange={() => {
                        setMinimalPower(50);
                      }}
                    >
                      50+
                    </ButtonSwitch>
                    <ButtonSwitch
                      pressed={minimalPower === 100}
                      className={styles.buttonSwitch}
                      onChange={() => {
                        setMinimalPower(100);
                      }}
                    >
                      100+
                    </ButtonSwitch>
                    <ButtonSwitch
                      pressed={minimalPower === 150}
                      className={styles.buttonSwitch}
                      onChange={() => {
                        setMinimalPower(150);
                      }}
                    >
                      150+
                    </ButtonSwitch>
                    <ButtonSwitch
                      pressed={minimalPower === 200}
                      className={styles.buttonSwitch}
                      onChange={() => {
                        setMinimalPower(200);
                      }}
                    >
                      200+
                    </ButtonSwitch>
                  </div>

                  <div className={styles.distanceLimit}>
                    <h4>
                      {translation('_MAP_ROUTE_DISTANCE_LIMIT', language)}
                    </h4>
                    <div className={styles.column2Layout}>
                      <Input
                        value={distanceLimit}
                        name="distance-limit"
                        disabled={!isPremiumUser}
                        placeholder="Distance limit"
                        onChange={({ target: { value } }) =>
                          setDistanceLimit(Number(value))
                        }
                      />
                      <div className={styles.text}>
                        {translation(
                          '_MAP_ROUTE_DISTANCE_LIMIT_TEXT',
                          language
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.chargeDistance}>
                    <h4>
                      {translation('_MAP_ROUTE_DISTANCE_LIMIT', language)}
                    </h4>
                    <div className={styles.column2Layout}>
                      <Input
                        value={rangeLimit}
                        name="distance-limit"
                        disabled={!isPremiumUser}
                        placeholder="Distance limit"
                        onChange={({ target: { value } }) => {
                          setRangeLimit(Number(value));
                        }}
                      />
                      <div className={styles.text}>
                        {translation(
                          '_MAP_ROUTE_DISTANCE_CHARGE_LIMIT_TEXT',
                          language
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.distanceLimit}>
                    <h4>{translation('_MAP_ROUTE_OPTIONS', language)}</h4>
                    <br />
                    <Checkbox>
                      {translation('_MAP_ROUTE_OPTION_1', language)}
                    </Checkbox>
                    <Checkbox
                      checked={avoidTolls}
                      onChange={() => {
                        setDirections(null);
                        setAvoidTolls(!avoidTolls);
                      }}
                    >
                      {translation('_MAP_ROUTE_OPTION_2', language)}
                    </Checkbox>
                    <Checkbox
                      checked={avoidHighways}
                      onChange={() => {
                        setDirections(null);
                        setAvoidHighways(!avoidHighways);
                      }}
                    >
                      {translation('_MAP_ROUTE_OPTION_3', language)}
                    </Checkbox>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.steps}>
              <div className={styles.column}>
                <Points
                  points={places}
                  useDrag
                  language={language}
                  onDelete={handleDelete}
                  onChange={data => {
                    // console.log(data);
                    setPlaces(data);
                    setDirections(null);
                  }}
                />
              </div>

              <div>
                {places.length > 1 && directions && (
                  <Route
                    points={directions}
                    places={places}
                    language={language}
                  />
                )}

                {directions && (
                  <center>
                    <Button
                      onClick={() => {
                        setPlaces([]);
                        setTitle('');
                        setId(null);
                        setDirections(null);
                        Scroll.animateScroll.scrollToTop({ duration: 100 });
                      }}
                    >
                      {translation('_CLEAR_ROUTE', language)}
                    </Button>
                  </center>
                )}

                {places.length === 1 && (
                  <div className={styles.notice}>
                    <p>To build a route please add more that 1 point</p>
                    <p>
                      To change points order you can drag them and change the
                      order
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <center className={styles.addChargeStation}>
            To add station - please visit&nbsp;
            <Link to="/chargers/how-to-add">How to add charger</Link>
            <br />
            <br />
            Or you can send us a letter with information.
          </center>
        </Section>
      </div>
    </Section>
  );
};

export default MapRouteScreen;
