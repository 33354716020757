const translate = {
  _HOME_HEAD_TITLE: 'Odkryj świat bez emisji',
  _HOME_HEAD_BUTTON1: 'Sprawdź zakończone podróże',
  _HOME_HEAD_BUTTON2: 'Zacznij planować podróż',

  _HOME_HEAD_SECOND_TITLE: 'Poznaj ładowanie w okolicy',
  _HOME_HEAD_SECOND_DESC: `<p>Użyj jednego konta dla wszystkich zmieniających się sieci wokół ciebie!</p><p>Możesz łatwo znaleźć, podłączyć, zapłacić i naładować samochód za pomocą naszej niesamowitej aplikacji - wszystko, czego potrzebujesz, to</p> `,

  _HOME_HEAD_THIRD_TITLE: `Oszczędź <span>CZAS</span>`,
  _HOME_HEAD_FOURTH_TITLE: `i <span>PIENIĄDZE</span>`,

  _HOME_WOW:
    `<p>Pomagamy samochodom elektrycznym znaleźć najbliższą stację ładowania w całej Ukrainie + Europie i korzystać z tego jednego konta we wszystkich sieciach ładowania.</p>` +
    `<p>Możesz przeglądać ostatnie sesje ładowania, aby określić, kiedy ostatnio ta stacja była używana przez inne samochody - pomoże ci to uniknąć odwiedzania niedziałających stacji na drodze.</p>`,

  _HOME_STATIONS: `Stacje`,
  _HOME_OUTSIDE_UKRAINE: `Ładowanie poza Ukrainą`,
  _HOME_FIND_NEAR: `Znajdź ładowarkę w pobliżu`,

  _MAP_ROUTE_ADD_ROUTE_POINT: `Dodaj punkt trasy`,
  _MAP_ROUTE_ADD_ROUTE_TEXT: `<p>Nowy punkt zostanie dodany na początku trasy i później będzie można ułożyć łańcuch punktów</p>`,
  _MAP_ROUTE_POWER_LIMIT: `Ograniczenie mocy:`,
  _MAP_ROUTE_DISTANCE_LIMIT: `Limit odległości:`,
  _MAP_ROUTE_DISTANCE_LIMIT_TEXT: `Maksymalna odległość do stacji od trasy (km)`,
  _MAP_ROUTE_DISTANCE_CHARGE_LIMIT: `Opłata za odległość do naliczenia:`,
  _MAP_ROUTE_DISTANCE_CHARGE_LIMIT_TEXT: `Średnia odległość między stacjami ładowania (km)`,
  _MAP_ROUTE_OPTIONS: 'Opcje:',
  _MAP_ROUTE_OPTION_1: 'Preferuj płatne stacje',
  _MAP_ROUTE_OPTION_2: 'Unikaj opłat drogowych',
  _MAP_ROUTE_OPTION_3: 'Unikaj autostrad',

  _CLEAR_ROUTE: 'Wyczyść trasę',
  _NO_ROUTES: 'Nie masz zapisanych tras',
  _SAVE_ROUTE: 'Zapisz trasę',
  _SAVE_ROUTE_TEXT:
    'Zapisaną trasę można później wczytać i zmienić. Możesz zapisać trasę ze wszystkimi przystankami i informacjami o opłatach.',
  _PAGE_HEAD_TITLE: 'Kreator map tras',
  _MAP_ROUTE_LOGGED:
    '<h1>Uwaga!</h1><p>Aby korzystać z tej sekcji, musisz się zalogować</p>',
  _LOAD: 'Załaduj',
  _ZAPISZ: 'Zapisz',
  _LOAD_TITLE: 'Załaduj trasę',
  _LOAD_DESC:
    '<p>Tutaj możesz łatwo załadować dowolną zapisaną trasę. Po prostu wybierz trasę i kliknij</p>',

  _POINTS: 'Punkty:',
  _POINTS_TEXT: 'Możesz przeciągać i zmieniać kolejność punktów',
  _PREMIUM_ACCOUNT: 'Konto premium',
  _PREMIUM_ACCOUNT_SEO_TITLE: 'Korzyści i koszty konta Premium',
  _PREMIUM_ACCOUNT_TEXT: 'Korzyści dla użytkowników Premium',
  _PREMIUM_FEATURES: 'Funkcje',
  _PREMIUM_REGULAR_USERS: 'Zwykli użytkownicy',
  _PREMIUM_PREMIUM_USERS: 'Użytkownicy Premium',

  // Header
  _LOGOUT: 'Wyloguj',
  _KONTO: 'Konto',
  _REGULAR_ACCOUNT: 'Zwykłe konto',
  _MY_TRIPS: 'Moje podróże',
  _TRIP_PLANNER: 'Planowanie podróży',
  _MY_STATIONS: 'Moje stacje',
  _MY_ENERGY: 'Moja energia',
  _MY_TARIFFS: 'Moje taryfy',
  _CHARGING: 'Ładowanie',
  _EV_FRIENDS: 'EV Przyjaciele',
  _EV_HOTELS: 'EV Hotele',
  _STATIONS_MAP: 'Mapa stacji',

  // Stories
  _STORIES: 'Moje podróże:',
  _STORIES_TEXT:
    'W tej sekcji możesz podzielić się z innymi użytkownikami swoimi doświadczeniami z podróży EV w innych krajach lub miastach z punktami, w których możesz się zatrzymać lub bezpłatnie naładować',
  _ADD_STORY: 'Dodaj podróż',
  _EDIT_STORY: 'Edytuj podróż',
  _STORIES_HEAD_TITLE: 'Moje podróże',
  _STORY_EDIT: 'Edytuj',
  _PUBLISHED_STATUS: 'Opublikowano',
  _UNPUBLISHED_STATUS: 'Nieopublikowane',
  _CHARGING_POINTS: 'Punkty ładowania',

  _ERROR: 'Błąd',
  _EDIT: 'Edytuj',
  _EDIT_TRIP: 'Edytuj podróż',
  _ADD_TRIP: 'Dodaj podróż',
  _ANULUJ: 'Anuluj',
  _ADD_POINT: 'Dodaj punkt',
  _CHARGE_POINT: 'Punkt ładowania',
  _BACK_TO_TRIPS: 'Powrót do podróży',
  _ALLOW_USERS_TO_SEE: 'Pozwól innym użytkownikom zobaczyć tę podróż',
  _UPLOAD_BUTTON: 'Wybierz pliki',
  _ADD_DESCRIPTION: 'Dodaj opis',
  _UPLOAD_TEXT: 'Obsługiwane pliki do przesłania — JPG, PNG, JPEG',

  _LOCATION_POSITION: 'Pozycja zlokalizowana:',
  _ERROR_GEO:
    'Z jakiegoś powodu nie możemy śledzić Twojej geolokalizacji, ale możemy wprowadzić ją ręcznie',
  _IS_CHARGING_FREE: 'Ładowanie było dla mnie bezpłatne',
  _ADD_CHARGE_POINT: 'Dodaj punkt ładowania',
  _MAX_CHARGING_SPEED: 'Maksymalna prędkość ładowania:',

  _DELETE: 'Usuń',
  _FROM: 'Od',
  _TO: 'Do',
  _DURATION: 'Czas trwania',
  _TOTAL_DISTANCE: 'Całkowity dystans',
  _TOTAL_DURATION: 'Całkowity czas trwania',

  _SHOW_MAP: 'Pokaż mapę',
  _HIDE_MAP: 'Ukryj mapę',
  _SHOW: 'Pokaż',
  _EV_TRIPS: 'Podróże EV'
};

export default translate;
