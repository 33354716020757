import React, { useRef, useState } from 'react';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';

const libraries = ['places'];

const formatPlace = place => {
  const { address_components } = place;

  const townSearch = address_components.find(({ types }) =>
    types.includes('locality')
  );

  const regionSearch = address_components.find(({ types }) =>
    types.includes('administrative_area_level_1')
  );

  const countrySearch = address_components.find(({ types }) =>
    types.includes('country')
  );

  if (townSearch || regionSearch || countrySearch) {
    return [
      townSearch ? townSearch.long_name : null,
      regionSearch ? regionSearch.long_name : null,
      countrySearch ? countrySearch.long_name : null
    ]
      .filter(Boolean)
      .join(', ')
      .trim();
  }

  return null;
};

const PlaceComponent = (props: any): any => {
  const { googleMapsApiKey, onSelect } = props;
  const inputRef = useRef();
  const [value, setValue] = useState('');

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();

    if (place && onSelect) {
      const result = {
        title: formatPlace(place),
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };

      setValue('');
      onSelect(result);
    }
  };

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={libraries}>
      <StandaloneSearchBox
        onLoad={ref => {
          inputRef.current = ref;
        }}
        onPlacesChanged={handlePlaceChanged}
      >
        <input
          type="text"
          name="title"
          className="form-control"
          placeholder="Enter Location"
          onChange={e => setValue(e.target.value)}
          value={value}
        />
      </StandaloneSearchBox>
    </LoadScript>
  );
};

export default PlaceComponent;
