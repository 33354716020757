// @flow
import * as React from 'react';
import styles from './switch.module.scss';

type Props = {
  className?: string,
  checked?: boolean,
  onClick?: () => void
};

const Switcher = (props: Props): any => {
  const { checked = false, className = '', onClick = () => {} } = props;
  const style = checked ? styles.sliderChecked : styles.slider;

  return (
    <div
      className={`${styles.container} ${className}`.trim()}
      onClick={onClick}
    >
      <div className={styles.switch}>
        <div className={style}>
          <div className={styles.input} />
        </div>
      </div>
    </div>
  );
};

export default Switcher;
