/**
 * Pages actions
 */

import { SET_STATIONS, GET_STATIONS } from './types';

import { getStationInfo } from '../../../lib/api';

export const setStationsData = data => ({
  type: SET_STATIONS,
  payload: () => data
});

export const getStationsData = params => ({
  type: GET_STATIONS,
  payload: getStationInfo(params)
});

export default {};
