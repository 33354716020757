import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

import Section from '../../components/Section';
import styles from './premium.module.scss';

import metaValues from '../../../config';
import translation from '../../../../translate';

import CheckedIcon from '../../assets/svg/check-mark-icon.svg';

const Checked = () => (
  <img src={CheckedIcon} alt="Checked" className={styles.checked} />
);

const PremiumScreen = () => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);

  return (
    <div>
      <Helmet>
        <title>
          {translation('_PREMIUM_ACCOUNT_SEO_TITLE', language)} - {defaultTitle}
        </title>
        <meta
          name="description"
          content="Premium Account benefits and costs for our users"
        />
        <meta property="keywords" content="ev-charging, charge points" />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <Section className={styles.content}>
        <Link
          to="/"
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to home
        </Link>
        <h1>{translation('_PREMIUM_ACCOUNT', language)}</h1>
        <p>{translation('_PREMIUM_ACCOUNT_TEXT', language)}</p>

        <table className={styles.table}>
          <tr className={styles.header}>
            <td>{translation('_PREMIUM_ACCOUNT', language)}</td>
            <td>{translation('_PREMIUM_REGULAR_USERS', language)}</td>
            <td>{translation('_PREMIUM_PREMIUM_USERS', language)}</td>
          </tr>

          <tr className={styles.mainFeature}>
            <td>Show pay-free charging stations on the route</td>
            <td>Not allowed</td>
            <td>
              <Checked />
            </td>
          </tr>
          <tr className={styles.mainFeature}>
            <td>Start charging (with our support)</td>
            <td>Not allowed</td>
            <td>
              <Checked />
            </td>
          </tr>
          <tr className={styles.mainFeature}>
            <td>Show current station status (free, busy)</td>
            <td>Not allowed</td>
            <td>
              <Checked />
            </td>
          </tr>

          <tr>
            <td>Intellectual route building (based on your EV-car range)</td>
            <td>Not allowed</td>
            <td>
              <Checked />
            </td>
          </tr>

          <tr>
            <td>Build a route between charge stations</td>
            <td>Not allowed</td>
            <td>
              <Checked />
            </td>
          </tr>

          <tr>
            <td>Custom distance limit from driving route</td>
            <td>Not allowed</td>
            <td>
              <Checked />
            </td>
          </tr>

          <tr>
            <td>Custom power limit choose for route building</td>
            <td>Not allowed</td>
            <td>
              <Checked />
            </td>
          </tr>

          <tr>
            <td>
              Build a route between points with charge stations highlighting
            </td>
            <td>
              <Checked />
            </td>
            <td>
              <Checked />
            </td>
          </tr>
        </table>
      </Section>
    </div>
  );
};

export default PremiumScreen;
