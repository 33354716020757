import { io } from 'socket.io-client';

export const initiateSocketConnection = SERVER_URL => {
  const socket = io(SERVER_URL, {
    // withCredentials: true,
    auth: {
      token: 'cde'
    }
  });

  return socket;
};

export const joinRoom = ({ name: roomName, socket, onMessage = () => {} }) => {
  socket.emit('join', roomName);
  socket.on('message', onMessage);
};

export const disconnectSocket = ({ socket }) => {
  if (socket) {
    socket.disconnect();
  }
};

export const sendMessage = ({ room, message, socket }) => {
  if (socket) {
    socket.emit(room, { message, room });
  }
};
