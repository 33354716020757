import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useGoogleMaps } from 'react-hook-google-maps';
import { Resizable } from 're-resizable';
import ReactDOMServer from 'react-dom/server';

import ChargeTesla from '../../assets/icons/charge-tesla.svg';
import ChargeImage22 from '../../assets/icons/charge-22.svg';
import ChargeImage50 from '../../assets/icons/charge-50.svg';
import ChargeImage100 from '../../assets/icons/charge-100.svg';
import ChargeImage150 from '../../assets/icons/charge-150.svg';
import ChargeImage200 from '../../assets/icons/charge-200.svg';
import ChargeImage300 from '../../assets/icons/charge-300.svg';
import Button from '../../components/Button';

import styles from './_map.module.scss';

const providerMappings = {
  BMW: 'https://bmw-public-charging.com',
  UGV: 'https://ugv.ua/uk/karta-zapravok/',
  EcoFactor: 'https://efn.ecofactor.ua/app-for-drivers/',
  TOKA: 'https://toka.energy/en/',
  GOTOU: 'https://go-tou.com/en'
};

const MapComponent = (props: any): any => {
  const {
    onCalculated,
    isCalculated,
    isPremiumUser,
    wayPoints = [],
    markers,
    avoidTolls = false,
    avoidHighways = false,
    onAddMarkerClick,
    userData
  } = props;

  const { googleKey } = userData || {};
  const [directionsService, setDirectionService] = useState(null);
  const [directionsRenderer, setDirectionRenderer] = useState(null);
  const [initialHeight, setInitialHeight] = useState(500);
  const [height, setHeight] = useState(initialHeight);
  const width = '100%';
  const zoom = 11;

  const point = {
    lat: wayPoints[0].lat,
    lng: wayPoints[0].lng
  };

  const { ref, map, google } = useGoogleMaps(googleKey, {
    zoom,
    point
  });

  const calcRoute = () => {
    const waypoints = wayPoints.slice(1, -1).map(pointInfo => ({
      location: {
        lat: pointInfo.lat,
        lng: pointInfo.lng
      }
    }));

    const request = {
      origin: wayPoints[0],
      waypoints,
      provideRouteAlternatives: true,
      avoidTolls,
      avoidHighways,
      travelMode: 'DRIVING',
      optimizeWaypoints: true
    };

    // console.log(request);

    if (wayPoints.length > 1) {
      request.destination = wayPoints[wayPoints.length - 1];

      directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          // directionsRenderer.setDirections(null);
          directionsRenderer.setDirections(result);
          const pointInfo = _.get(result, 'routes[0].legs', {});
  
          if (!isCalculated) {
            onCalculated(pointInfo);
          }
        }
      });
    }
  };

  // const pointInfo = _.get(routeDetails, 'routes[0].legs', {});

  const addMarker = (params: any) => {
    const {
      lat,
      lng,
      title,
      name,
      maxPower,
      distance,
      provider,
      network,
      isTesla,
      city
    } = params;

    const marker = new google.maps.Marker({
      position: { lat, lng }, // Passing the coordinates
      map,
      draggarble: false // If set to true you can drag the marker
    });

    const link = `https://www.google.com/maps/place/${lat},${lng}/@${lat},${lng},15z`;

    const buttonId = `btn-${lat}-${lng}`;
    const content = (
      <div className={styles.infoWindow}>
        <h2>{title || name || city}</h2>
        <ul>
          <li>
            Max Power:&nbsp;<b>{maxPower}&nbsp;kW</b>
          </li>
          <li>
            Distance:&nbsp;<b>{distance.toFixed(2)}&nbsp;km</b>
          </li>
          {providerMappings[provider] && (
            <li>
              Website:&nbsp;
              <a
                href={providerMappings[provider]}
                target="_blank"
                rel="noreferrer"
              >
                {provider}
              </a>
            </li>
          )}
          {network && (
            <li>
              Network:&nbsp;<b>{network}</b>
            </li>
          )}
        </ul>
        <p>
          <a href={link} target="_blank" rel="noreferrer">
            Open in Google Maps
          </a>
        </p>
        {isPremiumUser && <Button id={buttonId}>Add to charge list</Button>}
        {!isPremiumUser && (
          <div>
            <Button disabled>Add to charge list</Button>
            <p>Available only for Premium users</p>
          </div>
        )}
      </div>
    );

    let markerIcon = ChargeImage300;

    if (maxPower === 22) {
      markerIcon = ChargeImage22;
    }

    if (maxPower >= 50) {
      markerIcon = ChargeImage50;
    }

    if (maxPower >= 100) {
      markerIcon = ChargeImage100;
    }

    if (maxPower >= 150) {
      markerIcon = ChargeImage150;
    }

    if (maxPower >= 200) {
      markerIcon = ChargeImage200;
    }

    if (maxPower >= 300) {
      markerIcon = ChargeImage300;
    }

    if (isTesla) {
      markerIcon = ChargeTesla;
    }

    marker.setIcon(markerIcon);

    const information = new google.maps.InfoWindow({
      content: ReactDOMServer.renderToString(content)
    });

    const handleMarkerClick = () => {
      onAddMarkerClick(params);
    };

    let someButton;
    google.maps.event.addListener(information, 'domready', () => {
      someButton = document.getElementById(buttonId);

      if (someButton) {
        someButton.addEventListener('click', handleMarkerClick);
      }
    });

    google.maps.event.addListener(map, 'click', () => {
      information.close();

      if (someButton) {
        someButton.removeEventListener('click', handleMarkerClick);
      }
    });

    marker.addListener('click', () => {
      information.open(map, marker);
    });

    return marker;
  };

  useEffect(() => {
    const markerPool = markers && markers.length ? markers.map(addMarker) : [];

    if (map) {
      if (!directionsService) {
        setDirectionService(new google.maps.DirectionsService());
      }

      if (!directionsRenderer) {
        const renderer = new google.maps.DirectionsRenderer();
        setDirectionRenderer(renderer);
      }

      if (directionsRenderer && map) {
        directionsRenderer.setMap(map);
      }

      if (!isCalculated && directionsService && wayPoints.length) {
        // console.log('calculate directions');
        calcRoute();
      }
    }

    return () => {
      markerPool.forEach(marker => marker.setMap(null));
    };
  });

  return (
    <Resizable
      size={{ height, width }}
      axis="y"
      minconstraints={[10, 10]}
      maxconstraints={[0, 200]}
      onResize={(e, direction, refId, d) => {
        setHeight(initialHeight + d.height);
      }}
      onResizeStop={(e, direction, refId, d) => {
        setInitialHeight(initialHeight + d.height);
      }}
    >
      <div
        ref={ref}
        className={styles.mapContainer}
        style={{ height, width, zIndex: 0 }}
      />
    </Resizable>
  );
};

export default MapComponent;
