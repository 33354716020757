const translate = {
  _HOME_HEAD_TITLE: 'Відкрий світ без шкідливих викидів',
  _HOME_HEAD_BUTTON1: 'Переглянути завершені подорожі',
  _HOME_HEAD_BUTTON2: 'Почніть планувати свою подорож',

  _HOME_HEAD_SECOND_TITLE: 'Знайди зарядки навколо',
  _HOME_HEAD_SECOND_DESC: `<p>Використовуйте один обліковий запис для всіх зарядних мереж навколо вас!</p><p>Ви можете легко знайти, підключити, оплатити та зарядити свій автомобіль за допомогою нашої дивовижної платформи – все, що вам потрібно для цього, мати акаунт у нас.</p>`,

  _HOME_HEAD_THIRD_TITLE: `Економ свій <span>ЧАС</span>`,
  _HOME_HEAD_FOURTH_TITLE: `та <span>ГРОШІ</span>`,

  _HOME_WOW:
    `<p>Ми допомагаємо електромобілям знайти найближчу зарядну станцію по всій Україні та Європі і використовувати цей обліковий запис у всіх мережах для зарядки авто.</p>` +
    `<p>Ви можете переглянути останні сеанси зарядки, щоб визначити, коли востаннє цю станцію використовували інші автомобілі - це допоможе вам уникнути відвідування непрацюючих станцій на дорозі.</p>`,

  _HOME_STATIONS: `Станції`,
  _HOME_OUTSIDE_UKRAINE: `Зарядка поза Україною`,
  _HOME_FIND_NEAR: `Знайдіть зарядку поблизу`,

  _MAP_ROUTE_ADD_ROUTE_POINT: `Додати точку маршруту`,
  _MAP_ROUTE_ADD_ROUTE_TEXT: `<p>Нова точка буде додана до верхньої частини маршруту, а пізніше ви зможете впорядкувати ланцюжок точок</p>`,
  _MAP_ROUTE_POWER_LIMIT: `Обмеження потужності:`,
  _MAP_ROUTE_DISTANCE_LIMIT: `Обмеження відстані:`,
  _MAP_ROUTE_DISTANCE_LIMIT_TEXT: `Максимальна відстань до станції від маршруту (км)`,
  _MAP_ROUTE_DISTANCE_CHARGE_LIMIT: `Оплата відстані до заряджання:`,
  _MAP_ROUTE_DISTANCE_CHARGE_LIMIT_TEXT:
    'Середня відстань між зарядними станціями (км)',
  _MAP_ROUTE_OPTIONS: 'Параметри:',
  _MAP_ROUTE_OPTION_1: 'Надаю перевагу безкоштовним станціям',
  _MAP_ROUTE_OPTION_2: 'Уникати платних доріг',
  _MAP_ROUTE_OPTION_3: 'Уникати автострад',

  _CLEAR_ROUTE: 'Новий маршрут',
  _NO_ROUTES: 'У вас немає збережених маршрутів',
  _SAVE_ROUTE: 'Зберегти маршрут',
  _SAVE_ROUTE_TEXT:
    'Збережений маршрут можна завантажити та змінити пізніше. Ви можете зберегти маршрут з усіма зупинками та інформацією про тарифікацію.',
  _PAGE_HEAD_TITLE: 'Конструктор карти маршруту',
  _MAP_ROUTE_LOGGED: `<h1>Увага!</h1><p>Вам потрібно ввійти в систему, щоб використовувати цей розділ</p>`,
  _LOAD: 'Завантажити',
  _SAVE: 'Зберегти',
  _LOAD_TITLE: 'Завантажити маршрут',
  _LOAD_DESC:
    '<p>Тут можна легко завантажити будь-який збережений маршрут. Просто виберіть маршрут</p>',

  _POINTS: 'Зупинки:',
  _POINTS_TEXT: 'Ви можете перетягувати та змінювати порядок зупинок',
  _PREMIUM_ACCOUNT: 'Преміум-акаунт',
  _PREMIUM_ACCOUNT_SEO_TITLE: 'Переваги та привілеї преміум-облікового запису',
  _PREMIUM_ACCOUNT_TEXT: `Переваги для преміум-користувачів`,
  _PREMIUM_FEATURES: `Функції`,
  _PREMIUM_REGULAR_USERS: `Звичайні користувачі`,
  _PREMIUM_PREMIUM_USERS: `Преміум-користувачі`,

  // Header

  _LOGOUT: 'Вийти',
  _ACCOUNT: 'Обліковий запис',
  _REGULAR_ACCOUNT: 'Звичайний обліковий запис',
  _MY_TRIPS: 'Мої подорожі',
  _TRIP_PLANNER: 'Планувальник подорожі',
  _MY_STATIONS: 'Мої станції',
  _MY_ENERGY: 'Моя енергія',
  _MY_TARIFFS: 'Мої тарифи',
  _CHARGING: 'Зарядка',
  _EV_FRIENDS: 'EV-друзі',
  _EV_HOTELS: 'EV-отелі',
  _STATIONS_MAP: 'Карта станцій',

  // Stories
  _STORIES: 'Моі подорожі:',
  _STORIES_TEXT:
    'У цьому розділі ви можете поділитися з іншими користувачами своїм досвідом поїздки на EV в інші країни або міста із зазначенням пунктів, куди Ви можете залишитися або зарядити безкоштовно',
  _ADD_STORY: 'Додати подорож',
  _EDIT_STORY: 'Редагувати подорож',
  _STORIES_HEAD_TITLE: 'Моі подорожі',
  _STORY_EDIT: 'Редагувати',
  _PUBLISHED_STATUS: 'Опубліковано',
  _UNPUBLISHED_STATUS: 'Неопубліковано',
  _CHARGING_POINTS: 'Зарядки/зупинки',

  _ERROR: 'Помилка',
  _EDIT: 'Редагувати',
  _EDIT_TRIP: 'Редагувати подорож',
  _ADD_TRIP: 'Додати поїздку',
  _CANCEL: 'Скасувати',
  _ADD_POINT: 'Додати зупинку',
  _CHARGE_POINT: 'Точки зарядки',
  _BACK_TO_TRIPS: 'Назад до подорожей',
  _ALLOW_USERS_TO_SEE: 'Дозволити іншим користувачам бачити цю подорож',
  _UPLOAD_BUTTON: 'Виберіть файли',
  _ADD_DESCRIPTION: 'Додати опис',
  _UPLOAD_TEXT: 'Підтримувані файли для завантаження - JPG, PNG, JPEG',

  _LOCATION_POSITION: 'Геопозиція:',
  _ERROR_GEO:
    'Чомусь ми не можемо відстежити вашу геолокацію, але можемо ввести її вручну',
  _IS_CHARGING_FREE: 'Заряджання було для мене безкоштовним',
  _ADD_CHARGE_POINT: 'Додати точку зарядки',
  _MAX_CHARGING_SPEED: 'Максимальна швидкість заряджання:',

  _DELETE: 'Видалити',
  _FROM: 'Від',
  _TO: 'До',
  _DURATION: 'Тривалість',
  _TOTAL_DISTANCE: 'Загальна відстань',
  _TOTAL_DURATION: 'Загальна тривалість',

  _SHOW_MAP: 'Показати карту',
  _HIDE_MAP: 'Приховати карту',
  _SHOW: 'Показати',
  _EV_TRIPS: 'Подорожі на електромобілі'
};

export default translate;
