// @flow

import React from 'react';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HashLoader } from 'react-spinners';
import _ from 'lodash';

import styles from './account-edit.module.scss';
import Section from '../../components/Section';

import metaValues from '../../../config';
import AccountCart from '../../components/AccountCart';
import AccountForm from '../../components/_smart/AccountForm';

const AccountEditScreen = (): any => {
  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;
  const { userData, isFulfilled } = useSelector(({ user }: any) => user);
  const { API_URL } = useSelector(({ common }: any) => common);

  if (!isFulfilled) {
    return (
      <Section className={styles.container}>
        <center>
          <HashLoader />
        </center>
      </Section>
    );
  }

  const userFields = _.pick(userData, [
    'firstName',
    'lastName',
    'phone',
    'email'
  ]);

  return (
    <Section className={styles.container}>
      <Helmet>
        <title>Edit account - {defaultTitle}</title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={defaultTitle} />
      </Helmet>

      <div>
        <div>
          <Link to="/account">Back to account</Link>
          <div className={styles.content}>
            <AccountCart userData={userData} />
            <div>
              <h1>Account</h1>
              <AccountForm data={{ API_URL }} values={userFields} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AccountEditScreen;
