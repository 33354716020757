import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .matches(/(^[A-zАя0-9-() ]+$)/, 'Please, fill only latin chars')
    .required('Please, provide tarif title'),
  priceKwt: Yup.string()
    .matches(/(^[0-9. ]+$)/, 'Please, only numbers')
    .required('Please, provide price')
});

export default validationSchema;
