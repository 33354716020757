import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';
import { HashLoader } from 'react-spinners';
import moment from 'moment';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import Section from '../../../components/Section';
import OutcomeForm from '../../../components/_smart/OutcomeForm';

import styles from './station-outcome.module.scss';

import { getStationOutcomes, deleteStationOutcome } from '../../../../lib/api';

import metaValues from '../../../../config';
import Button from '../../../components/Button';

const StationOutcomeScreen = () => {
  const { stationId } = useParams();
  const [info, setInfo] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [deleteId, setDeleteId] = useState();
  const [isShowForm, setShowForm] = useState(false);
  const [update, setUpdate] = useState(new Date());

  const { API_URL } = useSelector(state => state.common);
  const { defaultTitle } = metaValues;
  // const { userData } = useSelector(state => state.user);

  const handleDelete = id => {
    setShowDeleteModal(false);
    setDeleteId(null);
    const newInfo = info.filter(item => item.id !== id);
    setInfo(newInfo);
    deleteStationOutcome({ API_URL, id, slug: stationId });
  };

  useEffect(() => {
    // if (!stationInfo) {
    //   history('/manage');
    // }

    const fetchData = async () => {
      const data = await getStationOutcomes({ API_URL, slug: stationId });
      setInfo(data);
    };

    if (stationId) {
      // console.log('fetch data');
      fetchData();
    }
  }, [stationId, update]);

  const { title } = info || {};

  const metaTitle = title
    ? `${title} - Station outcome management`
    : `Station outcome - ${defaultTitle}`;

  if (!info) {
    return (
      <div className={styles.loader}>
        <HashLoader color="#fff" size={50} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content="Top-up for user account" />
        <meta property="keywords" content="ev-charging, charge points" />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <Modal
        open={showDeleteModal}
        animationDuration={0}
        onClose={() => setShowDeleteModal(false)}
        classNames={{
          overlay: styles.modalOverlay,
          modal: styles.modal
        }}
        center
      >
        <h2>Simple centered modal</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
          pulvinar risus non risus hendrerit venenatis. Pellentesque sit amet
          hendrerit risus, sed porttitor quam.
        </p>
        <center className={styles.buttons}>
          <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button color="red" onClick={() => handleDelete(deleteId)}>
            Delete
          </Button>
        </center>
      </Modal>

      <Section className={styles.content}>
        <Link
          to={`/manage/profit/${stationId}`}
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to station
        </Link>

        <Button
          size="medium"
          className={styles.addButton}
          onClick={() => setShowForm(!isShowForm)}
        >
          Add &#128176;
        </Button>
        <h2>Station outcome items</h2>

        {isShowForm && (
          <OutcomeForm
            data={{ API_URL }}
            values={{ title: '', price: 0, stationId }}
            onSubmit={() => {
              setUpdate(new Date());
              setShowForm(false);
            }}
            onCancel={() => setShowForm(false)}
          />
        )}

        <h3>{title}</h3>
        <ul className={styles.table}>
          {info.map(item => (
            <li>
              <div>{item.title}</div>
              <div>{item.amount}</div>
              <div>{item.type}</div>
              <div>
                {item.activeDate &&
                  moment(item.activeDate).format('DD.MM.YYYY')}
              </div>
              <div>
                {/* <Button size="small">Edit</Button> */}
                <Button
                  size="small"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setDeleteId(item.id);
                  }}
                >
                  Delete
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </Section>
    </div>
  );
};

export default StationOutcomeScreen;
