import typeToReducer from 'type-to-reducer';

import { COMMON, LANGUAGE_CHANGE, THEME_CHANGE } from '../actions/types';

const DEFAULT_STATE = {
  language: 'en',
  theme: 'light'
};

const commonReducer = typeToReducer(
  {
    [COMMON]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [LANGUAGE_CHANGE]: (state, action) => ({
      ...state,
      language: action.payload
    }),
    [THEME_CHANGE]: (state, action) => ({
      ...state,
      theme: action.payload
    })
  },
  DEFAULT_STATE
);

export default commonReducer;
