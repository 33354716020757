// @flow

import { useState } from 'react';
import * as React from 'react';
import moment from 'moment';
import Carousel, { Modal, ModalGateway } from 'react-images';

import styles from './story-edit.module.scss';

import PhotoIcon from '../../assets/svg/icon-photo.svg';

const RenderOnePoint = (props: any): any => {
  const { pointData, key } = props;
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const {
    isFree,
    title: pointTitle,
    description: pointDesc,
    createdAt,
    images: imgs,
    chargeSpeed
  } = pointData;

  const date = moment(createdAt).format('DD/MM/YYYY');
  const timeStamp = moment(createdAt).format('HH:mm');
  const isFreeText = isFree ? styles.freeCharging : '';
  const imgCount = imgs ? imgs.length : null;
  const chargeInfo = chargeSpeed || null;

  const openLightbox = (imageId: any) => {
    const index = imgs.findIndex(x => x.id === imageId);
    setPhotoIndex(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => setViewerIsOpen(false);

  return (
    <li className={isFreeText} key={key}>
      <time>
        {date}
        <br />
        {timeStamp}
      </time>
      <div>
        <div className={styles.title}>{pointTitle}</div>
        <div className={styles.text}>{pointDesc}</div>
        {chargeInfo && (
          <div className={styles.chargeInfo}>
            <b>{chargeInfo}</b>&nbsp;kWt/h
          </div>
        )}
        {imgCount && (
          <div>
            <div className={styles.images}>
              <img src={PhotoIcon} className={styles.icon} alt="icon" />
              <span
                onClick={() => openLightbox(imgs[0].id)}
                className={styles.imageLink}
              >
                {imgCount}&nbsp;images
              </span>
            </div>
            {viewerIsOpen && (
              <ModalGateway>
                <Modal onClose={closeLightbox}>
                  <Carousel currentIndex={photoIndex} views={imgs} />
                </Modal>
              </ModalGateway>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export default RenderOnePoint;
