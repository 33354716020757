import typeToReducer from 'type-to-reducer';

import {
  USER_LOGIN,
  SET_USER_STATE,
  USER_LOGGED_OUT,
  GET_USER_DATA,
  SET_USER_DATA
  // SET_USER_BOOKMARK
} from '../actions/types';

import { DEFAULT_ASYNC_STATE } from './constants';

const isServer = typeof window === 'undefined';

const jwtToken = !isServer ? localStorage.getItem('userJWT') : null;

const DEFAULT_STATE = {
  ...DEFAULT_ASYNC_STATE,
  userData: false,
  isLoggedIn: !!jwtToken,
  bookmarks: []
};

const userReducer = typeToReducer(
  {
    // [SET_USER_BOOKMARK]: (state, action) => ({
    //   ...state,
    //   bookmarks: action.payload
    // }),
    [USER_LOGIN]: {
      PENDING: () => ({
        ...DEFAULT_STATE,
        isPending: true
      }),
      REJECTED: (state, action) => ({
        ...DEFAULT_STATE,
        isRejected: true,
        error: action.payload
      }),
      FULFILLED: (state, action) => ({
        ...state,
        isFulfilled: true,
        isLoggedIn: true,
        isPending: false,
        ...action.payload
      }),
      RESET: () => DEFAULT_STATE
    },
    [USER_LOGGED_OUT]: state => ({
      ...state,
      userData: false,
      isFulfilled: false,
      isLoggedIn: false
    }),
    [SET_USER_STATE]: (state, action) => {
      const { userData } = state;
      return {
        ...state,
        userData: {
          ...userData,
          ...action.payload
        }
      };
    },
    [GET_USER_DATA]: {
      PENDING: () => ({
        ...DEFAULT_STATE,
        isPending: true
      }),
      REJECTED: (state, action) => ({
        ...DEFAULT_STATE,
        isRejected: true,
        error: action.payload
      }),
      FULFILLED: (state, action) => ({
        ...state,
        isFulfilled: true,
        isPending: false,
        isLoggedIn: true,
        userData: action.payload
      })
    },
    [SET_USER_DATA]: {
      PENDING: () => ({
        ...DEFAULT_STATE,
        isPending: true
      }),
      REJECTED: (state, action) => ({
        ...DEFAULT_STATE,
        isRejected: true,
        error: action.payload
      }),
      FULFILLED: (state, action) => ({
        ...state,
        isFulfilled: true,
        isPending: false,
        isLoggedIn: true,
        userData: action.payload
      })
    }
  },
  DEFAULT_STATE
);

export default userReducer;
