// @flow

import React, { useEffect } from 'react';
import { Field, Form, withFormik } from 'formik';
import Cards from 'react-credit-cards';

import validationSchema from './validation-schema';
import Button from '../Button';

import styles from './card.module.scss';

import { renderInput } from '../render-methods';

// $FlowFixMe
import 'react-credit-cards/es/styles-compiled.css';

/**
 * Props
 */
type Props = {
  isSubmitting: boolean,
  isValid: boolean,
  values: any,
  validateForm: any,
  setTouched: any
  // setFieldValue: (fieldName: string, value: string | number) => void
};

const CreditCardForm = (props: Props) => {
  const { isValid, values, isSubmitting, validateForm, setTouched } = props;

  useEffect(() => {
    validateForm();
    setTouched({ number: true, cvc: true, expire: true });
  }, []);

  const renderSubmitButton = (disabled: boolean) => (
    <Button size="large" disabled={disabled || isSubmitting}>
      Save card
    </Button>
  );

  const { number = '', cvc = '', expire = '' } = values;

  return (
    <Form className={styles.grid}>
      <div className={styles.cardIcon}>
        <Cards
          number={number}
          cvc={cvc}
          expiry={expire}
          name=""
          placeholders={{ name: '' }}
        />
      </div>

      <div className={styles.userArea}>
        <div className={styles.card}>
          <Field
            name="number"
            render={(properties: any) =>
              renderInput({
                ...properties,
                className: styles.maskInputCard,
                mask: '1111 1111 1111 1111',
                placeholderChar: '•',
                size: 20,
                placeholder: 'Enter card number'
              })
            }
          />
        </div>

        <div className={styles.subgrid}>
          <div className={styles.expire}>
            <Field
              name="expire"
              render={(properties: any) =>
                renderInput({
                  ...properties,
                  className: styles.input,
                  mask: '11/11',
                  placeholderChar: '•',
                  placeholder: '••/••'
                })
              }
            />
          </div>

          <div className={styles.cvc}>
            <Field
              name="cvc"
              render={(properties: any) =>
                renderInput({
                  ...properties,
                  className: styles.input,
                  mask: '111',
                  placeholderChar: '•',
                  placeholder: 'CVC'
                })
              }
            />
          </div>
        </div>
        {renderSubmitButton(!isValid)}
      </div>
    </Form>
  );
};

// $FlowFixMe
const FormWithFormik = withFormik({
  validationSchema,
  validateOnMount: true,
  // validateOnChange: true,
  mapPropsToValues: ({ values }) => values,
  handleSubmit: (values, { props }) => props.onSubmit(values)
})(CreditCardForm);

export default FormWithFormik;
