// @flow
import * as React from 'react';
import _ from 'lodash';
import { ClipLoader } from 'react-spinners';

import styles from './button.module.scss';

import * as Icon from '../Icon';

type Props = {
  size?: string,
  loading?: boolean,
  disabled?: boolean,
  id?: string,
  className?: string,
  color?: string,
  icon?: string,
  children?: React.Node,
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void
};

const Button = ({
  children = '',
  id = '',
  size = 'default',
  disabled = false,
  loading = false,
  className = '',
  color = '',
  onClick = () => {},
  icon
}: Props): any => {
  const style = size && styles[size] ? styles[size] : styles.default;
  const styleColor = color && styles[color] ? styles[color] : '';
  const handleOnClick = disabled ? () => {} : onClick;
  const IconSVG = icon ? _.get(Icon, icon) : '';

  return (
    <button
      id={id}
      className={`${style} ${styleColor} ${className}`.trim()}
      disabled={disabled || loading}
      onClick={handleOnClick}
    >
      {icon && <IconSVG className={styles.btnIcon} />}
      {children}
      {loading && (
        <span className={styles.loader}>
          <ClipLoader size={20} color="#d7d7d7" loading={loading} />
        </span>
      )}
    </button>
  );
};

export default Button;
