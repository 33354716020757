// @flow
import * as React from 'react';

import _ from 'lodash';
import dayjs from 'dayjs';

// $FlowFixMe
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { Component } from 'react';
import onClickOutsideHOC from 'react-onclickoutside';

import styles from './datepicker.module.scss';
import * as Icon from '../Icon';

dayjs.extend(weekOfYear);

/**
 * Props
 */
type State = {
  day: number,
  year: number,
  month: number
};

type Props = {
  date?: string,
  className?: string,
  showWeekNumbers?: boolean,
  onChange?: (state: State) => void,
  onClickOutside: () => void,
  onSelect: (result: State) => void,
  isVisible?: boolean
};

const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

class DatePicker extends Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    isVisible: false,
    onChange: () => {},
    showWeekNumbers: false,
    date: '',
    className: ''
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { date } = props;

    if (!Object.keys(state).length) {
      const defaultDate = dayjs().format('YYYY-MM-DD');

      return {
        day: Number(dayjs(date || defaultDate).date()),
        year: Number(dayjs(date || defaultDate).year()),
        month: Number(dayjs(date || defaultDate).month() + 1)
      };

      // if (
      //   state.day !== Number(dayjs(date).date()) ||
      //   state.year !== Number(dayjs(date).year()) ||
      //   state.month !== Number(dayjs(date).month() + 1)
      // ) {
      //   return {
      //     day: Number(dayjs(date).date()),
      //     year: Number(dayjs(date).year()),
      //     month: Number(dayjs(date).month() + 1)
      //   };
      // }
    }

    return state;
  }

  handleClick = (month: number) => {
    const { onChange } = this.props;
    this.setState({ month }, () => {
      if (onChange) {
        onChange(this.state);
      }
    });
  };

  handleClickOutside() {
    const { onClickOutside, isVisible } = this.props;
    if (isVisible) {
      onClickOutside();
    }
  }

  changeMonth = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event;
    this.setState({ month: Number(value) });
  };

  yearChange = (type: boolean) => {
    const { year } = this.state;
    const nextValue = year + (type ? 1 : -1);

    this.setState({ year: nextValue });
  };

  handleChooseClick = (state: State) => {
    const { onSelect } = this.props;
    this.setState(state, () => onSelect(state));
  };

  renderDays = () => {
    const { month, year, day } = this.state;
    const { showWeekNumbers } = this.props;

    const monthStart = `${year}-${month}-1`;
    const countOfDays = dayjs(monthStart).daysInMonth();
    const periodWeek = dayjs(monthStart).startOf('week');

    const weekSlice =
      dayjs(periodWeek).day() === 0 && dayjs(periodWeek).date() === 1;

    const periodStart = weekSlice
      ? periodWeek.subtract(6, 'day')
      : periodWeek.add(1, 'day');

    const cellCount = Math.ceil(countOfDays / 7) * 7 + (weekSlice ? 7 : 0);
    const containerStyle = showWeekNumbers
      ? `${styles.days} ${styles.daysHalf}`
      : styles.days;

    const dayList = Array(cellCount >= 30 ? cellCount : cellCount + 7)
      .fill(1)
      .map((x, index) => {
        const temp = periodStart.add(index, 'day');
        const weekDay = temp.day() - 1;
        const a = {
          month: temp.month() + 1,
          day: temp.date(),
          year: temp.year(),
          weekDay,
          dayweek: days[weekDay >= 0 ? weekDay : 6],
          tDate: temp
        };
        return a;
      });

    const weekNum = Array(dayList.length / 7)
      .fill(1)
      .map((i, index) => {
        const beginOfWeek = periodStart.add(1 + index * 7, 'day');
        // $FlowFixMe
        return dayjs(beginOfWeek).week();
      });

    return (
      <div>
        {showWeekNumbers && (
          <ul className={styles.weekNum}>
            <li>Week num</li>
            {_.map(weekNum, wn => (
              <li key={wn}>{wn}</li>
            ))}
          </ul>
        )}

        <div className={containerStyle}>
          <ul className={styles.header}>
            {_.map(days, one => (
              <li key={one}>{one}</li>
            ))}
          </ul>
          {_.map(
            dayList,
            ({ day: listDay, month: listMonth, tDate, weekDay }) => {
              const isCurrentMonth =
                parseInt(dayjs(tDate).format('M'), 10) === month;
              const isCurrentDay =
                dayjs(tDate).format('YYYY-MM-DD') ===
                dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');
              const isWeekDay = weekDay === 5 || weekDay === -1;
              const isSelected = parseInt(dayjs(tDate).format('D'), 10) === day;

              const styleArray = [
                styles.oneDay,
                isCurrentMonth
                  ? styles.isCurrentMonth
                  : styles.isNotCurrentMonth,
                isCurrentDay ? styles.isCurrentDay : '',
                isWeekDay ? styles.isWeekDays : '',
                isSelected ? styles.isSelected : ''
              ];

              return (
                <div
                  className={styleArray.join(' ')}
                  key={`${listMonth}-${listDay}`}
                  onClick={() =>
                    this.handleChooseClick({
                      day: listDay,
                      month: listMonth,
                      year
                    })
                  }
                >
                  {listDay}
                </div>
              );
            }
          )}
        </div>
      </div>
    );
  };

  render() {
    const { year, month } = this.state;
    const { isVisible, className = '' } = this.props;
    const style = `${styles.monthYearPicker} ${className}`.trim();

    if (isVisible) {
      return (
        <div className={style}>
          <center className={styles.padding}>Please select date</center>
          <center>
            <select
              className={styles.selectMonth}
              onChange={this.changeMonth}
              value={month}
            >
              {_.map(months, (one, key: number) => (
                <option value={Number(key + 1)} key={one}>
                  {one}
                </option>
              ))}
            </select>
            <div
              className={styles.decreaseYear}
              onClick={() => this.yearChange(false)}
            >
              <Icon.MinusButton />
            </div>
            <div className={styles.yearMain}>{year}</div>
            <div
              className={styles.increaseYear}
              onClick={() => this.yearChange(true)}
            >
              <Icon.AddButton />
            </div>
          </center>
          <div className={styles.grid}>{this.renderDays()}</div>
        </div>
      );
    }

    return null;
  }
}

export default (onClickOutsideHOC(DatePicker): React.AbstractComponent<
  Props,
  mixed
>);
