// @flow

import React, { useState } from 'react';
import moment from 'moment';

import styles from './account.module.scss';

import ShowBillInfo from './show-bill';
import Calendar from '../../assets/svg/calendar.svg';
import ElectricStation from '../../assets/svg/electric-station.svg';

// $FlowFixMe
const RenderLightChargeTransactions = (props: any): any => {
  const { data: transactions = [] } = props;
  const [showBillId, setShowBillId] = useState(false);

  const handleBillShow = (id: any) => {
    if (showBillId === id) {
      // $FlowFixMe
      return setShowBillId(null);
    }

    return setShowBillId(id);
  };

  return transactions.map(transaction => {
    if (!transaction) {
      return null;
    }

    const {
      id,
      totalAmount,
      userBalanceBefore,
      createdAt,
      Station: {
        title,
        StationPorts: [{ title: portTitle, portType, connectorId }]
      },
      kwt: totalKwt
    } = transaction;

    const transactionDateStart = moment(createdAt).format('DD-MM-YYYY, HH:mm');
    const kwt = Number(totalKwt / 1000).toFixed(2);

    return (
      <li key={id}>
        <div className={styles.chargeLight}>
          <div className={styles.summary}>
            <div>
              <img src={Calendar} className={styles.lignIcons} alt="" />
            </div>
            <div className={styles.date}>
              Balance before - {userBalanceBefore}₴
            </div>
            <div>
              <img src={Calendar} className={styles.lignIcons} alt="" />
            </div>
            <div className={styles.date}>{transactionDateStart}</div>
            <div>
              <img src={ElectricStation} className={styles.lignIcons} alt="" />
            </div>
            <div>
              {title} ({portTitle}, {portType} #{connectorId})
            </div>
          </div>

          <div className={styles.rightAlign}>
            <div>
              <span>{totalAmount || '0.00'}</span>₴
            </div>
            <div>
              <span>{kwt}</span>KWh
            </div>
          </div>

          <div className={styles.merge} onClick={() => handleBillShow(id)}>
            show more...
          </div>
        </div>
        {showBillId === id && <ShowBillInfo id={id} onClose={handleBillShow} />}
      </li>
    );
  });
};

export default RenderLightChargeTransactions;
