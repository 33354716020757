import typeToReducer from 'type-to-reducer';

import { EVTRIPS } from '../actions/types';
// import { DEFAULT_ASYNC_STATE } from './constants';

const DEFAULT_STATE = {};

// const DEFAULT_STATE = {
//   ...DEFAULT_ASYNC_STATE
// };

const tripsReducer = typeToReducer(
  {
    [EVTRIPS]: (state, action) => ({
      ...state,
      ...action.payload
    })
  },
  DEFAULT_STATE
);

export default tripsReducer;
