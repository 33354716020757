// @flow
import React, { useState } from 'react';

import Button from '../Button';
import Select from '../Select';

import styles from './ports.module.scss';

type Props = {
  showSaved: boolean,
  submitting: boolean,
  onSubmit: any,
  ports: any
};

const portTypes = ['ccs2', 'chademo', 'type2', 'j1772'];

const renderPort = (port: any) => {
  const { connectorId, title, portType, handleRemove } = port;

  return (
    <li key={connectorId}>
      <div>{connectorId}</div>
      <div>{title}</div>
      <div>{portType}</div>
      <Button
        size="small"
        onClick={() => {
          handleRemove(connectorId);
        }}
      >
        Delete
      </Button>
    </li>
  );
};

const Ports = (props: Props): any => {
  const { ports: stationPorts = [], onSubmit, submitting, showSaved } = props;
  const [ports, setPorts] = useState(stationPorts);
  const [addPort, setAddPort] = useState();
  const [addTitle, setAddTitle] = useState();

  const handleSave = () => {
    if (onSubmit && typeof onSubmit === 'function') {
      onSubmit(ports);
    }
  };

  const handleRemove = (connectorId: any) => {
    const result = ports
      .filter(x => x.connectorId !== connectorId)
      .map((item, index) => ({
        ...item,
        connectorId: index + 1
      }));

    setPorts(result);
  };

  return (
    <div className={styles.ports}>
      <ul className={styles.list}>
        {ports.map((item, index) =>
          renderPort({ ...item, connectorId: index + 1, handleRemove })
        )}
      </ul>

      <div className={styles.add}>
        <input
          name="addTitle"
          placeholder="Add connector title"
          value={addTitle}
          onChange={({ target: { value: val } }) => setAddTitle(val)}
        />
        <Select
          name="portType"
          className={styles.select}
          // $FlowFixMe
          options={portTypes}
          value={addPort}
          onChange={setAddPort}
        />

        <Button
          size="small"
          disabled={!addTitle || !addPort || submitting}
          loading={submitting}
          onClick={() => {
            if (addPort && addTitle) {
              const newPorts = [...ports];
              newPorts.push({
                portType: addPort,
                title: addTitle
              });

              setAddPort();
              setAddTitle();
              setPorts(
                newPorts.map((item, index) => ({
                  ...item,
                  connectorId: index + 1
                }))
              );
            }
          }}
        >
          Add
        </Button>
      </div>
      <Button onClick={handleSave}>Save</Button>
      {showSaved && <p>Ports configuration was saved...</p>}
    </div>
  );
};

export default Ports;
