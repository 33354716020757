// @flow

import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';

import validationSchema from './validation-schema';
import Button from '../../Button';
import styles from './tarif.module.scss';
import { renderInput } from '../../render-methods';

import { updateUserTarif } from '../../../../lib/api';
import DatePicker from '../../DatePicker';

/**
 * Props
 */
type Props = {
  data: any,
  onSubmit: any,
  values: any
};

const TarifForm = (props: Props): any => {
  const { values: initialValues, data, onSubmit } = props;
  const { API_URL, Currencies = [] } = data;

  const [isSubmitting, setSubmitting] = useState(false);
  // const [isChanged, setChanged] = useState();
  const [showPicker1, setPicker1] = useState(false);
  const [showPicker2, setPicker2] = useState(false);

  const handleSave = async (values: any) => {
    await updateUserTarif({
      API_URL,
      values
    });

    if (onSubmit && typeof onSubmit === 'function') {
      onSubmit();
    }
  };

  const renderSubmitButton = (disabled: boolean) => (
    <div>
      <Button
        // disabled={!isChanged || disabled || isSubmitting}
        disabled={disabled || isSubmitting}
        loading={isSubmitting}
      >
        Save changes
      </Button>
      <Button
        className={styles.cancelBtn}
        color="gray"
        onClick={() => {
          if (onSubmit && typeof onSubmit === 'function') {
            onSubmit();
          }
        }}
      >
        Cancel
      </Button>
    </div>
  );

  const checkZero = (item: any) => (Number(item) < 10 ? `0${item}` : item);

  return (
    <div className={styles.container}>
      <Formik
        validateOnChange
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={async (values: any, actions: any) => {
          setSubmitting(true);
          await handleSave(values);
          actions.setSubmitting(false);
          setSubmitting(false);
        }}
      >
        {(formProps: any) => {
          const { setFieldValue, values: fieldValues } = formProps;
          const { startDay, finishDay, startHour, finishHour } = fieldValues;

          return (
            <Form>
              <div className={styles.grid}>
                <div className={styles.caption}>Title</div>
                <Field name="title">
                  {(properties: any) =>
                    renderInput({
                      ...properties,
                      placeholder: 'Enter tarif title'
                    })
                  }
                </Field>

                <div className={styles.caption}>Active dates</div>
                <div className={styles.tripleDates}>
                  <div>
                    <Field name="startDay">
                      {(properties: any) => {
                        const {
                          field: { name: fieldName }
                        } = properties;

                        return (
                          <div>
                            {renderInput({
                              ...properties,
                              onFocus: () => setPicker1(true)
                            })}
                            <DatePicker
                              isVisible={showPicker1}
                              onSelect={values => {
                                const { day, month, year } = values;
                                const dy = checkZero(day);
                                const mn = checkZero(month);

                                setFieldValue(fieldName, `${dy}.${mn}.${year}`);
                                setPicker1(false);
                              }}
                              onClickOutside={() => setPicker1(false)}
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>

                  <div>to</div>

                  <Field name="finishDay">
                    {(properties: any) => {
                      const {
                        field: { name: fieldName }
                      } = properties;

                      return (
                        <div>
                          {renderInput({
                            ...properties,
                            onFocus: () => setPicker2(true)
                          })}
                          <DatePicker
                            isVisible={showPicker2}
                            onSelect={values => {
                              const { day, month, year } = values;
                              const dy = checkZero(day);
                              const mn = checkZero(month);

                              setFieldValue(fieldName, `${dy}.${mn}.${year}`);
                              setPicker2(false);
                            }}
                            onClickOutside={() => setPicker2(false)}
                          />
                        </div>
                      );
                    }}
                  </Field>

                  {(startDay || finishDay) && (
                    <div
                      className={styles.clear}
                      onClick={() => {
                        setFieldValue('startDay', '');
                        setFieldValue('finishDay', '');
                      }}
                    >
                      &times;
                    </div>
                  )}
                </div>

                <div className={styles.caption}>Active hours</div>
                <div className={styles.triple}>
                  <Field name="startHour">{renderInput}</Field>
                  <div>to</div>
                  <Field name="finishHour">{renderInput}</Field>

                  {(startHour || finishHour) && (
                    <div
                      className={styles.clear}
                      onClick={() => {
                        setFieldValue('startHour', '');
                        setFieldValue('finishHour', '');
                      }}
                    >
                      &times;
                    </div>
                  )}
                </div>

                <div className={styles.caption}>Currency</div>
                <div className={styles.context}>
                  <select
                    name="currencyCode"
                    onChange={({ target: { value } }) => {
                      setFieldValue('currencyCode', value);
                    }}
                  >
                    <option value={initialValues.currencyCode}>
                      Select currency
                    </option>
                    {Currencies.map(item => (
                      <option
                        key={item.code}
                        value={item.code}
                        selected={item.code === initialValues.currencyCode}
                      >
                        {item.code}
                      </option>
                    ))}
                  </select>
                </div>

                <div className={styles.caption}>Price (kwt)</div>
                <Field name="priceKwt">{renderInput}</Field>

                <div className={styles.caption}>Price (hour)</div>
                <Field name="priceHour">{renderInput}</Field>

                <div className={styles.caption}>Price (idle hour)</div>
                <Field name="priceStayHour">{renderInput}</Field>
              </div>

              {renderSubmitButton(false)}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TarifForm;
