import React from 'react';
import moment from 'moment';

import Button from '../../components/Button';
import styles from './_route.module.scss';

import translation from '../../../../translate';

/*

distance: {text: "130 km", value: 130078}
duration: {text: "1 hour 45 mins", value: 6291}
end_address: "Neuschwansteinstraße 20, 87645 Schwangau, Germany"
end_location: {lat: function, lng: function, toString: function, toJSON: function, equals: function, …}
start_address: "Lauchstädter Str. 10, 80807 München, Germany"

*/

const renderOnePoint = ({
  item,
  index,
  onDelete,
  enableDeletion,
  language
}: any): any => {
  const {
    distance: { text: distanceText },
    duration: { text: durationText },
    end_address: endAddress,
    start_address: startAddress
  } = item;

  const letterA = String.fromCharCode(index + 65);
  const letterB = String.fromCharCode(index + 66);

  return (
    <li key={index} className={styles.onePoint}>
      <div className={styles.letter}>
        <h3>{letterA}</h3>
        <h4>{letterB}</h4>
        <div className={styles.distance}>{distanceText}</div>
      </div>
      <div>
        {enableDeletion && (
          <Button size="small" onClick={() => onDelete(index)}>
            {translation('_DELETE', language)}
          </Button>
        )}
        <div className={styles.smallInfo}>
          <b>{translation('_FROM', language)}:</b>&nbsp;{startAddress}
        </div>
        <div className={styles.smallInfo}>
          <b>{translation('_TO', language)}:</b>&nbsp;{endAddress}
        </div>
        <div>
          <b>{translation('_DURATION', language)}:</b>&nbsp;{durationText}
        </div>
      </div>
    </li>
  );
};

const Route = (props: any): any => {
  const { points = [], places = [], language } = props;
  let distance = 0;
  let duration = 0;

  if (points.length) {
    points.forEach(point => {
      if (point.distance) {
        distance += point.distance.value;
      }

      if (point.duration) {
        duration += point.duration.value;
      }
    });

    const durationHr = moment(duration * 1000).format('H');
    const durationMin = moment(duration * 1000).format('mm');

    const onCopy = () => {
      const output = points
        .map(item => ({
          distance: item.distance.text,
          from: item.start_address,
          to: item.end_address,
          duration: item.duration.text,
          link: `https://www.google.com/maps/place/${item.end_location.toUrlValue()}`
        }))
        .map((item, index) => [
          `<h2>${index + 1}. ${places[index].title}</h2>`,
          `<b>From</b>: ${item.from}<br>`,
          `<b>To:</b> ${item.to}<br>`,
          `<b>Distance</b>: ${item.distance}<br>`,
          `<b>Duration</b>: ${item.duration}<br>`,
          `<b>Map</b>: <a href="${item.link}">${item.link}</a><br><br>`
        ].join('\n'))
        .join('\n\n');

      navigator.clipboard.write([
        new ClipboardItem({
            "text/html": new Blob([output], { type: "text/html" }),
            "text/plain": new Blob([output], { type: "text/plain" })
        })
      ]);

      console.log(points);
      // navigator.clipboard.writeText(output);
    }

    return (
      <div className={styles.route}>
        <div>
          {translation('_TOTAL_DISTANCE', language)}:&nbsp;
          <span className={styles.number}>{distance / 1000}</span>km
        </div>
        <div>
          {translation('_TOTAL_DURATION', language)}:&nbsp;
          <span className={styles.number}>{durationHr}</span>hr&nbsp;
          <span className={styles.number}>{durationMin}</span>min&nbsp;
        </div>
        <Button onClick={onCopy}>{translation('_COPY_LINKS', language)}</Button>
        {points.map((item, index) => renderOnePoint({ item, index, language }))}
      </div>
    );
  }

  return null;
};

export default Route;
