// @flow

import React from 'react';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './charge-outside-ua.module.scss';
import Section from '../../components/Section';

import metaValues from '../../../config';
import countries from './data.json';

// const scroll = Scroll.animateScroll;

const openInNewTab = (url: any) => {
  window.open(url, '_blank', 'noopener,noreferrer');
  // const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  // if (newWindow) {
  //   newWindow.opener = null;
  // }
};

const renderItem = (item: any) => {
  const { title, text, url } = item;

  return (
    <li>
      <h3>{title}</h3>
      {text.map(oneRow => (
        <p>{oneRow}</p>
      ))}
      {url && (
        <div className={styles.url} onClick={() => openInNewTab(url)}>
          {url}
        </div>
      )}
    </li>
  );
};

const renderVignette = (vignette: any) => {
  const lines = vignette.join('\n');
  return (
    <div
      className={styles.vignette}
      dangerouslySetInnerHTML={{ __html: lines }}
    />
  );
};

// https://docs.google.com/document/d/1Kfy2SGQCPioWDUDyiWluBiVA3RNgs3G-_f5D3Qed2K4/edit#heading=h.1jkfszmfqnl5

const OutsideScreen = (): any => {
  const { currCountry = 'Україна' } = useParams();
  const common = useSelector(({ common: comm }: any) => comm);
  const { language } = common;

  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;
  const countryList = countries.map(({ title }) => title);

  // console.log(currCountry);

  return (
    <section className={styles.section}>
      <Helmet>
        <title>Charging outside Ukraine - {defaultTitle}</title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={defaultTitle} />
      </Helmet>

      <Section>
        <ul className={styles.countryList}>
          {countryList.map(country => {
            const current = currCountry === country ? styles.current : '';
            return (
              <li className={current}>
                <Link to={`/${language}/charge-out-ukraine/${country}`}>
                  {country}
                </Link>
              </li>
            );
          })}
        </ul>

        {!currCountry && (
          <div>
            <p>Please, choose the country what you`re interesting</p>
            <p>
              If general information that you looking is absent - message to us
              and we`ll add that to this section
            </p>
          </div>
        )}

        {countries.map(({ title, items, vignette }) => {
          const isVisible = currCountry === title;
          if (!isVisible) {
            return null;
          }

          return (
            <div>
              <h2>{title}</h2>
              {vignette && vignette.length && renderVignette(vignette)}
              <ul>{items.map(renderItem)}</ul>
            </div>
          );
        })}
      </Section>
    </section>
  );
};

export default OutsideScreen;
