// @flow

import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import validationSchema from './validation-schema';
import Button from '../../Button';
import styles from './account-form.module.scss';
import { renderInput } from '../../render-methods';

import { setUserState } from '../../../store/actions/auth';
import { setUser } from '../../../../lib/api';

/**
 * Props
 */
type Props = {
  data: any,
  values: any
};

const AccountForm = (props: Props): any => {
  const { values: initialValues, data } = props;
  const { API_URL } = data;

  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);

  const renderSubmitButton = (disabled: boolean) => (
    <Button
      size="large"
      disabled={disabled || isSubmitting}
      loading={isSubmitting}
    >
      Save profile
    </Button>
  );

  const handleSave = async (values: any) => {
    dispatch(setUserState(_.omit(values, 'email')));
    setUser({
      API_URL,
      values: _.omit(values, 'email')
    });
  };

  return (
    <Formik
      validateOnChange
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async (values: any, actions: any) => {
        setSubmitting(true);
        await handleSave(values);
        actions.setSubmitting(false);
        setSubmitting(false);
      }}
    >
      {() => (
        <Form className={styles.grid}>
          <div className={styles.firstName}>
            <Field name="firstName">
              {(properties: any) =>
                renderInput({
                  ...properties,
                  size: 20,
                  placeholder: 'Enter your first name'
                })
              }
            </Field>
          </div>

          <div className={styles.expire}>
            <Field name="lastName">
              {(properties: any) =>
                renderInput({
                  ...properties,
                  size: 20,
                  placeholder: 'Enter your last name'
                })
              }
            </Field>
          </div>

          <div className={styles.expire}>
            <Field name="email">
              {(properties: any) =>
                renderInput({
                  ...properties,
                  disabled: true,
                  size: 20
                })
              }
            </Field>
          </div>

          <div className={styles.expire}>
            <Field name="phone">
              {(properties: any) =>
                renderInput({
                  ...properties,
                  size: 20
                })
              }
            </Field>
          </div>

          {renderSubmitButton(false)}
        </Form>
      )}
    </Formik>
  );
};

export default AccountForm;
