// @flow
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import Button from '../Button';

import styles from './port-tarifs.module.scss';

type Props = {
  submitting: boolean,
  userTarifs: any,
  ports: any,
  showSaved: boolean,
  onSubmit: any
};

const PortTarifs = (props: Props): any => {
  const {
    onSubmit,
    ports = [],
    userTarifs = [],
    showSaved = false,
    submitting
  } = props;
  // const { ports: stationPorts = [] } = props;

  const currentState = ports.reduce((a, item) => {
    const { connectorId, portTarifs = [] } = item;

    return {
      ...a,
      [connectorId]: portTarifs
    };
  }, {});

  const currentStyles = ports.reduce((a, item) => {
    const { connectorId } = item;
    return {
      ...a,
      [connectorId]: styles.defaultArea
    };
  }, {});

  const [state, setState] = useState(currentState);
  const [style, setStyle] = useState(currentStyles);

  const onDragEnter = (ev: any, area: any) => {
    // console.log('onDragEnter');
    // console.log(ev, area);

    const newStyles = { ...style };
    newStyles[area] = styles.hoverArea;

    setStyle(newStyles);
  };

  const onDragLeave = (ev: any, area: any) => {
    // console.log('onDragLeave');
    // console.log(ev, area);

    const newStyles = { ...style };
    newStyles[area] = styles.defaultArea;
    setStyle(newStyles);
  };

  const onDragStart = (ev: any, id: any) => {
    ev.dataTransfer.setData('id', id);
  };

  const onDragOver = (ev: any) => {
    ev.preventDefault();
  };

  const onDrop = (ev: any, area: any) => {
    const id = Number(ev.dataTransfer.getData('id'));
    const areaId = Number(area);
    const item = userTarifs.find(x => x.id === id);

    const search = state[areaId].find(x => x.id === id);

    if (!search) {
      const newState = { ...state };
      newState[areaId].push(item);
      setState(newState);

      const newStyles = { ...style };
      newStyles[areaId] = styles.defaultArea;

      setStyle(newStyles);
    }

    return true;
  };

  const handleSubmit = () => {
    if (onSubmit && typeof onSubmit === 'function') {
      const out = [];
      Object.keys(state).forEach(connectorId => {
        const port = ports.find(
          x => Number(x.connectorId) === Number(connectorId)
        );

        const portId = _.get(port, 'id');
        const tarifIds = state[connectorId].map(({ id }) => id);

        if (portId) {
          out.push({
            connectorId: Number(connectorId),
            portId,
            tarifIds
          });
        }
      });

      onSubmit(out);
    }
  };

  const renderPort = (port: any) => {
    const { connectorId, title, portType } = port;
    const portTarifs = state[connectorId];
    const portStyle = style[connectorId];

    return (
      <li key={connectorId}>
        <div className={styles.grid}>
          <div>{connectorId}</div>
          <div>{title}</div>
          <div>{portType}</div>
        </div>

        <div className={styles.gridFull}>
          <div
            className={portStyle}
            onDragEnter={ev => onDragEnter(ev, connectorId)}
            onDragLeave={ev => onDragLeave(ev, connectorId)}
            onDragOver={onDragOver}
            onDrop={ev => onDrop(ev, connectorId)}
          >
            <div className={styles.text}>Drag and drop tarif here to apply</div>
            {portTarifs.map(item => (
              <div
                key={item.id}
                className={styles.droppedTarif}
                onClick={() => {
                  const newState = { ...state };

                  newState[connectorId] = portTarifs.filter(
                    x => x.id !== item.id
                  );

                  setState(newState);
                }}
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>
      </li>
    );
  };

  const renderValue = (value: any, currency: any) => {
    if (Number(value) > 0) {
      return (
        <span>
          {value}
          <span className={styles.currencyCode}>{currency}</span>
        </span>
      );
    }

    return <span className={styles.zero}>0</span>;
  };

  return (
    <div className={styles.content}>
      <div className={styles.grid}>
        <div className={styles.ports}>
          <h3>Station ports</h3>
          <ul className={styles.list}>{ports.map(renderPort)}</ul>
        </div>

        <div className={styles.dropTarifs}>
          <Link className={styles.link} to="/manage/tarifs">
            Edit tarifs
          </Link>
          <h3>Available user tafis for applying</h3>
          <p>Drag tarif to port for apply tarif on that port</p>
          <div className={styles.tarifs}>
            <div className={`${styles.item} ${styles.header}`}>
              <div className={styles.title}>Title</div>
              <div className={styles.type}>Type</div>
              <div className={styles.range}>Range</div>
              <div className={styles.prices}>Prices</div>
            </div>
            {userTarifs.map(item => {
              const dtFormat = 'DD.MM.YYYY';
              const stDay = moment(item.startDay, 'DD.MM.YYYY').format(
                dtFormat
              );
              const fnDay = moment(item.finishDay, 'DD.MM.YYYY').format(
                dtFormat
              );

              const hours =
                item.startHour && item.finishHour ? (
                  `${String(item.startHour)} - ${String(item.finishHour)}`
                ) : (
                  <span>default</span>
                );

              const range =
                item.tarif === 'days' ? `${stDay} - ${fnDay}` : hours;

              return (
                <div key={item.id} className={styles.item}>
                  <div
                    className={styles.title}
                    onDragStart={e => onDragStart(e, item.id)}
                    draggable
                  >
                    {item.title}
                  </div>
                  <div className={styles.type}>
                    {item.tarif && <span>{item.tarif}</span>}
                  </div>
                  <div className={styles.range}>{range}</div>
                  <div className={styles.prices}>
                    <div>{renderValue(item.priceHour, item.currencyCode)}</div>
                    <div>{renderValue(item.priceKwt, item.currencyCode)}</div>
                    <div>
                      {renderValue(item.priceStayHour, item.currencyCode)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <hr />

      <Button onClick={handleSubmit} disabled={submitting} loading={submitting}>
        Save
      </Button>

      {showSaved && <p>Changes has been saved...</p>}
    </div>
  );
};

export default PortTarifs;
