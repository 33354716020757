/*
 * action types
 */
export const COMMON = 'COMMON';
export const EVHOTELS = 'EVHOTELS';
export const EVTRIPS = 'EVTRIPS';
export const EVTRIP = 'EVTRIP';
export const USER_LOGIN = 'USER_LOGIN';
export const SET_USER_STATE = 'SET_USER_STATE';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const GET_USER_DATA = 'GET_USER_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_STATIONS = 'SET_STATIONS';
export const GET_STATIONS = 'GET_STATIONS';
export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';
export const THEME_CHANGE = 'THEME_CHANGE';
