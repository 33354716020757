// @flow
import * as React from 'react';

import styles from './button-switch.module.scss';

type Props = {
  pressed?: boolean,
  className?: string,
  children?: React.Node,
  onChange?: (event: SyntheticEvent<HTMLButtonElement>) => void
};

const Button = ({
  children = '',
  pressed = false,
  className = '',
  onChange = () => {}
}: Props): any => {
  const style = pressed ? styles.pressed : styles.default;

  return (
    <button className={`${style} ${className}`.trim()} onClick={onChange}>
      {children}
    </button>
  );
};

export default Button;
