import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Section from '../../components/Section';

import Planning from '../../assets/svg/workspace-4.svg';

import styles from './promo.module.scss';

const PromoScreen = () => {
  const title = 'Example';
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      const next = current === 4 ? 0 : current + 1;
      setCurrent(next);
    }, 3000);

    return () => {
      // 👇️ clear timeout when the component unmounts
      clearTimeout(timeoutID);
    };
  });

  return (
    <section>
      <Helmet>
        <title>Planning a trip - EV Trips Planner</title>
        <meta
          name="description"
          content="Smart trip planning for electric cars"
        />
        <meta
          property="keywords"
          content="Planning a trip, ev car, electric car trip"
        />
        <meta property="og:title" content={title} />
      </Helmet>

      <Section className={styles.content}>
        <Link to="/">Home</Link>
        <center>
          <h1>Planning a trip</h1>
        </center>

        <div className={styles.grid}>
          <div>
            <ul>
              {current === 0 && (
                <li>
                  <h3>Stage 1</h3>
                  <h2>
                    Where are you <nobr>going?</nobr>
                  </h2>
                  <p>
                    Indicate your departure point and destination, the battery
                    level at the start of the journey and the level you want at
                    the end of it, plus any midway stages.
                  </p>
                </li>
              )}

              {current === 1 && (
                <li>
                  <h3>Stage 2</h3>
                  <h2>
                    What car model do you <nobr>drive?</nobr>
                  </h2>
                  <p>Enter the EV you are taking on the journey.</p>
                </li>
              )}

              {current === 2 && (
                <li>
                  <h3>Stage 3</h3>
                  <h2>
                    Do you want to enjoy a motorway-free <nobr>trip?</nobr>
                  </h2>
                  <p>
                    Avoid motorways, avoid tolls and enter a maximum speed to
                    save on energy.
                  </p>
                </li>
              )}

              {current === 3 && (
                <li>
                  <h3>Stage 4</h3>
                  <h2>
                    Enjoy the optimum route adapted to your <nobr>needs</nobr>
                  </h2>
                  <p>
                    Chargemap automatically calculates your route and selects
                    the charging stations that match up with the information you
                    have logged in. You can also visualise your route directly:
                    distance, overall travel time and the necessary charging
                    stop(s) along the way.
                  </p>
                </li>
              )}

              {current === 4 && (
                <li>
                  <h3>Stage 5</h3>
                  <h2>
                    You’re on your <nobr>way!</nobr>
                  </h2>
                  <p>
                    Access information about the charging station on the details
                    screen, save your route if necessary and launch the
                    navigation tool for your next charging stop on your
                    favourite GPS app.
                  </p>
                </li>
              )}
            </ul>
          </div>
          <div>
            <img src={Planning} alt="Planning a trip" />
          </div>
        </div>
      </Section>
    </section>
  );
};

export default PromoScreen;
