// @flow

import { useState, useEffect } from 'react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import DragAndDrop from '../../components/DragAndDrop';
import { story } from '../../../lib/api';
import styles from './story-edit.module.scss';
import Button from '../../components/Button';
import Textarea from '../../components/Textarea';
import Switcher from '../../components/Switcher';
import Input from '../../components/Input';
import StoryPoint from './story-point';
import RenderOnePoint from './render-point';

import translation from '../../../../translate';

const StoryAdd = (): any => {
  const { id } = useParams();
  const common = useSelector(({ common: comm }: any) => comm);
  const { API_URL, language } = common;

  const [isLoading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(!id);
  const [isSaving, setSaving] = useState(false);
  const [isError, setError] = useState(false);
  const [time, setTime] = useState(new Date());
  const [data, setData] = useState();
  const [showAdd, setShowAdd] = useState(false);
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');

  const { images } = data || { images: [] };

  const [image, setImage] = useState(images);
  const [status, setStatus] = useState(false);

  const storyImage = image && image[0] ? image[0].src : null;
  const history = useNavigate();

  const savePointData = async (pointData: any) => {
    const response = await story.savePoint(pointData, API_URL);
    const { EVRoutePoints } = data || { EVRoutePoints: [] };
    const newPoints = [].concat(response, EVRoutePoints);

    const newData = {
      ...data,
      EVRoutePoints: newPoints
    };

    // $FlowFixMe
    setData(newData);
    setShowAdd(false);
    setTime(new Date());
  };

  const saveStory = async () => {
    setSaving(true);
    try {
      const saveItem = {
        id,
        title,
        description,
        status,
        image
      };

      await story.save(saveItem, API_URL);

      setSaving(false);
      setDescription('');
      setTitle('');
      setImage([]);
      setStatus(false);
      setTime(new Date());
      history(`/${common.language}/stories`);
    } catch (error) {
      setError(error);
    }
  };

  const handleLoad = async () => {
    setLoading(true);
    const response = await story.load(id, API_URL);
    const {
      title: loadTitle,
      status: statusData,
      description: desc,
      images: imagesData
    } = response;

    setData(response);
    setTitle(loadTitle);
    setStatus(statusData);
    setDescription(desc);

    if (imagesData && imagesData.length) {
      setImage(imagesData);
    }

    setLoading(false);
  };

  const renderPoints = () => {
    const { EVRoutePoints: list } = data || { EVRoutePoints: [] };
    return (
      <div className={styles.points}>
        <div className={styles.addButton}>
          <Button
            onClick={() => {
              setShowAdd(true);
            }}
          >
            {translation('_ADD_POINT', language)}
          </Button>
        </div>
        <h3>{translation('_CHARGE_POINT', language)}</h3>
        <ul className={styles.route}>
          {list.map(item => (
            <RenderOnePoint pointData={item} />
          ))}
        </ul>
      </div>
    );
  };

  const saveData = () => {
    saveStory();
  };

  useEffect(() => {
    const fetchData = async () => {
      // You can await here
      await handleLoad();
    };

    if (id) {
      fetchData();
    }
  }, [time, id]);

  const opTitle = id
    ? translation('_EDIT_TRIP', language)
    : translation('_ADD_TRIP', language);

  const renderEditForm = () => (
    <div>
      <Button
        className={styles.saveButton}
        disabled={isSaving}
        onClick={() => saveData()}
      >
        {translation('_SAVE', language)}
      </Button>

      <Button
        className={styles.saveButton}
        disabled={isSaving}
        onClick={() => {
          if (id) {
            setEdit(!isEdit);
          } else {
            history(-1);
          }
        }}
      >
        {translation('_CANCEL', language)}
      </Button>

      <h1>{opTitle}</h1>
      <Input
        name="title"
        value={title}
        fullWidth
        className={styles.input}
        onChange={event => setTitle(event.target.value)}
      />
      <Textarea
        name="description"
        value={description}
        onChange={event => setDescription(event.target.value)}
      />

      <div className={styles.switchBlock}>
        <Switcher
          checked={status}
          onClick={() => setStatus(!status)}
          className={styles.switcher}
        />
        <span>{translation('_ALLOW_USERS_TO_SEE', language)}</span>
      </div>

      {!showAdd && (
        <DragAndDrop
          API_URL={API_URL}
          additionalText={translation('_UPLOAD_TEXT', language)}
          buttonTitle={translation('_UPLOAD_BUTTON', language)}
          onChange={setImage}
          showLoader={false}
          images={image}
          errorMessageText={isError && isError.message}
        />
      )}
    </div>
  );

  return (
    <section className={styles.content}>
      <Helmet>
        <title>EV-UA.NET - Charging network in Ukraine</title>
        <meta property="og:title" content="EV-UA.NET" />
        <meta property="og:image" content="/assets/svg/logo.svg" />
        <meta
          property="description"
          content="Charging network in Ukraine - you can be part of the future"
        />
      </Helmet>

      <section>
        <p className={styles.link} onClick={() => history(-1)}>
          {translation('_BACK_TO_TRIPS', language)}
        </p>

        {isLoading && <PulseLoader color="#ffb700" size={10} />}

        {!isEdit && (
          <div>
            <Button
              className={styles.saveButton}
              disabled={isSaving}
              onClick={() => setEdit(!isEdit)}
            >
              {translation('_EDIT', language)}
            </Button>
            <h1>{title}</h1>

            {storyImage && (
              <div className={styles.image}>
                <img src={storyImage} alt={title} />
              </div>
            )}
          </div>
        )}

        {isEdit && renderEditForm()}

        {!isEdit && showAdd && (
          <StoryPoint
            API_URL={API_URL}
            setShowAdd={setShowAdd}
            onSave={pointData => {
              savePointData({
                ...pointData,
                routeId: id
              });
            }}
          />
        )}

        {!showAdd && id && renderPoints()}

        {isError && (
          <div>
            <h2>{translation('_ERROR', language)}</h2>
            {isError.message}
          </div>
        )}
      </section>
    </section>
  );
};

export default StoryAdd;
