// @flow

import * as React from 'react';

import styles from './error.module.scss';

type Props = {
  children?: React.Node
};

export const ErrorWrapper = ({ children = '' }: Props): any => (
  <div className={styles.errorContainer}>{children}</div>
);

export const ErrorComponent = ({ children = '' }: Props): any => (
  <div className={styles.errorMessage}>{children}</div>
);
