// @flow

import { useState } from 'react';
import * as React from 'react';
import { useGeolocated } from 'react-geolocated';
import { useSelector } from 'react-redux';

import DragAndDrop from '../../components/DragAndDrop';
import styles from './story-edit.module.scss';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';

import translation from '../../../../translate';

const StoryPoint = (props: any): any => {
  const { API_URL, setShowAdd, onSave } = props;
  const common = useSelector(({ common: comm }: any) => comm);
  const { language } = common;

  const [description, setDescription] = useState();
  const [isSaving] = useState(false);
  const [images, setImages] = useState();
  const [isFree, setIsFree] = useState(false);
  const [chargeSpeed, setChargeSpeed] = useState();

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false
      },
      userDecisionTimeout: 5000
    });

  const { latitude, longitude } = coords || {};

  return (
    <div>
      <center>
        <h3>{translation('_ADD_CHARGE_POINT', language)}</h3>
      </center>
      <Textarea
        name="description"
        value={description}
        onChange={event => setDescription(event.target.value)}
      />

      <div>
        {translation('_MAX_CHARGING_SPEED', language)}&nbsp;
        <Input
          className={styles.inputKwt}
          value={chargeSpeed}
          onChange={event => {
            setChargeSpeed(event.target.value.replace(/\D/g, ''));
          }}
          name="maxSpeed"
        />
        &nbsp; kW
      </div>

      <Checkbox
        id="freeCharge"
        checked={isFree}
        onChange={() => setIsFree(!isFree)}
      >
        {translation('_IS_CHARGING_FREE', language)}
      </Checkbox>

      <hr />

      {!isGeolocationAvailable ||
        (!isGeolocationEnabled && (
          <div className={styles.error}>
            {translation('_ERROR_GEO', language)}
          </div>
        ))}

      {isGeolocationAvailable && (
        <div>
          <b>{translation('_LOCATION_POSITION', language)}</b>
          {latitude}, {longitude}
        </div>
      )}

      <DragAndDrop
        API_URL={API_URL}
        additionalText={translation('_UPLOAD_TEXT', language)}
        buttonTitle={translation('_UPLOAD_BUTTON', language)}
        onChange={setImages}
        showLoader={false}
        multiple
        images={images}
        // errorMessageText=
      />

      <center>
        <Button
          disabled={isSaving || !isGeolocationAvailable}
          onClick={() => {
            onSave({
              description,
              latitude,
              longitude,
              images,
              isFree,
              chargeSpeed
            });
          }}
        >
          {translation('_SAVE', language)}
        </Button>
        <Button
          onClick={() => {
            setShowAdd(false);
          }}
        >
          {translation('_CANCEL', language)}
        </Button>
      </center>
    </div>
  );
};

export default StoryPoint;
