// @flow

import React, { useEffect, useState } from 'react';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HashLoader } from 'react-spinners';
import dayjs from 'dayjs';
import _ from 'lodash';

import styles from './payments.module.scss';
import Section from '../../components/Section';

import metaValues from '../../../config';
import AccountCart from '../../components/AccountCart';

import { getUserPayments } from '../../../lib/api';

import MoneyAdd from '../../assets/svg/money-add.svg';
import MoneyMinus from '../../assets/svg/money-minus.svg';

const renderOperation = (item: any) => {
  const { amount, Station } = item;

  const imgSrc = amount > 0 ? MoneyAdd : MoneyMinus;
  const colorStyle = amount > 0 ? styles.green : styles.red;
  const style = `${styles.one} ${colorStyle}`;
  const amountAbs = amount.replace('-', '');
  const kwt = _.get(item, 'ChargeOCPPTransaction.moneyData.totalKwt');
  const balanceBefore = _.get(item, 'ChargeOCPPTransaction.userBalanceBefore');

  return (
    <ul className={style}>
      <li className={styles.image}>
        <img src={imgSrc} alt="payment operation" />
      </li>
      <li className={styles.amount}>
        <span>{amountAbs}</span>₴
        <div className={styles.balanceBefore}>
          <span>{balanceBefore}</span>
        </div>
      </li>
      {!Station && <li className={styles.description}>Account top-up</li>}
      {Station && Station.title && (
        <li className={styles.description}>
          {Station.title} ({kwt}kwt)
        </li>
      )}
    </ul>
  );
};

const renderGroup = (key: any, data: any) => {
  const [DD, MM, YYYY] = key.split('-');
  let title = dayjs(`${YYYY}-${MM}-${DD}`).format('MMMM D, YYYY');
  const format = 'DD-MM-YYYY';

  if (dayjs().format(format) === key) {
    title = 'Today';
  }

  if (dayjs().subtract(1, 'day').format(format) === key) {
    title = 'Yesterday';
  }

  return (
    <div className={styles.dayGroup}>
      <div className={styles.title}>{title}</div>
      <div className={styles.context}>{data.map(renderOperation)}</div>
    </div>
  );
};

const AccountOperationsScreen = (): any => {
  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;
  const { userData, isFulfilled } = useSelector(({ user }: any) => user);
  const { API_URL } = useSelector(({ common }: any) => common);

  const [operations, setOperations] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserPayments({ API_URL });
      setOperations(data);
    };

    fetchData();
  }, []);

  if (!isFulfilled || !operations) {
    return (
      <Section className={styles.container}>
        <center>
          <HashLoader />
        </center>
      </Section>
    );
  }

  const { items } = operations;

  return (
    <Section className={styles.container}>
      <Helmet>
        <title>Payment operations - {defaultTitle}</title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={defaultTitle} />
      </Helmet>

      <div>
        <Link to="/account">Back to account</Link>
        <div className={styles.content}>
          <AccountCart userData={userData} />
          <div className={styles.secondRow}>
            <h1>Payment operations</h1>
            {Object.keys(items).map(key => renderGroup(key, items[key]))}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AccountOperationsScreen;
