import Home from './Home';
import Energy from './Energy';
import EVFriendly from './EVFriendly';
import Charging from './Charging';
import Chargers from './ChargerFind';
import ChargerAdd from './Chargers-add';
import Account from './Account';
import AccountEdit from './AccountEdit';
import AccountCards from './AccountCards';
import AccountPayments from './AccountPayments';
import AccountTopUp from './AccountTopUp';
import Station from './Station';
import StationList from './StationList';
import Page404 from './Page404';
import OutsideCharge from './Charge-Out-UA';
import PromoScreen from './Promo';

import ManageScreen from './Manage';
import ManageStation from './Manage/Station';
import AccountTarifs from './Manage/AccountTarifs';
import StationTarifs from './Manage/StationTarifs';
import OutcomeStation from './Manage/Outcomes';
import ProfitStation from './Manage/Profit';
import MapScreen from './Map';
import RouteBuilder from './RouteBuilder';
// import MapRouteView from '../screens/MapRouteView';
import ExampleScreen from './Example';
import EVHotelsScreen from './EVHotels';
import EVTripsScreen from './EVTrips';
import EVTripViewScreen from './EVTripView';
import StoryScreen from './Story';
import StoryEditScreen from './StoryEdit';

import Premium from './PremiumAccountInfo';
// import StoryPointsScreen from '../screens/StoryPoints';
// import StoryPointEditScreen from '../screens/StoryPointEdit';
// import EditorScreen from '../screens/Editor';

const Routes = [
  {
    path: '/:language/',
    element: Home,
    exact: true
  },
  {
    path: '/:language/premium',
    element: Premium,
    exact: true
  },
  {
    path: '/:language/promo',
    element: PromoScreen,
    exact: true
  },
  {
    path: '/energy',
    element: Energy,
    exact: true
  },
  {
    path: '/account',
    element: Account,
    exact: true
  },
  {
    path: '/:language/ev-hotels',
    element: EVHotelsScreen,
    exact: true
  },
  // ev-trips
  {
    path: '/:language/ev-trips',
    element: EVTripsScreen,
    exact: true
  },
  {
    path: '/:language/ev-trips/:id',
    element: EVTripViewScreen,
    exact: true
  },
  // ev-route section
  {
    path: '/:language/stories/',
    element: StoryScreen,
    exact: true
  },
  {
    path: '/:language/stories/add/',
    element: StoryEditScreen,
    exact: true
  },
  // {
  //   path: '/stories/:id/edit/',
  //   element: StoryEditScreen,
  //   protected: true,
  //   exact: true
  // },
  {
    path: '/:language/stories/:id',
    element: StoryEditScreen,
    exact: true
  },
  // {
  //   path: '/stories/:id/points/:pointId([0-9]+)?/edit',
  //   element: StoryPointEditScreen,
  //   protected: true,
  //   exact: true
  // },

  // manage section
  {
    path: '/manage',
    element: ManageScreen,
    exact: true
  },
  {
    path: '/manage/tarifs/:stationId',
    element: StationTarifs,
    exact: true
  },
  {
    path: '/manage/tarifs',
    element: AccountTarifs,
    exact: true
  },
  {
    path: '/manage/profit/:stationId',
    element: ProfitStation,
    exact: true
  },
  {
    path: '/manage/profit/outcome/:stationId',
    element: OutcomeStation,
    exact: true
  },
  {
    path: '/manage/station/:stationId',
    element: ManageStation,
    exact: true
  },

  // ---------------------------------------------

  {
    path: '/account/manage',
    element: AccountEdit,
    exact: true
  },
  {
    path: '/account/top-up',
    element: AccountTopUp,
    exact: true
  },
  {
    path: '/account/payments',
    element: AccountPayments,
    exact: true
  },
  {
    path: '/account/cards',
    element: AccountCards,
    exact: true
  },

  // ---------------------------------------------------
  {
    path: '/:language/map-route',
    element: RouteBuilder,
    exact: true
  },
  {
    path: '/:language/map-route/:id',
    // element: MapRouteView,
    element: RouteBuilder,
    exact: true
  },
  {
    path: '/:language/map',
    element: MapScreen,
    exact: true
  },
  {
    path: '/example',
    element: ExampleScreen,
    exact: true
  },
  {
    path: '/:language/charge-out-ukraine/',
    element: OutsideCharge,
    exact: true
  },
  {
    path: '/:language/charge-out-ukraine/:currCountry',
    element: OutsideCharge,
    exact: true
  },
  {
    path: '/:language/ev-friendly',
    element: EVFriendly,
    exact: true
  },
  {
    path: '/:language/chargers',
    element: Chargers,
    exact: true
  },
  {
    path: '/:language/chargers/how-to-add',
    element: ChargerAdd,
    exact: true
  },
  {
    path: '/:language/charging',
    element: Charging,
    exact: true
  },
  {
    path: '/:language/stations',
    element: StationList,
    exact: true
  },
  {
    path: '/:language/station/:stationId',
    element: Station,
    exact: true
  },
  {
    exact: false,
    path: '*',
    element: Page404
  }
];

export default Routes;
