// @flow

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { PulseLoader } from 'react-spinners';

import dynamic from '../../../lib/dynamic';
import { energy } from '../../../lib/api';
import Button from '../../components/Button';
import Input from '../../components/Input';
import DatePicker from '../../components/DatePicker';

import styles from './energy.module.scss';

const EnergyScreen = (): any => {
  const ReactApexChart = dynamic(() => import('react-apexcharts'), {
    ssr: false
  });

  const user = useSelector(({ user: usr }: any) => usr);
  const { API_URL } = useSelector(({ common }: any) => common);
  const history = useNavigate();
  const { userData } = user || {};
  const { device: devices = [] } = userData || { device: [] };

  // $FlowFixMe
  const [data, setData] = useState([]);
  const [date, setDate] = useState(moment().format('YYYYMMDD'));
  const [currentMonth, setCurrentMonth] = useState(moment().format('YYYY-MM'));
  const [stat, setStat] = useState({});

  const [currentDevice, setCurrentDevice] = useState();
  const [isMonthVisible, setMonthVisible] = useState(false);
  const [isYearVisible, setYearVisible] = useState(false);
  const [monthData, setMonthData] = useState();
  const [yearData, setYearData] = useState();
  const [isLoadingMonth, setLoadingMonth] = useState(false);
  const [isLoadingYear, setLoadingYear] = useState(false);

  const [totalMoney, setTotalMoney] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isShowPrice, setShowPrice] = useState(false);
  const [isShowKwt, setShowKwt] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const series1 = data.map(x => x.total);
  const series2 = data.map(x => x.averageV);

  const chartData = {
    series: [
      {
        name: 'Consuming, kWh',
        type: 'column',
        data: series1
      },
      {
        name: 'Voltage',
        type: 'line',
        data: series2
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false
      },
      plotOptions: {
        bar: {
          columnWidth: '96%'
        },
        dataLabels: {
          offsetY: -150
        }
      },
      dataLabels: {
        enabled: true,
        formatter: (val: any, opt: any) => {
          const { seriesIndex, dataPointIndex } = opt;

          if (seriesIndex === 0) {
            if (isShowKwt && !isShowPrice) {
              return `${val}kWh`;
            }

            if (isShowPrice && isShowKwt) {
              return [`${val}kWh`, data[dataPointIndex].priceTotal];
            }

            if (isShowPrice && !isShowKwt) {
              return data[dataPointIndex].priceTotal;
            }

            return '';
          }

          return val;
        },
        style: {
          colors: ['', 'red'],
          fontSize: 13
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          borderRadius: 2,
          padding: 10
        }
      },
      stroke: {
        width: [1, 6],
        colors: ['', 'red']
      },
      xaxis: {
        categories: data.map(x => {
          const [time] = x.dt.split(',').reverse();
          return time;
        })
      },
      yaxis: [
        {
          min: 0,
          max: 8,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#008FFB'
          },
          labels: {
            style: {
              colors: '#008FFB'
            }
          },
          title: {
            text: 'Consuming, kWh',
            style: {
              color: '#008FFB'
            }
          },
          tooltip: {
            enabled: true
          }
        },
        {
          min: 190,
          max: 255,
          opposite: true,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: '#00E396'
          },
          labels: {
            show: true,
            style: {
              colors: 'red'
            }
            // offsetX: -100
          },
          title: {
            text: 'Voltage',
            style: {
              color: 'red'
            }
          }
        }
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        }
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40
      }
    }
  };

  const { data: monthInfo = [] } = monthData || { data: [] };

  const labels = monthInfo.map((x: any) =>
    moment(x.date, 'YYYY-MM-DD').format('MMM Do')
  );

  const monthChart = {
    series: [
      {
        name: 'Night consuming',
        type: 'column',
        data: monthInfo.map(x => Number(x.nightKwt.toFixed(3)))
      },
      {
        name: 'Total consuming',
        type: 'area',
        data: monthInfo.map(x => Number(x.totalCount.toFixed(3)))
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        stacked: false
      },
      stroke: {
        width: [0, 2, 5],
        curve: 'smooth'
      },
      plotOptions: {
        bar: {
          columnWidth: '50%'
        }
      },

      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },
      labels,
      markers: {
        size: 0
      },
      yaxis: {
        title: {
          text: 'Kwh`s'
        },
        min: 0
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y: any) => {
            if (typeof y !== 'undefined') {
              return `${y.toFixed(0)} kWh's`;
            }

            return y;
          }
        }
      }
    }
  };

  const loadMetricsData = async (deviceId: any) => {
    setLoading(true);

    const {
      data: response,
      statStart,
      statFinish,
      nightKwt,
      dayKwt,
      nightPrice,
      totalKwt,
      totalPrice,
      dayPrice,
      todayDayKwt,
      todayNightKwt
    } = await energy.day({ date, deviceId, API_URL });

    const total = response.reduce((a, b) => a + b.priceTotal, 0).toFixed(2);
    setTotalMoney(total);
    setLoading(false);
    setData(response);
    setStat({
      todayDayKwt,
      todayNightKwt,
      statStart,
      statFinish,
      nightKwt,
      dayKwt,
      nightPrice,
      dayPrice,
      totalKwt,
      totalPrice
    });
  };

  const loadMonthData = async (deviceId: any) => {
    setLoadingMonth(true);
    const response = await energy.month({
      deviceId,
      month: currentMonth,
      API_URL
    });
    setLoadingMonth(false);
    setMonthData(response);

    const {
      statStart,
      statFinish,
      nightKwt,
      dayKwt,
      nightPrice,
      totalKwt,
      totalPrice,
      dayPrice,
      todayDayKwt,
      todayNightKwt
    } = response;

    setStat({
      todayDayKwt,
      todayNightKwt,
      statStart,
      statFinish,
      nightKwt,
      dayKwt,
      nightPrice,
      dayPrice,
      totalKwt,
      totalPrice
    });
  };

  const loadYearData = async (deviceId: any) => {
    setLoadingYear(true);
    const response = await energy.year({ deviceId, API_URL });
    setLoadingYear(false);
    setYearData(response);
  };

  const renderYearTable = (mData: any) => (
    <table className={styles.monthTable}>
      <tr>
        <td>Month</td>
        <td>dayKwt</td>
        <td>dayPrice</td>
        <td>nightKwt</td>
        <td>nightPrice</td>
        <td>totalKwt</td>
        <td>totalPrice</td>
      </tr>
      {Object.keys(mData).map(day => {
        const { dayKwt, dayPrice, nightKwt, nightPrice, totalKwt, totalPrice } =
          mData[day];
        return (
          <tr>
            <td>{day}</td>
            <td>{dayKwt}</td>
            <td>{dayPrice}</td>
            <td>{nightKwt}</td>
            <td>{nightPrice}</td>
            <td>{totalKwt}</td>
            <td>{totalPrice}</td>
          </tr>
        );
      })}
    </table>
  );

  useEffect(() => {
    if (!isMonthVisible) {
      loadMetricsData(currentDevice);
    }

    if (isMonthVisible) {
      loadMonthData(currentDevice);
    }

    if (isYearVisible) {
      loadYearData(currentDevice);
    }
  }, [date, currentDevice, isYearVisible, isMonthVisible, currentMonth]);

  const {
    // $FlowFixMe
    nightKwt,
    // $FlowFixMe
    dayKwt,
    // $FlowFixMe
    dayPrice,
    // $FlowFixMe
    nightPrice,
    // $FlowFixMe
    totalKwt,
    // $FlowFixMe
    totalPrice,
    // $FlowFixMe
    todayDayKwt,
    // $FlowFixMe
    todayNightKwt
  } = stat || {};

  return (
    <section className={styles.content}>
      <div className={styles.center}>
        <select
          value={currentDevice}
          onChange={event => {
            setCurrentDevice(event.target.value);
            history(`/energy/${event.target.value}`);
          }}
        >
          <option value="">Choose device</option>
          {devices.map(device => (
            <option value={device.deviceId} key={device.deviceId}>
              {device.title} ({device.deviceId})
            </option>
          ))}
        </select>
      </div>
      <h2>Потребление электроэнергии (месяц):</h2>
      {stat && Object.keys(stat).length > 0 && (
        <div className={styles.priceArea}>
          <div>
            Дневное потребление: <span>{dayKwt}</span> кВт
            <span className={styles.price}>{dayPrice}</span> грн
          </div>
          <div>
            Ночное потребление: <span>{nightKwt}</span> кВт
            <span className={styles.price}>{nightPrice}</span> грн
          </div>
          <div className={styles.total}>
            Потребление за месяц: <span>{totalKwt}</span> кВт
            <span className={styles.price}>{totalPrice}</span> грн
          </div>
        </div>
      )}
      <div className={styles.center}>
        <Button size="medium" onClick={() => setYearVisible(!isYearVisible)}>
          {isYearVisible ? 'Hide year consuming' : 'Show year consuming'}
        </Button>
        <Button size="medium" onClick={() => setMonthVisible(!isMonthVisible)}>
          {isMonthVisible ? 'Show day consuming' : 'Show month consuming'}
        </Button>
      </div>

      {isYearVisible && (
        <div>
          <h2>Year consuming</h2>
          {isLoadingYear && (
            <div className={styles.center}>
              <PulseLoader color="#ffb700" size={10} />
            </div>
          )}

          {!isLoading && yearData && renderYearTable(yearData)}
        </div>
      )}

      {!isYearVisible && !isMonthVisible && (
        <div>
          <div className={styles.picker}>
            <span>Выберите дату</span>
            <div className={styles.pickerPlace}>
              <Input
                type="text"
                className={styles.input}
                value={moment(date, 'YYYYMMDD').format('DD/MM/YYYY')}
                onFocus={() => setShowDatePicker(true)}
              />
              <DatePicker
                className={styles.pickerWindow}
                isVisible={showDatePicker}
                onClickOutside={() => setShowDatePicker(false)}
                showWeekNumbers
                onSelect={({ day, month, year }) => {
                  const m = month < 10 ? `0${month}` : month;
                  const value = `${day}/${m}/${year}`;
                  const dt = moment(value, 'DD/MM/YYYY').format('YYYYMMDD');
                  setDate(dt);
                  setShowDatePicker(false);
                }}
              />
            </div>
          </div>

          <h3>
            Итого за выбранную дату - <span>{totalMoney}</span> грн
          </h3>

          <center>
            <div>Day KWT - {todayDayKwt}</div>
            <div>Night KWT - {todayNightKwt}</div>
          </center>

          {isLoading && (
            <div className={styles.center}>
              <PulseLoader color="#ffb700" size={10} />
            </div>
          )}

          {!isLoading && data && (
            <div>
              <h2>График потребления электроэнергии:</h2>
              <div className={styles.buttons}>
                <Button onClick={() => setShowKwt(!isShowKwt)}>
                  {isShowKwt ? 'Hide kwt' : 'Show kwt'}
                </Button>
                <Button onClick={() => setShowPrice(!isShowPrice)}>
                  {isShowPrice ? 'Hide price' : 'Show price'}
                </Button>
              </div>
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="area"
                height={600}
              />
            </div>
          )}
        </div>
      )}

      {!isYearVisible && isMonthVisible && (
        <div>
          {isLoadingMonth && (
            <div className={styles.center}>
              <PulseLoader color="#ffb700" size={10} />
            </div>
          )}
          {!isLoadingMonth && (
            <div>
              <div className={styles.center}>
                <Button
                  onClick={() =>
                    setCurrentMonth(
                      moment().subtract(1, 'month').format('YYYY-MM')
                    )
                  }
                >
                  Previous month
                </Button>
                <Button
                  onClick={() => setCurrentMonth(moment().format('YYYY-MMÎ'))}
                >
                  Current
                </Button>
              </div>
              <h2>Month consuming</h2>
              <ReactApexChart
                options={monthChart.options}
                series={monthChart.series}
                type="area"
                height={600}
              />
            </div>
          )}
        </div>
      )}
    </section>
  );
};

const dynamicExport = (): any => {
  const isServer = typeof window === 'undefined';

  if (!isServer) {
    return EnergyScreen();
  }

  return null;
};

export default dynamicExport;
