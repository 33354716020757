// @flow
import React from 'react';
import _ from 'lodash';
import styles from './section.module.scss';
import css from '../../app.module.scss';

type Props = {
  children?: any,
  className?: string,
  marginTop?: boolean,
  fullwidth?: boolean
};

const renderSection = (props: Props): any => {
  const { children, className = '', marginTop = false, fullwidth = false } = props;
  const isMarginTop = marginTop ? styles.marginTop : '';
  const style = `${styles.section} ${className} ${isMarginTop}`.trim();

  if (fullwidth) {
    return <div className={`${css.mainBodyFull} ${style}`}>{children}</div>;
  }

  return (
    <div className={style}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default renderSection;
