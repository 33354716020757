// @flow

import React, { useState, useEffect } from 'react';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { useGeolocated } from 'react-geolocated';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HashLoader } from 'react-spinners';

import styles from './chargers.module.scss';
import Section from '../../components/Section';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';

import MarkerMap from '../../assets/svg/marker-map.svg';
import GoRoute from '../../assets/svg/go-route.svg';

import metaValues from '../../../config';
import { getChargers, getEFChargers } from '../../../lib/api';

// const isServer = typeof window === 'undefined';

const geoConfig = {
  positionOptions: {
    enableHighAccuracy: false
  },
  userDecisionTimeout: 5000
};

const renderPort = (port: any) => {
  const { name, maxPower, price } = port;

  return (
    <li className={styles.prices} key={name}>
      <div>{name}</div>
      <div>{maxPower}kWh</div>
      <div>{price}</div>
    </li>
  );
};

const renderCharger = (chargerInfo: any) => {
  const { name, address, distance, ports, lat, lng } = chargerInfo;
  // const link = `https://www.google.com/maps/@${lat},${lng},14z`;

  // https://www.google.com/maps/dir/50.496469430874036,30.36147058478468/50.44235458284302,30.287675158341045/@50.496469430874036,30.36147058478468,18z

  const link = `https://www.google.com/maps/dir//49.560748,34.5263205/@${lat},${lng},18.31z`;

  return (
    <li key={name}>
      <div className={styles.distance}>
        <a href={link} target="_blank" rel="noreferrer">
          {distance}km
          <span>
            <img src={GoRoute} alt="go to route" />
          </span>
        </a>
      </div>
      <h4>{name}</h4>
      <div>Address: {address}</div>
      <ol>{ports.map(renderPort)}</ol>
    </li>
  );
};

const renderChargerList = ({ chargers = [], isPromo }: any) => {
  if (!chargers.length) {
    return null;
  }

  const style = isPromo ? styles.promoList : styles.chargerList;
  return (
    <ul className={style}>
      {isPromo && <h3>New stations</h3>}
      {chargers.map(renderCharger)}
    </ul>
  );
};

const renderEFStation = (item: any, coords: any) => {
  const { name, connectors, address, online, distance, longitude, latitude } =
    item;
  // const link = `https://www.google.com/maps/@${item.latitude},${item.longitude},18.31z`;
  const link = `https://www.google.com/maps/dir/${coords.latitude},${coords.longitude}/${latitude},${longitude}/@${item.latitude},${item.longitude},18z`;

  return (
    <div className={online ? styles.efConnector : styles.efConnectorOffline}>
      <div className={styles.distance}>
        <a href={link} target="_blank" rel="noreferrer">
          Build route - {distance}km
          <span>
            <img src={GoRoute} alt="go to route" />
          </span>
        </a>
      </div>
      <h4>
        <span className={online ? styles.online : styles.offline} />
        {name}
      </h4>
      <div className={styles.content}>
        <div>{address}</div>

        {connectors &&
          connectors.map(({ type, available }) => (
            <span className={available ? styles.free : styles.busy}>
              {type}
            </span>
          ))}
      </div>
    </div>
  );
};

const FriendScreen = (): any => {
  const common = useSelector(({ common: comm }: any) => comm);
  const { API_URL } = common;

  const geoData = useGeolocated(geoConfig);
  const {
    coords
    // isGeolocationAvailable,
    // isGeolocationEnabled
  } = geoData;

  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;
  const title = 'Search for EV Chargers';

  const connectors = {
    ccs2: true,
    chademo: false,
    type2: false,
    type1: false
  };

  const [conn, setConn] = useState(connectors);
  const [refreshChargeNow, setRefresh] = useState(new Date());
  const [changerList, setChangerList] = useState();
  const [efList, setEFList] = useState(null);
  const [efLoading, setEFLoading] = useState(false);
  const [promoList, setPromo] = useState();

  const setConnector = (connector: any, value: any): any => {
    const out = {
      ...conn
    };

    out[connector] = value;
    setConn(out);
  };

  const { latitude, longitude } = coords || {};

  const types = Object.keys(conn)
    .filter(key => conn[key])
    .map(key => `&types[]=${key}`)
    .join('');

  const loadEF = async () => {
    setEFList(null);
    setEFLoading(true);

    const params = {
      lat: latitude,
      lng: longitude,
      API_URL
    };

    const result = await getEFChargers(params);
    setEFList(result);
    setEFLoading(false);
  };

  const handleReload = async () => {
    const params = {
      lat: latitude,
      lng: longitude,
      types,
      API_URL
    };

    const { free, promo } = await getChargers(params);
    setChangerList(free);
    setPromo(promo);
  };

  useEffect(() => {
    const fetchData = async () => {
      // You can await here
      await handleReload();
    };

    if (latitude) {
      fetchData();
    }
  }, [refreshChargeNow]);

  return (
    <section className={styles.section}>
      <Helmet>
        <title>
          {title} - {defaultTitle}
        </title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={title} />
      </Helmet>
      <Section className={styles.section}>
        <img className={styles.image} src={MarkerMap} alt="Charge map" />
        <Link to="/chargers/how-to-add">How to add charger</Link>
        <h1>FREE Chargers around you</h1>
        <ul>
          <li>Lat: {latitude}</li>
          <li>Long: {longitude}</li>
        </ul>

        <div className={styles.params}>
          <div>
            <p>Choose charge type:</p>
            <Checkbox
              id="ccs2"
              checked={conn.ccs2}
              onChange={() => setConnector('ccs2', !conn.ccs2)}
            >
              CCS2
            </Checkbox>
            <Checkbox
              id="chademo"
              checked={conn.chademo}
              onChange={() => setConnector('chademo', !conn.chademo)}
            >
              Chademo
            </Checkbox>
            <Checkbox
              id="type2"
              checked={conn.type2}
              onChange={() => setConnector('type2', !conn.type2)}
            >
              Type2
            </Checkbox>
            <Checkbox
              id="type1"
              checked={conn.type1}
              onChange={() => setConnector('type1', !conn.type1)}
            >
              Type1
            </Checkbox>
          </div>

          <div>
            <p>Choose network:</p>
            <Checkbox id="yasno" checked onChange={() => {}}>
              YASNO
            </Checkbox>
            <Checkbox id="ugv" checked onChange={() => {}}>
              UGV
            </Checkbox>
            <Checkbox id="gotou" checked onChange={() => {}}>
              GO TO-U
            </Checkbox>
            <Checkbox id="ef" checked={false} disabled onChange={() => {}}>
              EcoFactor
            </Checkbox>
            <Checkbox id="ae" checked={false} disabled onChange={() => {}}>
              AE
            </Checkbox>
          </div>
        </div>

        <Button onClick={() => setRefresh(new Date())}>Find</Button>

        <Button
          onClick={() => {
            loadEF();
          }}
        >
          Check EF
        </Button>

        {efLoading && <HashLoader />}
        {efList && (
          <div className={styles.ecofactor}>
            {efList.map((item: any) => renderEFStation(item, coords))}
          </div>
        )}

        {renderChargerList({ chargers: promoList, isPromo: true })}
        {renderChargerList({ chargers: changerList, isPromo: false })}
      </Section>
    </section>
  );
};

// $FlowFixMe
export default FriendScreen;
