import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

import Section from '../../components/Section';
import Input from '../../components/Input';

import styles from './manage.module.scss';

import metaValues from '../../../config';

const renderStation = station => {
  const { slug, title } = station;

  return (
    <li>
      <div className={styles.title}>{title}</div>
      <div>
        <ol>
          <li>
            <Link to={`/manage/station/${slug}`}>
              Manage station information
            </Link>
          </li>
          <li>
            <Link to={`/manage/tarifs/${slug}`}>Manage tariffs</Link>
          </li>
          <li>
            <Link to={`/manage/profit/${slug}`}>Profitability</Link>
          </li>
        </ol>
      </div>
    </li>
  );
};

const ManageScreen = () => {
  const { defaultTitle } = metaValues;
  const { userData } = useSelector(state => state.user);
  const { manageStations = [] } = userData;

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Station management - {defaultTitle}</title>
        <meta name="description" content="Top-up for user account" />
        <meta property="keywords" content="ev-charging, charge points" />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <Section className={styles.content}>
        <Link
          to="/account"
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to account
        </Link>

        <div className={styles.grid}>
          <div>
            <h1>EV Stations</h1>
            <div>
              Search&nbsp;
              <Input
                className={styles.input}
                name="query"
                placeholder="Type station name or id"
              />
            </div>
            <ul className={styles.stationList}>
              {manageStations.map(renderStation)}
            </ul>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default ManageScreen;
