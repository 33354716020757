// @flow

import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';

import validationSchema from './validation-schema';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import styles from './outcome.module.scss';
import { renderInput } from '../../render-methods';
import DatePicker from '../../DatePicker';

import { saveProfitInfo } from '../../../../lib/api';

/**
 * Props
 */
type Props = {
  data: any,
  onSubmit: any,
  onCancel: any,
  values: any
};

const OutcomeForm = (props: Props): any => {
  const { values: initialValues, data, onSubmit, onCancel } = props;
  const { API_URL } = data;

  const [isSubmitting, setSubmitting] = useState(false);
  const [isError, setError] = useState(false);
  const [showPicker1, setPicker1] = useState(false);

  const handleSave = async (values: any) => {
    try {
      await saveProfitInfo({
        API_URL,
        values
      });
    } catch (error) {
      setError(error.message);
    }

    if (onSubmit && typeof onSubmit === 'function') {
      onSubmit(values);
    }
  };

  const renderSubmitButton = (disabled: boolean, formValues: any) => (
    <div>
      <Button
        // disabled={!isChanged || disabled || isSubmitting}
        disabled={disabled || isSubmitting}
        loading={isSubmitting}
        onClick={() => {
          if (onSubmit && typeof onSubmit === 'function') {
            onSubmit(formValues);
          }
        }}
      >
        Save changes
      </Button>
      <Button
        className={styles.cancelBtn}
        color="gray"
        onClick={() => {
          if (onCancel && typeof onCancel === 'function') {
            onCancel();
          }
        }}
      >
        Cancel
      </Button>
    </div>
  );

  const checkZero = (item: any) => (Number(item) < 10 ? `0${item}` : item);

  return (
    <div className={styles.container}>
      <Formik
        validateOnChange
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnBlur
        validateOnMount
        enableReinitialize
        onSubmit={async (values: any, actions: any) => {
          setSubmitting(true);
          await handleSave(values);
          actions.setSubmitting(false);
          setSubmitting(false);
        }}
      >
        {(formProps: any) => {
          const { setFieldValue, values, isValid } = formProps;

          return (
            <Form>
              <div className={styles.grid}>
                <div className={styles.caption}>Title</div>
                <Field name="title">
                  {(properties: any) =>
                    renderInput({
                      ...properties,
                      placeholder: 'Enter outcome title'
                    })
                  }
                </Field>

                <div className={styles.caption}>Type</div>
                <div className={styles.context}>
                  <select
                    name="type"
                    defaultValue={values.type}
                    onChange={({ target: { value } }) => {
                      setFieldValue('type', value);
                    }}
                  >
                    <option value={values.type}>Select type</option>
                    <option value="fixed" selected={values.type === 'fixed'}>
                      fixed
                    </option>
                    <option
                      value="percent"
                      selected={values.type === 'percent'}
                    >
                      percent
                    </option>
                    <option value="single" selected={values.type === 'single'}>
                      single
                    </option>
                    <option value="calc" selected={values.type === 'calc'}>
                      calculate
                    </option>
                  </select>
                </div>

                <div className={styles.caption}>Income kwt</div>
                <div className={styles.context}>
                  <Checkbox
                    id="isIncomeKwt"
                    checked={values.isIncomeKwt}
                    name="isIncomeKwt"
                    className={styles.checkbox}
                    onChange={value => {
                      setFieldValue('isIncomeKwt', value);

                      if (value) {
                        setFieldValue('type', 'single');
                      }
                    }}
                  >
                    &nbsp;check that if you are adding kwt price
                  </Checkbox>
                </div>

                <div className={styles.caption}>Active date</div>
                <div className={styles.tripleDates}>
                  <div>
                    <Field name="activeDate">
                      {(properties: any) => {
                        const {
                          field: { name: fieldName }
                        } = properties;

                        return (
                          <div>
                            {renderInput({
                              ...properties,
                              onFocus: () => setPicker1(true)
                            })}
                            <DatePicker
                              isVisible={showPicker1}
                              onSelect={value => {
                                const { day, month, year } = value;
                                const dy = checkZero(day);
                                const mn = checkZero(month);

                                setFieldValue(fieldName, `${dy}.${mn}.${year}`);
                                setPicker1(false);
                              }}
                              onClickOutside={() => setPicker1(false)}
                            />
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                </div>

                <div className={styles.caption}>Price</div>
                <Field name="price">
                  {(properties: any) =>
                    renderInput({
                      ...properties,
                      placeholder: 'Enter price'
                    })
                  }
                </Field>
              </div>

              {renderSubmitButton(!isValid, values)}
              {isError && <div className={styles.error}>{isError}</div>}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default OutcomeForm;
