import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Section from '../../components/Section';
import AccountCart from '../../components/AccountCart';

import styles from './top-up.module.scss';

import metaValues from '../../../config';

const ChartsScreen = () => {
  const { defaultTitle } = metaValues;
  const { userData } = useSelector(state => state.user);
  const [amount, setAmount] = useState();
  const [showAction, setShowAction] = useState();

  return (
    <div className={styles.container}>
      <Helmet>
        <title>Account Top-up - {defaultTitle}</title>
        <meta name="description" content="Top-up for user account" />
        <meta property="keywords" content="ev-charging, charge points" />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <Section className={styles.content}>
        <Link
          to="/account"
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to account
        </Link>

        <div className={styles.grid}>
          <AccountCart userData={userData} />
          <div>
            <h1>Top-up account</h1>
            <p>You can easy top-up balance for account</p>

            <Input
              name="amount"
              value={amount}
              placeholder="Enter amount"
              onChange={event => {
                setAmount(event.target.value);
              }}
            />
            <Button onClick={() => setShowAction(true)}>Pay</Button>
            {showAction && <p>Your account will be replenished on {amount}.</p>}
          </div>
        </div>
      </Section>
    </div>
  );
};

export default ChartsScreen;
