// @flow

import React from 'react';
import moment from 'moment';

import styles from './account-stat.module.scss';

const AccountStat = (props: any): any => {
  const {
    userData: { ChargeMonthly }
  } = props;

  const { totalAmount = 0, totalKwt = 0, totalCount = 0 } = ChargeMonthly || {};
  const kwt = Number(totalKwt / 1000).toFixed(2);
  const amount = Number(totalAmount).toFixed(2);
  const date = moment().format('MMMM YYYY');

  return (
    <div className={styles.context}>
      <div className={styles.statistics}>
        <div className={styles.amount}>
          ₴<span>{amount}</span>
          <p>spend money</p>
        </div>
        <div className={styles.kwt}>
          <span>{kwt}</span>
          <p>charged kWh</p>
        </div>
        <div className={styles.count}>
          <span>{totalCount}</span>
          <p>sessions</p>
        </div>
      </div>
      <span className={styles.date}>Your metrics for {date}</span>
    </div>
  );
};

export default AccountStat;
