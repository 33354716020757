import React from 'react';

import { ErrorMessage, getIn } from 'formik';
import MaskedInput from 'react-maskedinput';
import _ from 'lodash';

import Input from './Input';
import { ErrorWrapper } from './Error';

import styles from './render-methods.module.scss';

export type InputProps = {
  placeholder: string,
  disabled?: boolean,
  type: string,
  width: string,
  field: {
    name: string,
    value: string
  },
  form: {
    errors: {},
    touched: {}
  }
};

export const renderInputErrors = (inputName: string) => (
  <ErrorWrapper>
    <ErrorMessage name={inputName}>
      {msg => <span className={styles.errorMessage}>{msg}</span>}
    </ErrorMessage>
  </ErrorWrapper>
);

export const renderInput = (inputProps: InputProps) => {
  const {
    placeholder,
    placeholderChar = '',
    type,
    mask,
    // wide,
    className,
    disabled,
    onFocus,
    field,
    field: { name, value },
    form: { errors }
  } = inputProps;

  const hasError = getIn(errors, name);
  const maskError = _.get(errors, name) && mask;
  const classNameInput = maskError ? `${String(className)}Error` : className;

  // setTouched({ [name]: true });

  if (mask) {
    return (
      <div>
        <MaskedInput
          className={classNameInput}
          mask={mask}
          onFocus={onFocus}
          placeholder={placeholder}
          placeholderChar={placeholderChar}
          type={type}
          name={name}
          value={value}
          {...field}
        />
        {renderInputErrors(name)}
      </div>
    );
  }

  return (
    <div>
      <Input
        mask={mask}
        placeholder={placeholder}
        type={type}
        hasError={hasError}
        onFocus={onFocus}
        disabled={disabled}
        name={name}
        value={value}
        {...field}
      />
      {renderInputErrors(name)}
    </div>
  );
};
