// @flow

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import {
  Link,
  useParams,
  useSearchParams,
  useNavigate
} from 'react-router-dom';

import { useSelector } from 'react-redux';
import { HashLoader } from 'react-spinners';

import * as Scroll from 'react-scroll';
import moment from 'moment';

import Section from '../../../components/Section';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import metaValues from '../../../../config';
import DatePicker from '../../../components/DatePicker';

import styles from './profit.module.scss';

import { getStationProfit } from '../../../../lib/api';

const renderAmount = (amount: any) => {
  const num = Number(amount)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1 ');

  if (Number(amount) > 0) {
    return <span className={styles.amountPlus}>+{num}</span>;
  }

  return <span className={styles.amount}>{num}</span>;
};

const renderOneMonth = (section: any) => {
  const { date, items, total } = section;

  const renderMonth = () =>
    items.map(item => {
      const {
        payments,
        station,
        total: totalAll,
        effectiveKPI,
        outcome,
        income
      } = item;

      const renderItems = payments.map(one => {
        const { title, amount, total: oneTotal } = one;
        return (
          <ol>
            <li>{title}</li>
            <li>{oneTotal}</li>
            <li>{renderAmount(amount)}</li>
          </ol>
        );
      });

      return (
        <ul>
          <li>
            <div className={styles.title}>{station}</div>
            <div className={styles.kpi}>
              <div className={styles.value}>{effectiveKPI}%</div>
              <div className={styles.outcome}>(-)&nbsp;{outcome}</div>
              <div className={styles.income}>(+)&nbsp;{income}</div>
            </div>
          </li>
          <li>
            {renderItems}
            <ol className={styles.summary}>
              <li />
              <li />
              <li>{totalAll.toFixed(2)}</li>
            </ol>
          </li>
        </ul>
      );
    });

  return (
    <div>
      <h2>{date}</h2>
      {renderMonth()}
      {items && items.length > 1 && (
        <ul>
          <li />
          <li>
            <ol>
              <li />
              <li />
              <li className={styles.sectionTotal}>{total.toFixed(2)}</li>
            </ol>
          </li>
        </ul>
      )}
    </div>
  );
};

const RenderList = (props: any) => {
  const { data } = props;
  const { payments, total } = data;

  return (
    <div className={styles.list}>
      <ul className={styles.header}>
        <li>Station name</li>
        <li>
          <ol>
            <li>Metric name</li>
            <li>Value</li>
            <li>Amount</li>
          </ol>
        </li>
      </ul>

      {payments.map(renderOneMonth)}

      <ul className={styles.total}>
        <li className={styles.title}>TOTAL</li>
        <li>
          <ol>
            <li />
            <li />
            <li className={styles.total}>{total}</li>
          </ol>
        </li>
      </ul>
    </div>
  );
};

const ProfitStationScreen = (): any => {
  const { stationId } = useParams();
  const { API_URL } = useSelector(({ common: comm }: any) => comm);
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const queryDate1 = searchParams.get('startDate');
  const queryDate2 = searchParams.get('endDate');

  const momentDate1 = queryDate1 ? moment(queryDate1, 'YYYYMMDD') : moment();
  const momentDate2 = queryDate2 ? moment(queryDate2, 'YYYYMMDD') : moment();

  const dFormat = 'YYYYMMDD';
  const startDate = momentDate1.startOf('month').format(dFormat);
  const endDate = momentDate2.endOf('month').format(dFormat);
  const [date1, setDate1] = useState(moment(startDate).format(dFormat));
  const [date2, setDate2] = useState(moment(endDate).format(dFormat));
  const [showDatePicker1, setShowDatePicker1] = useState(false);
  const [showDatePicker2, setShowDatePicker2] = useState(false);

  const setPeriod = (type: any) => {
    if (type.includes('prev-')) {
      const clearType = type.replace('prev-', '');
      setDate1(
        moment().startOf(clearType).subtract(1, clearType).format(dFormat)
      );
      setDate2(
        moment().endOf(clearType).subtract(1, clearType).format(dFormat)
      );
    } else {
      setDate1(moment().startOf(type).format(dFormat));
      setDate2(moment().endOf(type).format(dFormat));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      navigate({
        path: `/manage/profit/${String(stationId)}`,
        search: `?startDate=${date1}&endDate=${date2}`
      });

      setLoading(true);

      const profitData = await getStationProfit({
        API_URL,
        slug: [stationId],
        start: moment(date1, dFormat).format('YYYY/MM/DD'),
        finish: moment(date2, dFormat).format('YYYY/MM/DD')
      });

      setData(profitData);
      setLoading(false);
    };

    if (stationId) {
      fetchData();
    }
  }, [stationId, date1, date2]);

  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;

  const renderControls = () => (
    <div className={styles.search}>
      <div className={styles.dates}>
        <Link to={`/manage/profit/outcome/${String(stationId)}`}>
          <Button size="medium" className={styles.addButton}>
            Edit outcomes
          </Button>
        </Link>
        Current&nbsp;
        <Button size="medium" onClick={() => setPeriod('week')}>
          Week
        </Button>
        <Button size="medium" onClick={() => setPeriod('prev-month')}>
          Prev Month
        </Button>
        <Button size="medium" onClick={() => setPeriod('month')}>
          Month
        </Button>
        <Button size="medium" onClick={() => setPeriod('prev-quarter')}>
          Prev Quarter
        </Button>
        <Button size="medium" onClick={() => setPeriod('quarter')}>
          Quarter
        </Button>
        <Button size="medium" onClick={() => setPeriod('year')}>
          Year
        </Button>
      </div>

      <span>
        <b>Report period:</b>
      </span>

      <Input
        className={styles.datePicker}
        name="date1"
        value={moment(date1, 'YYYYMMDD').format('DD/MM/YYYY')}
        onFocus={() => setShowDatePicker1(true)}
      />
      <DatePicker
        className={styles.pickerWindow}
        isVisible={showDatePicker1}
        date={date1}
        onClickOutside={() => setShowDatePicker1(false)}
        showWeekNumbers
        onSelect={({ day, month, year }) => {
          const m = month < 10 ? `0${month}` : month;
          const value = `${day}/${m}/${year}`;
          const dt = moment(value, 'DD/MM/YYYY').format('YYYYMMDD');
          setDate1(dt);
          setShowDatePicker1(false);
        }}
      />

      <Input
        name="date2"
        className={styles.datePicker}
        value={moment(date2, 'YYYYMMDD').format('DD/MM/YYYY')}
        onFocus={() => setShowDatePicker2(true)}
      />
      <DatePicker
        className={styles.pickerWindow}
        isVisible={showDatePicker2}
        date={date2}
        onClickOutside={() => setShowDatePicker2(false)}
        showWeekNumbers
        onSelect={({ day, month, year }) => {
          const m = month < 10 ? `0${month}` : month;
          const value = `${day}/${m}/${year}`;
          const dt = moment(value, 'DD/MM/YYYY').format('YYYYMMDD');
          setDate2(dt);
          setShowDatePicker2(false);
        }}
      />
    </div>
  );

  if (isLoading || !data) {
    return (
      <Section>
        <div className={styles.content}>
          <Link
            to="/manage"
            onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
          >
            Back to stations
          </Link>
          <h1>Profitability</h1>
          {renderControls()}
          <center>
            <HashLoader />
          </center>
        </div>
      </Section>
    );
  }

  const { stationInfo } = data;
  const { slug } = stationInfo[stationId];

  return (
    <Section>
      <Helmet>
        <title>
          Profitability ({stationId}) - {defaultTitle}
        </title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={defaultTitle} />
      </Helmet>

      <div className={styles.content}>
        <Link
          to="/manage"
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to stations
        </Link>

        <h3>{slug}</h3>
        <h1>Profitability</h1>
        {renderControls()}
        <RenderList data={data} />
      </div>
    </Section>
  );
};

export default ProfitStationScreen;
