// @flow
import _ from 'lodash';
import * as React from 'react';
import styles from './select.module.scss';

type Props = {
  tip?: string,
  name?: string,
  value?: string,
  options: [string],
  className?: string,
  placeholder?: string,
  hasError?: boolean,
  onChange?: any
};

const Select = (props: Props) => {
  const {
    hasError = false,
    onChange = () => {},
    tip = '',
    name = '',
    value = '',
    // $FlowFixMe
    options = [],
    className = ''
  } = props;

  const styleName = `${styles.inputGroup} ${className}`.trim();

  return (
    <div className={styleName}>
      <select
        {..._.omit(props, ['onChange', 'hasError', 'options'])}
        className={hasError ? styles.hasError : ''}
        id={name}
        value={value}
        name={name}
        placeholder="Choose option"
        onChange={response => {
          const {
            target: { value: result }
          } = response;

          if (onChange && typeof onChange === 'function') {
            onChange(result);
          }
        }}
      >
        <option value="">Choose port type</option>
        {options &&
          options.length &&
          options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
      </select>
      {tip && <div className={styles.inputTip}>{tip}</div>}
    </div>
  );
};

export default (Select: React.AbstractComponent<Props, mixed>);
