// @flow

import React from 'react';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';

import styles from './friends.module.scss';
import Section from '../../components/Section';

import Mobile from '../../assets/svg/mobile-route.svg';

import metaValues from '../../../config';

// const scroll = Scroll.animateScroll;

const FriendScreen = (): any => {
  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;

  return (
    <section className={styles.section}>
      <Helmet>
        <title>EV-Friendly places - {defaultTitle}</title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={defaultTitle} />
      </Helmet>
      <Section className={styles.section}>
        <div className={styles.image}>
          <img src={Mobile} alt="iphone" />
        </div>
        <h1>EV-Friendly places</h1>
        <p>
          The increasing sales figures for electric cars also show that the
          transportation being used to reach those destinations is more and more
          likely to be an altogether more eco-friendly option. If you already
          own or are thinking of purchasing an electric vehicle (EV) ahead of
          your next road trip, you may also find yourself thinking about which
          drivable destination could be best to visit.
        </p>
        <div className={styles.bg}>
          <div className={styles.review}>
            Over the past 10 years, we’ve attracted a large user base for the
            app. Over one million drivers already trust Plugsurfing to help them
            charge on their business and leisure travels across Europe.
          </div>
        </div>

        <p>
          While there are countless benefits to holidaying in an EV, there are
          additional issues to consider, such as how likely you are to find
          charging spots – and that’s before you’ve considered which country
          actually has the most to offer in terms of sights to see and scenery
          to soak up.
        </p>
        <h2>
          The best countries in Europe for an EV road trip (and what to see when
          you’re there)
        </h2>
        <p>
          To find out for certain, we’ve done our research and crunched the
          numbers, to find out for sure which country in Europe is best for an
          EV road trip.
        </p>
      </Section>
    </section>
  );
};

export default FriendScreen;
