// @flow

import React, { useState } from 'react';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HashLoader } from 'react-spinners';

import styles from './account.module.scss';
import Section from '../../components/Section';
import Button from '../../components/Button';
import CreditCard from '../../components/CreditCard';
// import VerificationCode from '../../components/VerificationCode';
import AccountCart from '../../components/AccountCart';

import metaValues from '../../../config';

import visa from '../../assets/svg/card-visa.svg';
import mastercard from '../../assets/svg/card-mastercard.svg';

const renderCard = (cardInfo: any) => {
  const { id, cardType, expireDate, numberHidden } = cardInfo;
  const cardImages = { visa, mastercard };
  const type = cardType.replace('card-', '');
  const src = cardImages[type];
  const alt = `${type} card`;
  return (
    <li key={id}>
      <img className={styles.cardIcons} src={src} alt={alt} />
      <div>
        <div className={styles.cardNumber}>{numberHidden}</div>
        <div>{expireDate}</div>
      </div>
      <Button size="small">Delete</Button>
    </li>
  );
};

const AccountCardScreen = (): any => {
  const [isShowAdd, setShowAdd] = useState(false);
  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;
  const { userData } = useSelector(({ user }: any) => user);

  const { UserCards } = userData || {};

  if (!UserCards) {
    return (
      <Section className={styles.container}>
        <center>
          <HashLoader />
        </center>
      </Section>
    );
  }

  return (
    <Section className={styles.container}>
      <Helmet>
        <title>Account cards management - {defaultTitle}</title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={defaultTitle} />
      </Helmet>

      <div>
        <Link to="/account">Back to account</Link>

        <div className={styles.content}>
          <AccountCart userData={userData} />
          <div>
            <h1>Account cards</h1>
            <ul className={styles.cardList}>{UserCards.map(renderCard)}</ul>
            <Button onClick={() => setShowAdd(!isShowAdd)}>
              {!isShowAdd ? 'Add new card' : 'Cancel add card'}
            </Button>
            {isShowAdd && <CreditCard values={{}} onSubmit={console.log} />}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AccountCardScreen;
