'use strict';

/* global process, require, exports */

Object.defineProperty(exports, '__esModule', {
  value: true
});

exports.LoadableContext = void 0;

const _interopRequireDefault = (obj) => {
  return obj && obj.__esModule
    ? obj
    : {
        default: obj
      };
}

const _react = _interopRequireDefault(require('react'));

const LoadableContext = _react.default.createContext(null);

exports.LoadableContext = LoadableContext;

if (process.env.NODE_ENV !== 'production') {
  LoadableContext.displayName = 'LoadableContext';
}
