import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';

// import Button from '../../components/Button';
import Section from '../../components/Section';
import Charging from '../../components/Charging';
import styles from './charging.module.scss';

import metaValues from '../../../config';

const ChartsScreen = () => {
  const { defaultTitle } = metaValues;

  return (
    <div>
      <Helmet>
        <title>React charts dashboards - {defaultTitle}</title>
        <meta
          name="description"
          content="There you can find our portfolio about charts implementations"
        />
        <meta
          property="keywords"
          content="react emobility solutions, react ocpp demo, react ocpp management system, ocpp dashboard, ocpp billing system, ocpp charging management"
        />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <Section className={styles.content}>
        <Link
          to="/"
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to home
        </Link>
        <h1>Charge station control</h1>
        <Charging />
      </Section>
    </div>
  );
};

export default ChartsScreen;
