const translate = {
  _COPY_LINKS: 'Copy route to clipboard',
  _HOME_HEAD_TITLE: 'Discover the world emission-free',
  _HOME_HEAD_BUTTON1: 'Look to completed trips',
  _HOME_HEAD_BUTTON2: 'Start planning your trip',

  _HOME_HEAD_SECOND_TITLE: 'Explore charging around',
  _HOME_HEAD_SECOND_DESC: `<p>Use one account for all changing networks around you!</p><p>You can easy find, plug, pay and charge your car with our amazing app - all you need to do it's</p>`,

  _HOME_HEAD_THIRD_TITLE: `Save your <span>TIME</span>`,
  _HOME_HEAD_FOURTH_TITLE: `and <span>MONEY</span>`,

  _HOME_WOW:
    `<p>We are helping EV cars to find a nearest charging station around whole Ukraine + Europe and use that that one account in all charging networks.</p>` +
    `<p>You can view last charge sessions to identify when last time that station was used by other cars - it will help you to avoid visiting non-working stations on the road.</p>`,

  _HOME_STATIONS: `Stations`,
  _HOME_OUTSIDE_UKRAINE: `Charging outside Ukraine`,
  _HOME_FIND_NEAR: `Find charger near you`,

  _MAP_ROUTE_POWER_TYPE: 'Connector type:',
  _MAP_ROUTE_ADD_ROUTE_POINT: `Add route point`,
  _MAP_ROUTE_ADD_ROUTE_TEXT: `<p>New point will be added to the top of route and later you can arrage chain of points</p>`,
  _MAP_ROUTE_POWER_LIMIT: `Power limit:`,
  _MAP_ROUTE_DISTANCE_LIMIT: `Distance limit:`,
  _MAP_ROUTE_DISTANCE_LIMIT_TEXT: `Maximum distance to station from route (km)`,
  _MAP_ROUTE_DISTANCE_CHARGE_LIMIT: `Distance charge to charge:`,
  _MAP_ROUTE_DISTANCE_CHARGE_LIMIT_TEXT: `Average distance between charging stations (km)`,
  _MAP_ROUTE_OPTIONS: 'Options:',
  _MAP_ROUTE_OPTION_1: 'Prefer pay free stations',
  _MAP_ROUTE_OPTION_2: 'Avoid tolls',
  _MAP_ROUTE_OPTION_3: 'Avoid highways',

  _CLEAR_ROUTE: 'Clear route',
  _NO_ROUTES: 'You don`t have saved routes',
  _SAVE_ROUTE: 'Save route',
  _SAVE_ROUTE_TEXT:
    'Saved route can be loaded and changed later. You can save route with all stops and information about chargings.',
  _PAGE_HEAD_TITLE: 'Route map builder',
  _MAP_ROUTE_LOGGED: `<h1>Attention!</h1><p>You need login to use this section</p>`,
  _LOAD: 'Load',
  _SAVE: 'Save',
  _LOAD_TITLE: 'Load route',
  _LOAD_DESC:
    '<p>You can easy load any saved route here. Just select route and click</p>',

  _POINTS: 'Points:',
  _POINTS_TEXT: 'You can drag and change the order of points',
  _PREMIUM_ACCOUNT: 'Premium account',
  _PREMIUM_ACCOUNT_SEO_TITLE: 'Premium Account benefits and costs',
  _PREMIUM_ACCOUNT_TEXT: `Benefits for Premium users`,
  _PREMIUM_FEATURES: `Features`,
  _PREMIUM_REGULAR_USERS: `Regular users`,
  _PREMIUM_PREMIUM_USERS: `Premium users`,

  // Header

  _LOGOUT: 'Logout',
  _ACCOUNT: 'Account',
  _REGULAR_ACCOUNT: 'Regular account',
  _MY_TRIPS: 'My trips',
  _TRIP_PLANNER: 'Trip planner',
  _MY_STATIONS: 'My stations',
  _MY_ENERGY: 'My energy',
  _MY_TARIFFS: 'My tariffs',
  _CHARGING: 'Charging',
  _EV_FRIENDS: 'EV friends',
  _EV_HOTELS: 'EV Hotels',
  _STATIONS_MAP: 'Stations map',

  // Stories
  _STORIES: 'My trips:',
  _STORIES_TEXT:
    'In this section you can share with other users your EV travel experience in other countries or cities with points where you can stay or charge for free',
  _ADD_STORY: 'Add trip',
  _EDIT_STORY: 'Edit',
  _STORIES_HEAD_TITLE: 'My trips',
  _STORY_EDIT: 'Edit',
  _PUBLISHED_STATUS: 'Published',
  _UNPUBLISHED_STATUS: 'Unpublished',
  _CHARGING_POINTS: 'Charging points',

  _EDIT: 'Edit',
  _ERROR: 'Error',
  _EDIT_TRIP: 'Edit trip',
  _ADD_TRIP: 'Add trip',
  _CANCEL: 'Cancel',
  _ADD_POINT: 'Add point',
  _CHARGE_POINT: 'Charge point',
  _BACK_TO_TRIPS: 'Back to trips',
  _ALLOW_USERS_TO_SEE: 'Allow other users to see that trip',
  _UPLOAD_BUTTON: 'Select a files',
  _ADD_DESCRIPTION: 'Add description',
  _UPLOAD_TEXT: 'Supported files for upload - JPG, PNG, JPEG',

  _LOCATION_POSITION: 'Located position:',
  _ERROR_GEO:
    'For some reason, we can`t track your geolocation, but can enter it manually',
  _IS_CHARGING_FREE: 'Charging was free for me',
  _ADD_CHARGE_POINT: 'Add charge point',
  _MAX_CHARGING_SPEED: 'Max charging speed:',

  _DELETE: 'Delete',
  _FROM: 'From',
  _TO: 'To',
  _DURATION: 'Duration',
  _TOTAL_DISTANCE: 'Total distance',
  _TOTAL_DURATION: 'Total duration',

  _SHOW_MAP: 'Show map',
  _HIDE_MAP: 'Hide map',
  _SHOW: 'Show',
  _EV_TRIPS: 'EV Trips'
};

export default translate;
