// @flow
import * as React from 'react';
import { type Node } from 'react';
import styles from './checkbox.module.scss';

type Props = {
  id: number | string,
  className?: string,
  checked?: boolean,
  disabled?: boolean,
  children?: Node,
  onChange?: (value: boolean) => void
};

const Checkbox = (props: Props) => {
  const {
    checked = false,
    children = '',
    id,
    onChange = () => {},
    disabled = false,
    className = ''
  } = props;
  const style = checked ? styles.checked : styles.unchecked;
  const finalStyle = disabled
    ? `${style} ${styles.disabled} ${className}`.trim()
    : `${style} ${className}`.trim();

  return (
    <label className={finalStyle} htmlFor={id}>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={() => {
          if (onChange) {
            onChange(Boolean(!checked));
          }
        }}
        id={id}
      />
      {children}
    </label>
  );
};

export default (Checkbox: React.AbstractComponent<Props, mixed>);
