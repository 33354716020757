/* global google */
import React, { useState, useEffect } from 'react';
// import _ from 'lodash';
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer
} from 'react-google-maps';

const Map = (props: any): any => {
  const {
    height = '500px',
    width = '100%',
    points,
    reverse,
    routeDetails,
    routeCallback
  } = props;

  const routePoints = reverse ? points.reverse() : points;
  const [directions, setDirections] = useState(routeDetails);
  const directionsService = new google.maps.DirectionsService();

  const originPoint = routePoints[0];
  const destinationPoint = routePoints[routePoints.length - 1];

  const waypoints = routePoints.slice(1, -1).map(point => ({
    location: {
      lat: point.lat,
      lng: point.lng
    }
  }));

  const origin = {
    location: originPoint
  };

  const destination = {
    location: destinationPoint
  };

  // console.log({
  //   origin,
  //   destination,
  //   waypoints
  // });

  const buildRoute = () => {
    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        avoidTolls: true,
        avoidHighways: false,
        optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK && result) {
          setDirections(result);
          if (routeCallback) {
            routeCallback(result);
          }
        } else {
          console.log('error fetching directions');
          console.log(result);
        }
      }
    );
  };

  useEffect(() => {
    if (!directions) {
      buildRoute();
    }
  }, [directions, routeDetails]);

  const GoogleMapExample = withGoogleMap(() => (
    <GoogleMap defaultZoom={11}>
      <DirectionsRenderer directions={directions} />
    </GoogleMap>
  ));

  return (
    <div>
      <GoogleMapExample
        containerElement={<div style={{ height, width }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default Map;
