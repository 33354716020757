import React from 'react';
import _ from 'lodash';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';

import AppRoutes from './screens';
import { getUserData } from './store/actions/auth';
import Header from './components/Header';
import Footer from './components/Footer';
import styles from './app.module.scss';

const App = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isServer = typeof window === 'undefined';

  const common = useSelector(state => state.common);
  const user = useSelector(state => state.user);

  const { GA_ID, API_URL, theme } = common;

  if (!isServer) {
    ReactGA.initialize(GA_ID);
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }

  const token =
    !isServer && localStorage ? localStorage.getItem('userJWT') : null;

  if (token) {
    const {
      isPending: isPendingUser,
      isFulfilled,
      isRejected: isRejectedUser,
      userData
    } = user;

    if (!userData && !isPendingUser && !isRejectedUser && !isFulfilled) {
      dispatch(getUserData({ API_URL }));
    }

    // const { isPending: isPendingData, data } = common;
    // if (!data && !isPendingData) {
    //   // dispatch(getFacets());
    // }
  }

  const themeClass = theme === 'dark' ? styles.bodyDark : styles.bodyLight;

  return (
    <div className={themeClass}>
      <Header />
      <div className={styles.main}>
        <Routes>
          {_.map(AppRoutes, (route, index) => {
            const { element: Element } = route;

            // if (route.protected) {
            //   return (
            //     <ProtectedRoute {...route} key={index}>
            //       {component}
            //     </ProtectedRoute>
            //   );
            // }

            return <Route {...route} key={index} element={<Element />} />;
          })}
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
