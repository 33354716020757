import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { HashLoader } from 'react-spinners';
import GoogleMapReact from 'google-map-react';
import { Helmet } from 'react-helmet';

import Section from '../../components/Section';
import Button from '../../components/Button';
import * as ChargeIcons from '../../components/TypeIcons';
import ChargePinImage from './charge-pin.svg';

import { setStationsData } from '../../store/actions/stations';
import { sendTransactionData, getStationInfo } from '../../../lib/api';
import metaValues from '../../../config';

import {
  initiateSocketConnection,
  disconnectSocket,
  joinRoom
} from '../../sockets';

import styles from './station.module.scss';

import {
  STATUS_CHARGING,
  STATUS_PREPARING,
  STATUS_FAULTED,
  STATUS_UNAVAILABLE,
  STATUS_AVAILABLE,
  STATUS_SUSPENDED_EVSE,
  STATUS_SUSPENDED_EV,
  STATUS_FINISHING
} from '../default-store';

const statusToStyles = data => {
  const out = {};

  if (data) {
    Object.keys(data).forEach(key => {
      out[key] = styles[data[key]] || '';
    });
  }

  return out;
};

const handleTransactionStart = async params => {
  console.log(params);
  const data = await sendTransactionData({ ...params, action: 'start' });
  console.log(data);
};

const handleTransactionStop = async params => {
  console.log(params);
  const data = await sendTransactionData({ ...params, action: 'stop' });
  console.log(data);
};

const renderCurrentInfo = params => {
  const {
    status: statusRaw,
    style: styleRaw,
    portType,
    portTarifs,
    title,
    connectorId,
    CurrentOffered,
    CurrentImport,
    PowerActiveImport,
    EnergyActiveImportRegister,
    SoC,
    Voltage,
    transactionId,
    totalMinutesTime,
    idleMin,
    stationId,
    current,
    userData: { id: userId },
    SERVER_URL
  } = params;

  if (current) {
    console.log({ userId, current });
  }

  const status = CurrentImport > 0 ? STATUS_CHARGING : statusRaw;
  let style = CurrentImport > 0 ? styles.Charging : styleRaw;

  if (!transactionId && status === STATUS_CHARGING) {
    style = styles.Preparing;
  }

  const IconImage = ChargeIcons[portType] || ChargeIcons.Type2;
  const idleDuration = moment.duration(idleMin, 'minutes');
  const idleHours = idleDuration.hours();
  const idleMinutes = idleDuration.minutes();
  const idleTime = idleMin
    ? `${String(Number(idleHours))} hr ${idleMinutes} mins`
    : null;

  const totalDuration = moment.duration(totalMinutesTime, 'minutes');
  const totalHours = totalDuration.hours();
  const totalMinutes = totalDuration.minutes();

  const totalTime = totalMinutesTime
    ? `${String(Number(totalHours))} hr ${totalMinutes} mins`
    : null;

  const renderTransactionStatus = () => {
    if (transactionId) {
      return (
        <div>
          {CurrentImport === 0 && <div className={styles.Preparing}>Idle</div>}
          {CurrentImport > 0 && <div className={styles.Charging}>Charging</div>}
        </div>
      );
    }

    return null;
  };

  const renderStartButton = parameters => (
    <Button
      color="green"
      // loading
      // disabled
      onClick={() => {
        handleTransactionStart(parameters);
      }}
    >
      Start
    </Button>
  );

  const renderStopButton = parameters => (
    <Button color="red" onClick={() => handleTransactionStop(parameters)}>
      Stop
    </Button>
  );

  // const { ChargeOCPPTransactions = [] } = params;
  // const chargeHistory = ChargeOCPPTransactions.map(({ createdAt: date }) =>
  //   moment(date).format('DD/MM/YYYY HH:mm:ss')
  // );

  const kwtAmount = Number(EnergyActiveImportRegister / 1000).toFixed(2);
  const kwtSpeed = Number(PowerActiveImport).toFixed(2);

  const [tarif] = portTarifs;
  const { priceKwt, priceHour } = tarif || {};
  const chargeStarted =
    current && current.SessionStart
      ? moment(current.SessionStart).format('HH:mm:ss')
      : null;

  return (
    <div key={connectorId} className={styles.oneConnector}>
      <h4>{title}</h4>
      {tarif && (
        <div className={styles.tarif}>
          <span>
            ₴<span>{priceKwt}</span>/kWh
          </span>
          {parseInt(priceHour, 10) > 0 && (
            <span>
              ₴<span>{priceHour}</span>/hr
            </span>
          )}
        </div>
      )}

      <IconImage className={`${styles.svgIcon} ${style}`.trim()} />

      <div className={styles.status}>
        {/*
          // TODO: external charging session
        */}
        {!transactionId && status === STATUS_CHARGING && (
          <div className={styles.Charging}>{STATUS_CHARGING}</div>
        )}

        {(status === STATUS_AVAILABLE || !status) && (
          <div className={styles.Available}>{STATUS_AVAILABLE}</div>
        )}

        {status === STATUS_FAULTED && (
          <div className={styles.Faulted}>{STATUS_FAULTED}</div>
        )}

        {status === STATUS_UNAVAILABLE && (
          <div className={styles.Unavailable}>{STATUS_UNAVAILABLE}</div>
        )}

        {status === STATUS_FINISHING && (
          <div className={styles.Finishing}>{STATUS_FINISHING}</div>
        )}
      </div>

      {([STATUS_PREPARING, STATUS_SUSPENDED_EVSE, STATUS_SUSPENDED_EV].includes(
        status
      ) ||
        (portType === 'chademo' && [STATUS_AVAILABLE].includes(status))) &&
        !transactionId &&
        renderStartButton({ stationId, connectorId, SERVER_URL })}

      {status === STATUS_CHARGING && (
        <div>
          {/*
          // TODO: uncomment when we will have real data
          // for debug view you need to remove condition
          // to show that block only for charging status
          */}

          {chargeStarted && <div>Started at {chargeStarted}</div>}

          {SoC > 0 && <div className={styles.soc}>{SoC}%</div>}

          {transactionId && (
            <div>
              {renderStopButton({ stationId, connectorId, SERVER_URL })}
              {transactionId && renderTransactionStatus()}
            </div>
          )}

          {CurrentOffered > 0 && (
            <div className={styles.values}>
              {totalTime && (
                <React.Fragment>
                  <div>Total time</div>
                  <div>{totalTime}</div>
                </React.Fragment>
              )}

              {idleTime && (
                <React.Fragment>
                  <div>Idle time</div>
                  <div>{idleTime}</div>
                </React.Fragment>
              )}

              <div>Voltage (V)</div>
              <div>{Voltage}</div>
              <div>Charging (A)</div>
              <div>
                {parseInt(CurrentImport, 10)}/{CurrentOffered}
              </div>
              <div>Power (kWh)</div>
              <div>{kwtSpeed}</div>
              <hr />
              <br />
              <div>Received (kW)</div>
              <div>{kwtAmount}</div>
            </div>
          )}
        </div>
      )}

      {/* chargeHistory && chargeHistory.length > 0 && (
        <div className={styles.chargeHistory}>
          <h5>Last charges:</h5>
          <ul>
            {chargeHistory.map(date => (
              <li>{date}</li>
            ))}
          </ul>
        </div>
      ) */}
    </div>
  );
};

const renderConnectors = params => {
  const { connectors = [], current, stationId, SERVER_URL, userData } = params;

  const styled = statusToStyles(connectors);

  const conn = Object.keys(connectors)
    .map(key => {
      const { StationPorts } = params;
      const portInfo = StationPorts.find(
        x => Number(x.connectorId) === Number(key)
      );

      if (!portInfo) {
        return null;
      }

      const status = connectors[key];

      const out = {
        status,
        current: current[key],
        style: styled[key],
        ...portInfo,
        ...current[key]
      };

      return out;
    })
    .filter(x => !!x);

  // console.log(conn);

  return (
    <ul className={styles.connectors}>
      {conn.map((connector, index) => (
        <li key={`connector-${index}`}>
          {renderCurrentInfo({ ...connector, userData, stationId, SERVER_URL })}
        </li>
      ))}
    </ul>
  );
};

const ChargePin = () => (
  <div className={styles.marker}>
    <img src={ChargePinImage} className={styles.pin} alt="" />
  </div>
);

const StationScreen = () => {
  const { stationId } = useParams();
  const common = useSelector(state => state.common);
  const { userData } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const navigate = useNavigate();

  const { GOOGLE_KEY, API_URL } = common;

  const getStore = useSelector(state => state.stations);
  const [station, setStation] = useState(getStore[stationId]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStationInfo({ API_URL, slug: stationId });
      setStation(data);
    };

    socketRef.current = initiateSocketConnection(API_URL);

    if (!station) {
      fetchData();
    }

    joinRoom({
      name: stationId,
      socket: socketRef.current,
      onMessage: data => {
        // console.log('onMessage', data);

        if (Object.keys(data).length > 0) {
          const { data: message, action } = data;
          const { slug: stationName } = message;

          console.log({ stationName, stationId });

          if (action === 'state' && stationName === stationId) {
            // console.log('action = state update');
            // console.log(message);
            setStation(message);
            dispatch(
              setStationsData({
                [stationId]: message
              })
            );
          }
        }
      }
    });

    return () => {
      console.log('disconnect socket');
      disconnectSocket({ socket: socketRef.current });
    };
  }, [stationId]);

  if (station) {
    const { title, description = '', lat, lng, cameraUrl, photoUrl } = station;

    const defaultGoogleProps = {
      center: {
        lat,
        lng
      },
      zoom: 15
    };

    const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;

    return (
      <section>
        <Helmet>
          <title>
            {title} - {defaultTitle}
          </title>
          <meta name="description" content={defaultDescription} />
          <meta property="keywords" content={defaultKeywords} />
          <meta property="og:title" content={title} />
        </Helmet>

        <Section className={styles.content}>
          <div className={styles.link} onClick={() => navigate(-1)}>
            Back
          </div>
          <h1>{title}</h1>
          <div className={styles.grid}>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: description.replace(/\n/g, '<br />')
              }}
            />
            {lat > 0 && lng > 0 && (
              <div className={styles.map}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: GOOGLE_KEY }}
                  defaultCenter={defaultGoogleProps.center}
                  defaultZoom={defaultGoogleProps.zoom}
                >
                  <ChargePin lat={lat} lng={lng} />
                </GoogleMapReact>
              </div>
            )}
          </div>
          <div className={styles.gridImages}>
            {cameraUrl && (
              <div className={styles.image}>
                <img crossOrigin src={`/storage/${cameraUrl}`} alt="camera" />
              </div>
            )}
            {photoUrl && (
              <div className={styles.image}>
                <img crossOrigin src={`/storage/${photoUrl}`} alt="photoview" />
              </div>
            )}
          </div>

          {renderConnectors({
            ...station,
            userData,
            stationId,
            SERVER_URL: API_URL
          })}
        </Section>
      </section>
    );
  }

  return (
    <Section className={styles.content}>
      <h1> </h1>
      <div className={styles.link} onClick={() => navigate(-1)}>
        Back
      </div>
      <center>
        <HashLoader />
      </center>
    </Section>
  );
};

export default StationScreen;
