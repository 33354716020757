// @flow

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { PulseLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { story } from '../../../lib/api';
import styles from './story.module.scss';
import Button from '../../components/Button';

import translation from '../../../../translate';

const StoryScreen = (): any => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const common = useSelector(({ common: comm }: any) => comm);
  const { language, API_URL } = common;

  const renderStories = (list: any = []) => (
    <ul className={styles.list}>
      {list.map(
        ({
          title,
          id,
          status,
          createdAt,
          description,
          EVRoutePoints,
          EVRouteCountries
        }) => {
          const date = moment(createdAt).format('DD/MM/YYYY, HH:mm');

          return (
            <li key={id}>
              <Button
                size="medium"
                className={styles.editButton}
                onClick={() => navigate(`/${common.language}/stories/${id}`)}
              >
                {translation('_EDIT_STORY', language)}
              </Button>
              <span className={status ? styles.published : styles.unpublished}>
                {status
                  ? translation('_PUBLISHED_STATUS', language)
                  : translation('_UNPUBLISHED_STATUS', language)}
              </span>
              <h1>{title}</h1>
              <div className={styles.description}>{description}</div>
              <div className={styles.countries}>
                {EVRouteCountries.map(item => (
                  <span>{item}</span>
                ))}
              </div>
              <div className={styles.date}>
                {date}
                <span className={styles.points}>
                  <span>{EVRoutePoints.length}</span>&nbsp;
                  {translation('_CHARGING_POINTS', language)}
                </span>
              </div>
            </li>
          );
        }
      )}
    </ul>
  );

  const handleLoad = async () => {
    setLoading(true);
    const response = await story.list(API_URL);
    setLoading(false);
    setData(response);
    return true;
  };

  useEffect(() => {
    const fetchData = async () => {
      // You can await here
      await handleLoad();
    };

    fetchData();
  }, []);

  return (
    <section className={styles.content}>
      <Helmet>
        <title>
          {translation('_STORIES_HEAD_TITLE', language)} - EV-UA.NET
        </title>
        <meta property="og:title" content="EV-UA.NET" />
        <meta property="og:image" content="/assets/svg/logo.svg" />
        <meta
          property="description"
          content="Charging network in Ukraine - you can be part of the future"
        />
      </Helmet>

      <Button
        className={styles.addButton}
        onClick={() => {
          navigate(`/${common.language}/stories/add/`);
        }}
      >
        {translation('_ADD_STORY', language)}
      </Button>

      <h2>{translation('_STORIES', language)}</h2>
      <p>{translation('_STORIES_TEXT', language)}</p>

      {isLoading && <PulseLoader color="#ffb700" size={10} />}
      {data && renderStories(data)}
    </section>
  );
};

export default StoryScreen;
