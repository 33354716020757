// /* global process */

import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import Section from '../../components/Section';
import Input from '../../components/Input';
import Button from '../../components/Button';
import * as ChargeIcons from '../../components/TypeIcons';
import { setStationsData } from '../../store/actions/stations';

import styles from './styles.module.scss';

import Charging from '../../assets/svg/charging-station-flat.svg';

import {
  initiateSocketConnection,
  disconnectSocket,
  joinRoom
} from '../../sockets';

import { STATUS_CHARGING } from '../default-store';

// const { WSHOST, WSUIPORT } = process.env;

const statusToStyles = data => {
  const out = {};

  if (data) {
    Object.keys(data).forEach(key => {
      out[key] = styles[data[key]] || '';
    });
  }

  return out;
};

const renderCurrentInfo = ({ id, type, style }) => {
  // TODO: add type
  const IconImage = ChargeIcons[type] || ChargeIcons.Type1;

  return (
    <div key={id}>
      <IconImage className={`${styles.svgIcon} ${style}`.trim()} />
    </div>
  );
};

const renderConnectors = connectorStates => {
  const { connectors, current, StationPorts } = connectorStates;
  const styled = statusToStyles(connectors);

  if (!connectors) {
    return null;
  }

  const keys = Object.keys(connectors);

  const displayConnectors = keys
    .map(id => {
      const portInfo = StationPorts.find(
        ({ connectorId }) => Number(connectorId) === Number(id)
      );

      if (!portInfo) {
        return false;
      }

      return {
        id,
        current: _.get(current, id),
        type: _.get(portInfo, 'portType'),
        status: _.get(connectors, id),
        style: _.get(styled, id)
      };
    })
    .filter(x => !!x)
    .sort((a, b) => {
      if (a.status === STATUS_CHARGING && b.status !== STATUS_CHARGING) {
        return -1;
      }

      return 0;
    });

  return (
    <ul className={styles.connectors}>
      {displayConnectors.map(connector => {
        const { id } = connector;

        return <li key={id}>{renderCurrentInfo(connector)}</li>;
      })}
    </ul>
  );
};

const renderOneTag = tag => (
  <div className={styles.tag} key={tag}>
    {tag}
  </div>
);

const renderStation = (data, language) => {
  const {
    slug: stationId,
    title,
    address,
    country,
    description,
    tags = [],
    lastActivity = {}
  } = data;

  const activity = Object.keys(lastActivity).map(key => {
    const { date, maxPower } = lastActivity[key];
    return {
      portId: key,
      date: moment(date).format('MMM Do YYYY, HH:mm'),
      maxPower
    };
  });

  return (
    <div key={stationId} className={styles.oneStation}>
      <Link to={`/${language}/station/${stationId}`}>
        <img src={Charging} className={styles.icon} alt="Charging" />
      </Link>
      <div className={styles.info}>
        <div className={styles.title}>
          <Link to={`/${language}/station/${stationId}/`}>
            {title} ({stationId})
          </Link>
        </div>
        {country && (
          <div className={styles.address}>
            {country}, {address}
          </div>
        )}

        {tags && (
          <div className={styles.tags}>
            {_.map(tags, one => renderOneTag(one))}
          </div>
        )}

        {description && <div className={styles.description}>{description}</div>}

        {activity.length > 0 && (
          <div className={styles.activity}>
            {activity.map(({ date, portId, maxPower }) => (
              <div key={portId}>
                [{portId}] {date} ({maxPower} kW/h)
              </div>
            ))}
          </div>
        )}
        <div>{renderConnectors(data)}</div>
      </div>
    </div>
  );
};

const StationListScreen = () => {
  const getStore = useSelector(state => state.stations);
  const common = useSelector(state => state.common);

  const { API_URL, language } = common;

  const stations = getStore ? Object.keys(getStore) : [];
  const [isShortView, setShortView] = useState(true);
  const dispatch = useDispatch();
  const socketRef = useRef(null);

  useEffect(() => {
    socketRef.current = initiateSocketConnection(API_URL);

    joinRoom({
      name: 'all',
      socket: socketRef.current,
      onMessage: data => {
        if (data) {
          const { action, data: payload } = data;
          console.log(data);

          if (action === 'state' && data) {
            // console.log(`[all] initial state`);
            // console.log(data);
            dispatch(setStationsData(payload));
          }
        }
      }
    });

    return () => {
      disconnectSocket({ socket: socketRef.current });
    };
  }, []);

  const listStyle = `${styles.stationList} ${
    isShortView ? styles.stationListShort : ''
  }`.trim();

  const navigate = useNavigate();

  return (
    <section>
      <Section className={styles.section}>
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.link} onClick={() => navigate(-1)}>
              Back
            </div>
            <div>
              <h2>Charge stations</h2>
            </div>
            <div>
              <Input
                className={styles.search}
                placeholder="Type station code or name"
                fullWidth
              />
              <Button
                className={styles.switchButton}
                onClick={() => setShortView(!isShortView)}
              >
                Switch view
              </Button>
            </div>
          </div>

          <div className={styles.grid}>
            {stations.length > 0 && (
              <div>
                <div className={listStyle}>
                  {stations.map(stationId =>
                    renderStation(getStore[stationId], language)
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Section>

      <Section className={styles.sand} />
    </section>
  );
};

export default StationListScreen;
