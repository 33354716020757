import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import translation from '../../../../translate';

import styles from './_points.module.scss';

const renderOnePoint = ({ title, index, onDelete, useDrag }: any): any => {
  const letterA = String.fromCharCode(index + 65);

  return (
    <li key={index} className={styles.onePoint}>
      <div className={styles.letter}>
        <h3>{letterA}</h3>
      </div>
      <div>
        {useDrag && (
          <div className={styles.deleteBtn} onClick={() => onDelete(index)} />
        )}
        <h2>{title}</h2>
      </div>
    </li>
  );
};

const Points = (props: any): any => {
  const {
    language,
    points = [],
    onDelete = () => {},
    onChange,
    useDrag = false
  } = props;

  // Function to update list on drop
  const handleDrop = droppedItem => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) {
      return;
    }

    const updatedList = [...points];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State

    if (onChange) {
      onChange(updatedList);
    }
  };

  // console.log('render points', points);
  // console.log(points.length);

  if (points.length > 0) {
    const pnt = points.map((item, index) => ({
      ...item,
      onDelete: () => onDelete(index),
      useDrag,
      index
    }));

    if (useDrag) {
      return (
        <div className={styles.content}>
          <h3>{translation('_POINTS', language)}</h3>
          <p>{translation('_POINTS_TEXT', language)}</p>
          <div>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="1">
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {pnt.map((item, index) => (
                      <Draggable
                        key={index}
                        draggableId={String(index)}
                        index={index}
                      >
                        {prov => (
                          <div
                            className={styles.item}
                            ref={prov.innerRef}
                            {...prov.dragHandleProps}
                            {...prov.draggableProps}
                          >
                            {renderOnePoint(item)}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      );
    }

    if (!useDrag) {
      return (
        <div className={styles.content}>
          <h3>{translation('_POINTS', language)}</h3>
          <div>
            {pnt.map(item => (
              <div className={styles.item}>{renderOnePoint(item)}</div>
            ))}
          </div>
        </div>
      );
    }
  }

  return null;
};

export default Points;
