import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import GoogleMapReact from 'google-map-react';
import { HashLoader } from 'react-spinners';

import Section from '../../components/Section';
import ChargePinImage from './charge-pin.svg';

import { getStationList } from '../../../lib/api';

import metaValues from '../../../config';
import styles from './map.module.scss';

const ChargePin = (props: any) => {
  const { title, onClick, showInfo, description, clickOutside, station } =
    props;

  const onClickOutsideListener = () => {
    document.removeEventListener('click', onClickOutsideListener);

    if (clickOutside && typeof clickOutside === 'function') {
      clickOutside();
    }
  };

  const link = `/station/${station.slug}`;

  return (
    <div className={styles.marker} onClick={onClick}>
      {showInfo && (
        <div
          className={styles.info}
          onMouseLeave={() => {
            document.addEventListener('click', onClickOutsideListener);
          }}
        >
          <Link to={link}>
            <h3>{title}</h3>
          </Link>
          <p>{description}</p>
          {station.StationPorts && (
            <div>
              <h3>Ports:</h3>
              <ul>
                {station.StationPorts.map(port => (
                  <li>{port.title}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
      <img src={ChargePinImage} className={styles.pin} alt={title} />
    </div>
  );
};

const MapScreen = () => {
  const common = useSelector(state => state.common);
  const { GOOGLE_KEY, API_URL } = common;
  const [showChargeInfo, setShowChargeInfo] = useState();
  const [stations, setStations] = useState();

  const title = 'Map Example';
  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;

  const defaultGoogleProps = {
    center: {
      lat: 50.3752548,
      lng: 30.8814421
    },
    zoom: 8,
    panControl: true,
    mapTypeControl: true,
    scrollwheel: true
  };

  const fetchData = async () => {
    const data = await getStationList({ API_URL });
    setStations(data);
  };

  if (!stations) {
    fetchData();
  }

  if (stations) {
    return (
      <section>
        <Helmet>
          <title>
            {title} - {defaultTitle}
          </title>
          <meta name="description" content={defaultDescription} />
          <meta property="keywords" content={defaultKeywords} />
          <meta property="og:title" content={title} />
        </Helmet>

        <Section className={styles.content}>
          <Link to="/account">Back</Link>
          <h1>{title}</h1>
          <div className={styles.map}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_KEY }}
              defaultCenter={defaultGoogleProps.center}
              defaultZoom={defaultGoogleProps.zoom}
            >
              {stations.map(station => (
                <ChargePin
                  station={station}
                  title={station.title}
                  lat={station.lat}
                  lng={station.lng}
                  description={station.description}
                  showInfo={showChargeInfo === station.id}
                  clickOutside={() => setShowChargeInfo(null)}
                  onClick={() => setShowChargeInfo(station.id)}
                />
              ))}
            </GoogleMapReact>
          </div>
        </Section>
      </section>
    );
  }

  return (
    <Section className={styles.content}>
      <h1> </h1>
      <Link to="/">Back to home</Link>
      <center>
        <HashLoader />
      </center>
    </Section>
  );
};

export default MapScreen;
