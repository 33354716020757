// @flow

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { HashLoader } from 'react-spinners';
import { useSelector } from 'react-redux';

import styles from './bill.module.scss';
import Button from '../../components/Button';

import { getTransaction } from '../../../lib/api';

const renderOneItem = (item: any) => {
  const { idleDuration, totalKwt, tarif, start, finish, amount } = item;

  const {
    id: tarifId,
    title: tarifTitle,
    priceHour = 0,
    priceStayHour = 0,
    priceKwt
  } = tarif;

  const TimeTemplate = 'DD/MM/YY, HH:mm';
  const stTime = moment(start).format(TimeTemplate);
  const fnTime = moment(finish).format(TimeTemplate);
  const tarifName = tarifId > 0 ? tarifTitle : 'Base';

  const kwt = Number((totalKwt / 1000).toFixed(2));

  return (
    <div className={styles.billRow}>
      <div className={styles.dates}>
        {stTime} - {fnTime}
      </div>
      <div>{tarifName}</div>
      <div className={styles.prices}>
        <span>{priceHour}</span>
      </div>
      <div className={styles.prices}>
        {idleDuration}
        <span>{priceStayHour}</span>
      </div>
      <div className={styles.prices}>
        {kwt}
        <span>{priceKwt}</span>
      </div>
      <div>{amount.toFixed(2)}</div>
    </div>
  );
};

type Props = {
  id: Number,
  onClose: any
};

const RenderBill = (props: Props): any => {
  const { id, onClose } = props;
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState();
  const common = useSelector(({ common: comm }: any) => comm);
  const { API_URL } = common;

  const handleReload = async () => {
    setLoading(true);
    try {
      const transactionData = await getTransaction({ id, API_URL });
      setData(transactionData);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      // You can await here
      await handleReload();
    };

    fetchData();
  }, []);

  if (isLoading || !data) {
    return (
      <div className={styles.bill}>
        <HashLoader />
      </div>
    );
  }

  if (isError || !data?.moneyData) {
    return (
      <div className={styles.bill}>
        <Button
          className={styles.closeButton}
          size="small"
          onClick={() => {
            onClose(id);
          }}
        >
          Close
        </Button>
        Transaction info is unavailable!
      </div>
    );
  }

  const { moneyData, totalAmount, userBalanceBefore, stopReason, amountLog } =
    data || { amountLog: [] };

  const { kwt } = moneyData;

  return (
    <div className={styles.bill}>
      <Button
        className={styles.closeButton}
        size="small"
        onClick={() => {
          onClose(id);
        }}
      >
        Close
      </Button>
      <h2>Bill info</h2>

      <div className={styles.head}>
        <div className={styles.kwt}>
          <span>{kwt}</span>KWh
        </div>
        <div className={styles.amount}>
          ₴<span>{totalAmount}</span>
        </div>
      </div>

      <div>
        <div className={styles.billHead}>
          <div>Dates</div>
          <div>Tariff</div>
          <div>Charge, min/$</div>
          <div>Idle, min/$</div>
          <div>Power, KWh</div>
          <div>Amount</div>
        </div>
      </div>
      {amountLog.map(renderOneItem)}

      {stopReason && <p>Stop reason - {stopReason}</p>}
      <p>Balance before - {userBalanceBefore}</p>
    </div>
  );
};

export default RenderBill;
