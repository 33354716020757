import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';
import { HashLoader } from 'react-spinners';

import Section from '../../../components/Section';
import PortTarifs from '../../../components/PortTarifs';

import styles from './station-tarifs.module.scss';

import {
  getStationInfo,
  getUserTarifs,
  setStationPortTarifs
} from '../../../../lib/api';

import metaValues from '../../../../config';

const StationTarifScreen = () => {
  const { stationId } = useParams();
  const [isSubmit, setSubmit] = useState();
  const [showSaved, setShowSaved] = useState(false);
  const [info, setInfo] = useState();
  const [stationPorts, setStationPorts] = useState();
  const [userTarifs, setUserTarifs] = useState([]);
  const { API_URL } = useSelector(state => state.common);
  const { defaultTitle } = metaValues;
  // const { userData } = useSelector(state => state.user);

  const handleSave = payload =>
    setStationPortTarifs({ API_URL, values: payload });

  useEffect(() => {
    // if (!stationInfo) {
    //   history('/manage');
    // }

    const fetchData = async () => {
      const [data, tarifs] = await Promise.all([
        getStationInfo({ API_URL, slug: stationId }),
        getUserTarifs({ API_URL })
      ]);

      const { StationPorts } = data;

      setInfo(data);
      setUserTarifs(tarifs);
      setStationPorts(StationPorts);
    };

    if (stationId) {
      // console.log('fetch data');
      fetchData();
    }
  }, [stationId]);

  const { title } = info || {};

  const metaTitle = title
    ? `${title} - Station tarifs management`
    : `Station Tarifs - ${defaultTitle}`;

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content="Top-up for user account" />
        <meta property="keywords" content="ev-charging, charge points" />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <Section className={styles.content}>
        <Link
          to="/manage"
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to station
        </Link>

        <div className={styles.grid}>
          <h2>{title}</h2>
          <h3>Station port tarifs management</h3>
        </div>

        {stationPorts && (
          <PortTarifs
            ports={stationPorts}
            showSaved={showSaved}
            userTarifs={userTarifs}
            submitting={isSubmit}
            onSubmit={async values => {
              setSubmit(true);
              const config = values.map(item => {
                const { portId, tarifIds } = item;
                return { portId, tarifIds };
              });

              await handleSave(config);
              setShowSaved(true);
              setTimeout(() => setShowSaved(false), 2000);
              setSubmit(false);
            }}
          />
        )}

        {!stationPorts && (
          <center>
            <HashLoader />
          </center>
        )}
      </Section>
    </div>
  );
};

export default StationTarifScreen;
