import React from 'react';
import { withScriptjs } from 'react-google-maps';
import { useSelector } from 'react-redux';

import Map from './map';
import styles from './map.module.scss';

const isServer = typeof window === 'undefined';

const RouteMap = (props: any): any => {
  const { userData } = useSelector(({ user }) => user);

  const { googleKey } = userData || {};
  const { routeCallback, routeDetails } = props;

  if (!isServer) {
    const { points, height = '400px', reverse } = props;

    // eslint-disable-next-line react/no-unstable-nested-components
    const MapInstance = () => (
      <Map
        points={points}
        height={height}
        reverse={reverse}
        routeDetails={routeDetails}
        routeCallback={data => {
          if (routeCallback) {
            routeCallback(data);
          }
        }}
      />
    );

    const MapLoader = withScriptjs(MapInstance);

    return (
      <div className={styles.mapContainer}>
        <MapLoader
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
          loadingElement={<div style={{ height }} />}
        />
      </div>
    );
  }

  return null;
};

export default RouteMap;
