export const STATUS_CHARGING = 'Charging';
export const STATUS_PREPARING = 'Preparing';
export const STATUS_FAULTED = 'Faulted';
export const STATUS_UNAVAILABLE = 'Unavailable';
export const STATUS_AVAILABLE = 'Available';
export const STATUS_SUSPENDED_EVSE = 'SuspendedEVSE';
export const STATUS_SUSPENDED_EV = 'SuspendedEV';
export const STATUS_FINISHING = 'Finishing';

export const defaultStore = {};
