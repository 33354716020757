// @flow
/* global process */

import { useState, useEffect } from 'react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { story } from '../../../lib/api';
import styles from './story-edit.module.scss';
import RenderOnePoint from './render-point';
import MapRoute from '../../components/RouteMap';
import Button from '../../components/Button';

import translation from '../../../../translate';

const { API_URL = 'https://api.ev-ua.net' } = process.env;

const StoryView = (): any => {
  const { id } = useParams();

  const common = useSelector(({ common: comm }: any) => comm);
  const trip = useSelector(({ evtrip: tripInfo }: any) => tripInfo);
  const { API_URL: comAPIURL, language } = common;

  const [isLoading, setLoading] = useState(false);
  const [isShowMap, setShowMap] = useState(false);
  const [data, setData] = useState(trip);
  const history = useNavigate();

  const handleLoad = async () => {
    setLoading(true);
    const response = await story.load(id, comAPIURL);
    if (response && response.id) {
      setData(response);
    }

    setLoading(false);
  };

  const renderPoints = () => {
    const { EVRoutePoints: list } = data || { EVRoutePoints: [] };

    if (!list) {
      return null;
    }

    return (
      <div className={styles.points}>
        <h3>{translation('_CHARGING_POINTS', language)}</h3>
        <ul className={styles.route}>
          {list.map(item => (
            <RenderOnePoint key={item} pointData={item} />
          ))}
        </ul>
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      // You can await here
      await handleLoad();
    };

    if (id && data && !data.title) {
      fetchData();
    }

    return () => {
      setData(null);
    };
  }, [id]);

  const {
    title,
    images,
    description,
    EVRoutePoints: points
  } = data || { images: [], title: null, points: null };
  const storyImage = images && images[0] ? images[0].src : null;

  return (
    <section className={styles.content}>
      <Helmet>
        <title>EV-Trips EV-UA.NET</title>
        <meta property="og:title" content="EV-UA.NET" />
        <meta property="og:image" content="/assets/svg/logo.svg" />
        <meta property="description" content={description} />
      </Helmet>

      <section>
        <p className={styles.link} onClick={() => history(-1)}>
          {translation('_BACK_TO_TRIPS', language)}
        </p>

        {isLoading && <PulseLoader color="#ffb700" size={10} />}

        {!isLoading && data && data.title && (
          <div>
            <Button
              className={styles.mapButton}
              onClick={() => setShowMap(!isShowMap)}
            >
              {isShowMap
                ? translation('_HIDE_MAP', language)
                : translation('_SHOW_MAP', language)}
            </Button>
            <h1>{title}</h1>
            {storyImage && (
              <div className={styles.image}>
                <img src={storyImage} alt={title} />
              </div>
            )}
            {isShowMap && (
              <div className={styles.map}>
                <MapRoute points={points} />
              </div>
            )}
            {renderPoints()}
          </div>
        )}
      </section>
    </section>
  );
};

const fetchData = async ({ req }: any): any => {
  const { params } = req;

  if (params && params[0]) {
    const id = params[0].replace('ev-trips/', '');
    const response = await story.load(id, API_URL);

    if (response && response.id) {
      return { evtrip: response };
    }
  }

  return null;
};

StoryView.fetchData = fetchData;

export default StoryView;
