import { combineReducers } from 'redux';

import common from './common';
import user from './auth';
import stations from './stations';
import evtrip from './ev-trip';
import evtrips from './ev-trips';
import evhotels from './ev-hotels';

export default combineReducers({
  common,
  stations,
  evtrip,
  evtrips,
  evhotels,
  user
});
