import * as React from 'react';

import styles from './types.module.scss';

type Props = {
  color: string,
  isActive: boolean,
  className: string
};

export const Chademo = ({
  color = 'black',
  isActive = false,
  className = ''
}: Props) => {
  const style = {
    fill: 'none',
    strokeWidth: 10,
    stroke: color
  };

  const styleHalf = {
    ...style,
    strokeWidth: 5
  };

  const styleQuad = {
    ...style,
    strokeWidth: 2.5
  };

  const styleName = isActive ? `${className} ${styles.blink}` : className;

  return (
    <svg
      viewBox="0 0 141.7323 141.7323"
      id="svg2"
      height="141.7323"
      width="141.7323"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={styleName}
    >
      <g transform="matrix(1, 0, 0, 1, -271.125275, 3)">
        <path
          id="path26"
          style={style}
          d="M 317.502 51.799 C 325.579 51.799 332.126 58.346 332.126 66.423 C 332.126 77.68 319.939 84.716 310.19 79.088 C 300.441 73.459 300.441 59.387 310.19 53.758 C 312.413 52.474 314.935 51.799 317.502 51.799 Z"
        />
        <path
          id="path28"
          style={style}
          d="M 370.687 51.799 C 378.764 51.799 385.311 58.346 385.311 66.423 C 385.311 77.68 373.125 84.716 363.375 79.088 C 353.626 73.459 353.626 59.387 363.375 53.758 C 365.598 52.474 368.12 51.799 370.687 51.799 Z"
        />
        <path
          id="path30"
          style={styleHalf}
          d="M 343.601 86.461 C 350.921 86.461 356.856 92.395 356.856 99.716 C 356.856 109.92 345.81 116.298 336.973 111.196 C 328.136 106.094 328.136 93.339 336.973 88.237 C 338.988 87.073 341.274 86.461 343.601 86.461 Z"
        />
        <path
          id="path32"
          style={styleQuad}
          d="M 343.742 87.304 C 345.299 87.304 346.561 88.566 346.561 90.123 C 346.561 92.293 344.212 93.649 342.332 92.564 C 340.453 91.479 340.453 88.767 342.332 87.682 C 342.761 87.435 343.247 87.304 343.742 87.304 Z"
        />
        <path
          id="path34"
          style={styleQuad}
          d="M 343.742 106.49 C 345.299 106.49 346.561 107.752 346.561 109.309 C 346.561 111.479 344.212 112.835 342.332 111.75 C 340.453 110.665 340.453 107.953 342.332 106.868 C 342.761 106.621 343.247 106.49 343.742 106.49 Z"
        />
        <path
          id="path36"
          style={styleQuad}
          d="M 333.725 96.897 C 335.282 96.897 336.544 98.159 336.544 99.716 C 336.544 101.886 334.195 103.242 332.316 102.157 C 330.437 101.072 330.437 98.36 332.316 97.275 C 332.744 97.028 333.23 96.897 333.725 96.897 Z"
        />
        <path
          id="path38"
          style={styleQuad}
          d="M 353.052 96.897 C 354.609 96.897 355.871 98.159 355.871 99.716 C 355.871 101.886 353.522 103.242 351.643 102.157 C 349.764 101.072 349.764 98.36 351.643 97.275 C 352.071 97.028 352.558 96.897 353.052 96.897 Z"
        />
        <path
          id="path40"
          style={styleHalf}
          d="M 343.601 19.45 C 350.921 19.45 356.856 25.385 356.856 32.706 C 356.856 42.91 345.81 49.287 336.973 44.185 C 328.136 39.083 328.136 26.328 336.973 21.226 C 338.988 20.063 341.274 19.45 343.601 19.45 Z"
        />
        <path
          id="path42"
          style={styleQuad}
          d="M 343.742 20.294 C 345.299 20.294 346.561 21.556 346.561 23.113 C 346.561 25.283 344.212 26.639 342.332 25.554 C 340.453 24.469 340.453 21.756 342.332 20.671 C 342.761 20.424 343.247 20.294 343.742 20.294 Z"
        />
        <path
          id="path44"
          style={styleQuad}
          d="M 343.742 39.48 C 345.299 39.48 346.561 40.742 346.561 42.299 C 346.561 44.469 344.212 45.825 342.332 44.74 C 340.453 43.655 340.453 40.942 342.332 39.857 C 342.761 39.61 343.247 39.48 343.742 39.48 Z"
        />
        <path
          id="path46"
          style={styleQuad}
          d="M 333.725 29.887 C 335.282 29.887 336.544 31.149 336.544 32.706 C 336.544 34.876 334.195 36.232 332.316 35.147 C 330.437 34.062 330.437 31.349 332.316 30.264 C 332.744 30.017 333.23 29.887 333.725 29.887 Z"
        />
        <path
          id="path48"
          style={styleQuad}
          d="M 353.052 29.887 C 354.609 29.887 355.871 31.149 355.871 32.706 C 355.871 34.876 353.522 36.232 351.643 35.147 C 349.764 34.062 349.764 31.349 351.643 30.264 C 352.071 30.017 352.558 29.887 353.052 29.887 Z"
        />
        <path
          id="path50"
          style={style}
          d="M 291.09 26.178 L 302.305 14.962 L 309.569 18.472 C 318.947 11.898 330.366 8.038 342.688 8.038 C 355.189 8.038 366.761 12.009 376.214 18.757 L 384.068 14.963 L 395.283 26.177 L 391.254 34.516 C 397.075 43.532 400.455 54.273 400.455 65.804 C 400.455 97.708 374.592 123.571 342.688 123.571 C 310.784 123.571 284.922 97.708 284.922 65.804 C 284.922 53.899 288.524 42.835 294.697 33.643 L 291.09 26.178 Z"
        />
      </g>
    </svg>
  );
};

export const CCS = ({
  color = 'black',
  isActive = false,
  className = ''
}: Props) => {
  const style = {
    fill: 'none',
    strokeWidth: 8,
    stroke: color
  };

  const styleHalf = {
    ...style,
    strokeWidth: 5
  };

  const styleName = isActive ? `${className} ${styles.blink}` : className;

  return (
    <svg
      viewBox="0 0 141.7323 141.7323"
      id="svg2"
      height="141.7323"
      width="141.7323"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={styleName}
    >
      <path
        id="path4"
        style={style}
        d="M 32.074 106.27 L 32.074 106.27 C 32.074 119.824 38.851 126.601 52.405 126.601 L 90.038 126.601 C 103.593 126.601 110.37 119.824 110.37 106.27 L 110.37 106.27 C 110.37 92.715 103.593 85.938 90.038 85.938 L 52.405 85.938 C 38.851 85.938 32.074 92.715 32.074 106.27 Z"
      />
      <path
        id="path6"
        style={styleHalf}
        d="M 82.46 55.092 C 86.462 55.092 89.706 58.336 89.706 62.338 C 89.706 67.916 83.667 71.403 78.837 68.614 C 74.006 65.824 74.006 58.852 78.837 56.063 C 79.938 55.427 81.187 55.092 82.46 55.092 Z"
      />
      <path
        id="path8"
        style={styleHalf}
        d="M 60.886 55.092 C 64.888 55.092 68.132 58.336 68.132 62.338 C 68.132 67.916 62.094 71.403 57.263 68.614 C 52.432 65.824 52.432 58.852 57.263 56.063 C 58.364 55.427 59.614 55.092 60.886 55.092 Z"
      />
      <path
        id="path10"
        style={styleHalf}
        d="M 50.514 37.007 C 54.516 37.007 57.76 40.251 57.76 44.253 C 57.76 49.831 51.722 53.317 46.891 50.527 C 42.061 47.739 42.061 40.766 46.891 37.977 C 47.993 37.341 49.243 37.007 50.514 37.007 Z"
      />
      <path
        id="path12"
        style={styleHalf}
        d="M 71.673 37.007 C 75.675 37.007 78.92 40.251 78.92 44.253 C 78.92 49.831 72.881 53.317 68.05 50.527 C 63.219 47.739 63.219 40.766 68.05 37.977 C 69.152 37.341 70.401 37.007 71.673 37.007 Z"
      />
      <path
        id="path14"
        style={styleHalf}
        d="M 92.416 37.007 C 96.419 37.007 99.663 40.251 99.663 44.253 C 99.663 49.831 93.624 53.317 88.793 50.527 C 83.962 47.739 83.962 40.766 88.793 37.977 C 89.895 37.341 91.144 37.007 92.416 37.007 Z"
      />
      <path
        id="path16"
        style={styleHalf}
        d="M 61.44 24.326 C 64.425 24.326 66.845 26.746 66.845 29.733 C 66.845 33.894 62.341 36.495 58.737 34.414 C 55.132 32.333 55.132 27.131 58.737 25.05 C 59.558 24.576 60.49 24.326 61.44 24.326 Z"
      />
      <path
        id="path18"
        style={styleHalf}
        d="M 82.321 24.326 C 85.307 24.326 87.727 26.746 87.727 29.733 C 87.727 33.894 83.222 36.495 79.618 34.414 C 76.014 32.333 76.014 27.131 79.618 25.05 C 80.44 24.576 81.372 24.326 82.321 24.326 Z"
      />
      <path
        id="path20"
        style={style}
        d="M 95.527 14.659 C 104.613 21.783 110.451 32.861 110.451 45.302 C 110.451 66.795 93.028 84.218 71.535 84.218 C 50.041 84.218 32.619 66.795 32.619 45.302 C 32.619 32.861 38.456 21.783 47.543 14.659 L 95.527 14.659 Z"
      />
      <path
        id="path22"
        style={styleHalf}
        d="M 53.834 95.547 C 59.658 95.547 64.379 100.268 64.379 106.092 C 64.379 114.209 55.591 119.283 48.562 115.224 C 41.532 111.165 41.532 101.018 48.562 96.96 C 50.165 96.035 51.983 95.547 53.834 95.547 Z"
      />
      <path
        id="path24"
        style={styleHalf}
        d="M 88.941 95.547 C 94.765 95.547 99.486 100.268 99.486 106.092 C 99.486 114.209 90.698 119.283 83.668 115.224 C 76.639 111.165 76.639 101.018 83.668 96.96 C 85.272 96.035 87.09 95.547 88.941 95.547 Z"
      />
    </svg>
  );
};

export const Tesla = ({
  color = 'black',
  isActive = false,
  className = ''
}: Props) => {
  const style = {
    fill: 'none',
    strokeWidth: 8,
    stroke: color
  };

  const style5 = {
    ...style,
    strokeWidth: 5
  };

  const style3 = {
    ...style,
    strokeWidth: 3
  };

  const styleName = isActive ? `${className} ${styles.blink}` : className;

  return (
    <svg
      viewBox="0 0 141.7323 141.7323"
      height="141.7323"
      width="141.7323"
      style={{ marginTop: '-5px' }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={styleName}
    >
      <path
        id="path82"
        style={style}
        d="m 123.3873,42.9313 c 2.3443,6.1013 3.6318,12.7261 3.6318,19.6529 0,30.2785 -24.5447,54.8231 -54.8232,54.8231 -30.2786,0 -54.8232,-24.5446 -54.8232,-54.8231 0,-6.4761 1.1256,-12.6882 3.1878,-18.455 1.9673,-11.1895 24.2978,-20.0123 51.5586,-20.0123 26.2802,0 47.9789,8.1995 51.2682,18.8144 z"
      />
      <path
        id="path84"
        style={style5}
        d="M 100.2756,38.5846 A 14.2673,14.2673 0 0 1 114.5429,52.852 14.2673,14.2673 0 1 1 100.2756,38.5846 Z"
      />
      <path
        id="path86"
        style={style5}
        d="M 42.607,38.5846 A 14.2673,14.2673 0 0 1 56.8743,52.852 14.2673,14.2673 0 1 1 42.607,38.5846 Z"
      />
      <path
        id="path88"
        style={style5}
        d="m 71.7582,88.0319 a 8.5465,8.5465 0 0 1 8.5465,8.5465 8.5465,8.5465 0 1 1 -8.5465,-8.5465 z"
      />
      <path
        id="path90"
        style={style3}
        d="m 49.6837,92.0452 a 4.4271,4.4271 0 0 1 4.4271,4.4271 4.4271,4.4271 0 1 1 -4.4271,-4.4271 z"
      />
      <path
        id="path92"
        style={style3}
        d="m 95.5227,92.0452 a 4.4271,4.4271 0 0 1 4.4272,4.4271 4.4271,4.4271 0 1 1 -4.4272,-4.4271 z"
      />
      <path
        id="path94"
        style={style5}
        d="m 30.3463,97.1283 c 27.9541,-24.1379 56.1934,-23.7439 84.7071,0"
      />
    </svg>
  );
};

export const Type1 = ({
  color = 'black',
  isActive = false,
  className = ''
}: Props) => {
  const style = {
    fill: 'none',
    strokeWidth: 8,
    stroke: color
  };

  const style5 = {
    ...style,
    strokeWidth: 5
  };

  const styleName = isActive ? `${className} ${styles.blink}` : className;

  return (
    <svg
      viewBox="0 0 141.7323 141.7323"
      height="141.7323"
      width="141.7323"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: '-5px' }}
      className={styleName}
    >
      <path
        id="path68"
        style={style}
        d="M 71.42 18.447 C 98.006 18.447 119.558 39.998 119.558 66.583 C 119.558 88.399 105.044 106.821 85.147 112.731 L 85.147 115.621 C 85.147 119.333 82.11 122.371 78.397 122.371 L 64.445 122.371 C 60.732 122.371 57.694 119.333 57.694 115.621 L 57.694 112.731 C 37.797 106.822 23.284 88.399 23.284 66.583 C 23.284 39.998 44.835 18.447 71.42 18.447 Z"
      />
      <path
        id="path70"
        style={style5}
        d="M 71.282 32.868 C 90.114 32.868 105.38 48.134 105.38 66.967 C 105.38 93.215 76.965 109.621 54.232 96.496 C 31.5 83.372 31.5 50.561 54.233 37.436 C 59.417 34.443 65.296 32.868 71.282 32.868 Z"
      />
      <path
        id="path72"
        style={style5}
        d="M 57.326 45.172 C 61.4 45.172 64.703 48.476 64.703 52.549 C 64.703 58.228 58.555 61.777 53.638 58.938 C 48.72 56.099 48.72 49 53.638 46.161 C 54.759 45.513 56.031 45.172 57.326 45.172 Z"
      />
      <path
        id="path74"
        style={style5}
        d="M 85.603 44.575 C 89.677 44.575 92.979 47.877 92.979 51.952 C 92.979 57.63 86.833 61.179 81.914 58.339 C 76.997 55.5 76.997 48.402 81.915 45.563 C 83.036 44.916 84.308 44.575 85.603 44.575 Z"
      />
      <path
        id="path76"
        style={style5}
        d="M 71.278 79.152 C 75.352 79.152 78.654 82.455 78.654 86.528 C 78.654 92.207 72.507 95.756 67.59 92.917 C 62.671 90.078 62.671 82.98 67.59 80.14 C 68.711 79.493 69.983 79.152 71.278 79.152 Z"
      />
      <path
        id="path78"
        style={style5}
        d="M 92.189 69.026 C 95.26 69.026 97.749 71.515 97.749 74.586 C 97.749 78.866 93.116 81.541 89.408 79.401 C 85.702 77.26 85.702 71.911 89.409 69.771 C 90.254 69.283 91.213 69.026 92.189 69.026 Z"
      />
      <path
        id="path80"
        style={style5}
        d="M 50.784 69.026 C 53.855 69.026 56.345 71.515 56.345 74.586 C 56.345 78.866 51.711 81.541 48.004 79.401 C 44.297 77.26 44.297 71.911 48.005 69.771 C 48.849 69.283 49.808 69.026 50.784 69.026 Z"
      />
    </svg>
  );
};

export const Type2 = ({
  color = 'black',
  isActive = false,
  className = ''
}: Props) => {
  const style = {
    fill: 'none',
    strokeWidth: 8,
    stroke: color
  };

  const style5 = {
    ...style,
    strokeWidth: 5
  };

  const style6 = {
    ...style,
    strokeWidth: 8
  };

  const styleName = isActive ? `${className} ${styles.blink}` : className;

  return (
    <svg
      viewBox="0 0 141.7323 141.7323"
      height="141.7323"
      width="141.7323"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: '-5px' }}
      className={styleName}
    >
      <path
        style={style5}
        d="M 86.747 79.203 C 92.474 79.203 97.115 83.846 97.115 89.572 C 97.115 97.554 88.475 102.542 81.563 98.551 C 74.65 94.561 74.65 84.582 81.563 80.592 C 83.139 79.682 84.926 79.203 86.747 79.203 Z"
      />
      <path
        style={style5}
        d="M 55.877 79.203 C 61.604 79.203 66.246 83.846 66.246 89.572 C 66.246 97.554 57.605 102.542 50.692 98.551 C 43.781 94.561 43.781 84.582 50.692 80.592 C 52.269 79.682 54.059 79.203 55.877 79.203 Z"
      />
      <path
        style={style5}
        d="M 41.038 53.325 C 46.763 53.325 51.405 57.968 51.405 63.693 C 51.405 71.675 42.765 76.663 35.854 72.673 C 28.941 68.682 28.941 58.704 35.854 54.714 C 37.429 53.803 39.217 53.325 41.038 53.325 Z"
      />
      <path
        style={style5}
        d="M 71.312 53.325 C 77.039 53.325 81.682 57.968 81.682 63.693 C 81.682 71.675 73.04 76.663 66.128 72.673 C 59.216 68.682 59.216 58.704 66.128 54.714 C 67.704 53.803 69.493 53.325 71.312 53.325 Z"
      />
      <path
        style={style5}
        d="M 100.995 53.325 C 106.72 53.325 111.363 57.968 111.363 63.693 C 111.363 71.675 102.723 76.663 95.81 72.673 C 88.898 68.682 88.898 58.704 95.81 54.714 C 97.387 53.803 99.174 53.325 100.995 53.325 Z"
      />
      <path
        style={style5}
        d="M 56.67 35.18 C 60.941 35.18 64.406 38.644 64.406 42.917 C 64.406 48.871 57.959 52.592 52.802 49.616 C 47.644 46.638 47.644 39.195 52.802 36.218 C 53.977 35.538 55.312 35.18 56.67 35.18 Z"
      />
      <path
        style={style5}
        d="M 86.549 35.18 C 90.821 35.18 94.283 38.644 94.283 42.917 C 94.283 48.871 87.838 52.592 82.681 49.616 C 77.524 46.638 77.524 39.195 82.681 36.218 C 83.857 35.538 85.19 35.18 86.549 35.18 Z"
      />
      <path
        style={style6}
        d="M 105.445 21.348 C 118.446 31.542 126.8 47.393 126.8 65.195 C 126.8 95.949 101.869 120.88 71.115 120.88 C 40.36 120.88 15.43 95.949 15.43 65.195 C 15.43 47.393 23.783 31.542 36.785 21.348 L 105.445 21.348 Z"
      />
    </svg>
  );
};

export const chademo = Chademo;
export const type2 = Type2;
export const j1772 = Type1;
export const type1 = Type1;
export const ccs2 = CCS;
export const ccs = CCS;
export const tesla = Tesla;
