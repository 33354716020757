// @flow

import React, { useEffect, useState } from 'react';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HashLoader } from 'react-spinners';
import dayjs from 'dayjs';
import * as Scroll from 'react-scroll';

// import _ from 'lodash';

import styles from './tarifs.module.scss';
import Section from '../../../components/Section';
import Button from '../../../components/Button';
import TarifForm from '../../../components/_smart/TarifForm';
import metaValues from '../../../../config';

import { getUserTarifs, deleteUserTarif } from '../../../../lib/api';

const renderValue = (value: any) => {
  if (Number(value) > 0) {
    return value;
  }

  return <span className={styles.zero}>0</span>;
};

const renderOne = ({
  item,
  setEditTarif,
  setTarifs,
  tarifs,
  API_URL,
  CurrencyMap
}: any): any => {
  const {
    title,
    id,
    startHour,
    finishHour,
    startDay,
    finishDay,
    updatedAt,
    priceKwt,
    priceHour,
    priceStayHour,
    createdAt,
    currencyCode
  } = item;

  const date = dayjs(updatedAt || createdAt).format('HH:mm, DD.MM.YYYY');
  const isHour = startHour || finishHour;
  const isDate = startDay || finishDay;

  const handleDelete = async (itemId: any) => {
    const newList = tarifs.filter(one => one.id !== itemId);
    setTarifs(newList);
    deleteUserTarif({ API_URL, id: itemId });
  };

  return (
    <li className={styles.one} key={id}>
      <div className={styles.id}>{id}</div>
      <div className={styles.icons}>
        {isHour && <span>Hours</span>}
        {isDate && <span>Date</span>}
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.price}>
        {renderValue(priceKwt)}
        <span className={styles.currency}>{CurrencyMap[currencyCode]}</span>
      </div>
      <div className={styles.price}>
        {renderValue(priceHour)}
        <span className={styles.currency}>{CurrencyMap[currencyCode]}</span>
      </div>
      <div className={styles.price}>
        {renderValue(priceStayHour)}
        <span className={styles.currency}>{CurrencyMap[currencyCode]}</span>
      </div>
      <div className={styles.date}>{date}</div>
      <div className={styles.buttons}>
        <Button
          size="small"
          onClick={() => {
            if (setEditTarif && typeof setEditTarif === 'function') {
              setEditTarif(item);
            }
          }}
        >
          Edit
        </Button>
        &nbsp;
        <Button
          color="red"
          size="small"
          onClick={() => {
            handleDelete(id);
          }}
        >
          Delete
        </Button>
      </div>
    </li>
  );
};

const AccountTarifScreen = (): any => {
  const { defaultTitle, defaultDescription, defaultKeywords } = metaValues;
  const { isFulfilled, userData } = useSelector(
    ({ user: userInfo }: any) => userInfo
  );
  const { API_URL } = useSelector(({ common: comm }: any) => comm);

  const [tarifs, setTarifs] = useState();
  const [editTarif, setEditTarif] = useState(false);

  const [refreshDate, setRefreshDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUserTarifs({ API_URL });
      setTarifs(data);
    };

    fetchData();
  }, [refreshDate]);

  if (!isFulfilled || !tarifs) {
    return (
      <Section className={styles.container}>
        <div className={styles.content}>
          <Link to="/account">Back to account</Link>
          <h1>Tarifs</h1>

          <center>
            <HashLoader />
          </center>
        </div>
      </Section>
    );
  }

  const { Currencies } = userData;
  const CurrencyMap = Currencies.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.code]: cur.sign
    }),
    {}
  );

  return (
    <Section className={styles.container}>
      <Helmet>
        <title>Tarifs operations - {defaultTitle}</title>
        <meta name="description" content={defaultDescription} />
        <meta property="keywords" content={defaultKeywords} />
        <meta property="og:title" content={defaultTitle} />
      </Helmet>

      <div className={styles.content}>
        <Link
          to="/manage"
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to stations
        </Link>
        <h1>Tarifs</h1>

        {!editTarif && (
          <div>
            <Button onClick={() => setEditTarif(true)}>+ Add</Button>

            <ul className={styles.list}>
              <li className={`${styles.one} ${styles.header}`} key={0}>
                <div className={styles.id}>ID</div>
                <div className={styles.icons}>Type</div>
                <div className={styles.title}>Title</div>
                <div className={styles.price}>Price (kwt)</div>
                <div className={styles.price}>Price (hr)</div>
                <div className={styles.price}>Price (idle, hr)</div>
                <div className={styles.date}>Date</div>
                <div className={styles.buttons}>Actions</div>
              </li>
              {tarifs.map((item: any) =>
                renderOne({
                  item,
                  setEditTarif,
                  setTarifs,
                  tarifs,
                  API_URL,
                  CurrencyMap
                })
              )}
            </ul>
          </div>
        )}

        {editTarif && (
          <TarifForm
            onSubmit={() => {
              setRefreshDate(new Date());
              setEditTarif(false);
            }}
            values={editTarif}
            data={{ API_URL, Currencies }}
          />
        )}
      </div>
    </Section>
  );
};

export default AccountTarifScreen;
