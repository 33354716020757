import { googleLogin, getUser, setUser } from '../../../lib/api';

import {
  USER_LOGIN,
  USER_LOGGED_OUT,
  GET_USER_DATA,
  SET_USER_STATE,
  SET_USER_DATA,
  LANGUAGE_CHANGE,
  THEME_CHANGE
} from './types';

export const setUserState = payload => ({
  type: SET_USER_STATE,
  payload
});

const isServer = typeof window === 'undefined';

export const loginFlow = (endpoint, API_URL, credentials) =>
  endpoint({ ...credentials, API_URL }).then(user => {
    const { accessToken } = user.userData;
    if (!isServer) {
      localStorage.setItem('userJWT', accessToken);
    }

    return user;
  });

export const googleLoginAction = (API_URL, credentials) => {
  const payload = loginFlow(googleLogin, API_URL, credentials);
  return {
    type: USER_LOGIN,
    payload
  };
};

export const userLoggedOut = () => {
  // console.log('userLoggedOut');
  // console.log(isServer);

  if (localStorage) {
    localStorage.removeItem('userJWT');
  }

  return {
    type: USER_LOGGED_OUT,
    payload: null
  };
};

export const themeChange = theme => ({
  type: THEME_CHANGE,
  payload: theme
});

export const languageChange = language => ({
  type: LANGUAGE_CHANGE,
  payload: language
});

export const setUserData = ({ API_URL, values }) => ({
  type: SET_USER_DATA,
  payload: setUser({ API_URL, values })
});

export const getUserData = ({ API_URL }) => ({
  type: GET_USER_DATA,
  payload: getUser({ API_URL })
});
