// @flow

import React from 'react';

import styles from './account-cart.module.scss';

import UserIcon from '../../assets/svg/avatar.svg';

const AccountCart = (props: any): any => {
  const {
    className,
    CurrencyAccounts = [],
    currency,
    userData: { id, firstName, lastName }
  } = props;

  const current = CurrencyAccounts.find(x => x.Currency.code === currency);

  let currencySign;
  let currencyBalance;

  if (current) {
    currencySign = current.Currency.sign;
    currencyBalance = current.balance;
  }

  return (
    <div className={`${styles.balance} ${className}`}>
      <div className={styles.user}>
        <img src={UserIcon} alt="user" />
      </div>
      <div className={styles.username}>
        {firstName} {lastName}
      </div>
      <div className={styles.amount}>
        <span>{currencySign}</span>
        {currencyBalance}
      </div>
      <div className={styles.id}>{id}</div>
    </div>
  );
};

export default AccountCart;
