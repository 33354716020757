// @flow
/* global process */

import * as React from 'react';
import { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import moment from 'moment';
// import { PulseLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { trips } from '../../../lib/api';
import styles from './hotels.module.scss';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';

const { API_URL = 'https://api.ev-ua.net' } = process.env;

const HotelsScreen = (): any => {
  const common = useSelector(({ common: comm }: any) => comm);
  const preloadTrips = useSelector(({ evhotels }: any) => evhotels);
  const { API_URL: stateAPIURL } = common;

  // const [isLoading, setLoading] = useState(false);
  const [, setLoading] = useState(false);
  const [data, setData] = useState(preloadTrips);
  const [countryId, setCountryId] = useState(0);
  const [isFree, setIsFree] = useState(true);
  const [page] = useState(0);
  // const [page, setPage] = useState(0);

  const renderHotels = (list: any): any => {
    const listHotels = list || [];

    return (
      <ul className={styles.list}>
        {listHotels.map(
          ({
            title,
            images,
            id,
            // createdAt,
            // description,
            chargeSpeed,
            EVRouteCountry
          }) => {
            // const date = moment(createdAt).format('DD/MM/YYYY, HH:mm');
            const image = images[0] ? images[0].src : '';
            // const link = `/ev-hotels/${id}`;

            return (
              <li key={id}>
                {image && (
                  <div className={styles.image}>
                    <img src={image} alt={title} />
                  </div>
                )}
                <div className={styles.info}>
                  <h1>{title}</h1>
                  {/*
                  <div className={styles.description}>{description}</div>
                  */}

                  <div className={styles.countries}>
                    <span>{chargeSpeed} kWh</span>
                  </div>

                  {EVRouteCountry && (
                    <div className={styles.countries}>
                      <span onClick={() => setCountryId(EVRouteCountry.id)}>
                        {EVRouteCountry.title}
                      </span>
                    </div>
                  )}
                </div>
              </li>
            );
          }
        )}
      </ul>
    );
  };

  const fetchData = async () => {
    setLoading(true);
    const response = await trips.hotels(
      { isFree, countryId },
      API_URL || stateAPIURL
    );

    setLoading(false);

    if (response && response.hotels) {
      setData(response.hotels);
    }

    if (window) {
      const params = { isFree };

      if (countryId > 0) {
        // $FlowFixMe
        params.countryId = countryId;
      }

      window.history.replaceState(
        null,
        null,
        // $FlowFixMe
        `?${new URLSearchParams(params)}`
      );
    }

    return true;
  };

  useEffect(() => {
    const fetch = async () => {
      // You can await here
      await fetchData();
    };

    fetch();
  }, [countryId, isFree, page]);

  // if (isLoading) {
  //   return null;
  // }

  return (
    <section className={styles.content}>
      <Helmet>
        <title>EV-UA.NET - EV-Hotels</title>
        <meta property="og:title" content="EV-UA.NET" />
        <meta property="og:image" content="/assets/svg/logo.svg" />
        <meta
          property="description"
          content="Charging network in Ukraine - you can be part of the future"
        />
      </Helmet>

      <h2>EV Hotels</h2>
      {countryId > 0 && (
        <div>
          <Button size="small" onClick={() => setCountryId(0)}>
            Clear country filter
          </Button>
        </div>
      )}

      <Checkbox
        id="isFree"
        checked={isFree}
        onChange={() => setIsFree(!isFree)}
      >
        Show only free charge points
      </Checkbox>

      {Array.isArray(data) && renderHotels(data)}
    </section>
  );
};

const fetchData = async (): any => {
  try {
    const response = await trips.hotels({}, API_URL);
    if (response && response.hotels) {
      return { evhotels: response.hotels };
    }

    return { evhotels: [] };
  } catch (e) {
    console.log(e.message);
    return { evhotels: [] };
  }
};

HotelsScreen.fetchData = fetchData;

export default HotelsScreen;
