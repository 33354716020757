import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/(^[A-z ]+$)/, 'Please, fill only latin chars')
    .required('Please, provide first name'),
  lastName: Yup.string()
    .matches(/(^[A-z ]+$)/, 'Please, fill only latin chars')
    .required('Please, provide last name')
});

export default validationSchema;
