import axios from 'axios';
import qs from 'querystring';
import _ from 'lodash';

// import config from '../config';

const isServer = typeof window === 'undefined';

const endpointUrl = (url, API_URL) => {
  const result = `${API_URL}${url}`;
  // console.log(result);
  return result;
};

const addAuthHeaders = (options = {}) => {
  const token =
    !isServer && localStorage ? localStorage.getItem('userJWT') : null;

  if (!token) {
    return {};
  }

  return {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept'
    }
  };
};

const loadWithParams = (url, values) => {
  const { API_URL } = values;
  const params = qs.stringify(_.omit(values, 'API_URL'));
  return axios
    .get(endpointUrl(`${url}?${params}`, API_URL), addAuthHeaders())
    .then(response => response.data);
};

export const apiURL = endpointUrl;

export const googleLogin = params => {
  const { API_URL } = params;

  return axios
    .post(endpointUrl('/auth/google', API_URL), params)
    .then(response => response.data);
};

export const getTransaction = params => {
  const { API_URL, id } = params;
  // console.log(endpointUrl(`/transaction/${id}`, API_URL));

  return axios
    .get(endpointUrl(`/transaction/${id}`, API_URL), addAuthHeaders())
    .then(response => response.data)
    .catch(error => error);
};

export const getChargers = params => {
  const { API_URL, lat, lng, types } = params;
  return axios
    .get(endpointUrl(`/charge/now?lat=${lat}&lng=${lng}${types}`, API_URL))
    .then(response => response.data);
};

export const getEFChargers = params => {
  const { API_URL, lat, lng } = params;
  return axios
    .get(
      endpointUrl(`/charge/find?lat=${lat}&lng=${lng}`, API_URL),
      addAuthHeaders()
    )
    .then(response => response.data);
};

export const sendMessage = params => {
  const { API_URL, message } = params;
  return axios
    .post(endpointUrl('/send-message', API_URL), { message }, addAuthHeaders())
    .then(response => response.data);
};

export const sendTransactionData = params => {
  const { SERVER_URL, connectorId, stationId, action } = params;

  return axios
    .post(
      endpointUrl('/transaction', SERVER_URL),
      { connectorId, stationId, action },
      addAuthHeaders()
    )
    .then(response => response.data);
};

export const getStationData = params => {
  const { SERVER_URL, stationId } = params;
  return axios
    .get(endpointUrl(`/clients/${stationId}`, SERVER_URL), addAuthHeaders())
    .then(response => response.data);
};

export const getUserPayments = params => {
  const { API_URL } = params;
  return axios
    .get(endpointUrl('/account/operations', API_URL), addAuthHeaders())
    .then(response => response.data);
};

export const getUserTarifs = params => {
  const { API_URL } = params;
  return axios
    .get(endpointUrl('/tarifs', API_URL), addAuthHeaders())
    .then(response => response.data);
};

export const deleteUserTarif = params => {
  const { API_URL, id } = params;
  return axios
    .delete(endpointUrl(`/tarifs/${id}`, API_URL), addAuthHeaders())
    .then(response => response.data);
};

export const updateUserTarif = params => {
  const { API_URL, values } = params;
  return axios
    .post(endpointUrl('/tarifs', API_URL), values, addAuthHeaders())
    .then(response => response.data);
};

export const setUser = params => {
  const { API_URL, values } = params;
  return axios
    .put(endpointUrl('/account', API_URL), values, addAuthHeaders())
    .then(response => response.data);
};

export const setStationData = params => {
  const { API_URL, values } = params;
  return axios
    .put(endpointUrl('/station', API_URL), values, addAuthHeaders())
    .then(response => response.data);
};

export const getStationProfit = params => {
  const { API_URL } = params;

  return axios
    .post(
      endpointUrl('/account/manage/station-profit', API_URL),
      params,
      addAuthHeaders()
    )
    .then(response => response.data);
};

export const setStationPorts = params => {
  const { API_URL, values } = params;
  return axios
    .post(
      endpointUrl('/account/manage/station-ports', API_URL),
      values,
      addAuthHeaders()
    )
    .then(response => response.data);
};

export const setStationPortTarifs = params => {
  const { API_URL, values } = params;
  return axios
    .post(
      endpointUrl('/account/manage/station-tarifs', API_URL),
      values,
      addAuthHeaders()
    )
    .then(response => response.data);
};

export const saveProfitInfo = params => {
  const { API_URL, values } = params;
  return axios
    .put(
      endpointUrl('/account/manage/station-profit', API_URL),
      values,
      addAuthHeaders()
    )
    .then(response => response.data);
};

export const getUser = params => {
  const { API_URL } = params;
  return axios
    .get(endpointUrl('/account', API_URL), addAuthHeaders())
    .then(response => response.data);
};

export const getStationInfo = params => {
  const { API_URL, slug } = params;
  const headers = addAuthHeaders();

  return axios
    .get(endpointUrl(`/station/${slug}`, API_URL), headers)
    .then(response => response.data);
};

export const getStationOutcomes = params => {
  const { API_URL, slug } = params;
  const headers = addAuthHeaders();
  const url = `/account/manage/outcome/${slug}`;

  return axios
    .get(endpointUrl(url, API_URL), headers)
    .then(response => response.data);
};

export const deleteStationOutcome = params => {
  const { API_URL, slug, id } = params;
  const headers = addAuthHeaders();
  const url = `/account/manage/outcome/${slug}`;

  return axios
    .delete(endpointUrl(url, API_URL), { ...headers, data: { id } })
    .then(response => response.data);
};

export const getStationList = params => {
  const { API_URL } = params;
  const headers = addAuthHeaders();
  const url = '/station-list';

  return axios
    .get(endpointUrl(url, API_URL), headers)
    .then(response => response.data);
};

export const energy = {
  day: values => loadWithParams('/energy', values),
  month: values => loadWithParams('/energy/month', values),
  year: values => loadWithParams('/energy/by-month', values)
};

export const common = {
  upload: (params, API_URL) =>
    axios
      .post(endpointUrl('/upload', API_URL), params, addAuthHeaders())
      .then(response => response.data)
};

export const routes = {
  match: (values, API_URL) =>
    axios
      .post(endpointUrl('/routes/match', API_URL), values, addAuthHeaders())
      .then(response => response.data),
  remove: ({ API_URL, id }) =>
    axios.delete(endpointUrl(`/routes/${id}`, API_URL), addAuthHeaders()),
  load: ({ API_URL, id }) =>
    axios
      .get(endpointUrl(`/routes/${id}`, API_URL), addAuthHeaders())
      .then(response => response.data),
  list: API_URL =>
    axios
      .get(endpointUrl('/routes', API_URL), addAuthHeaders())
      .then(response => response.data),
  saveRoute: (values, API_URL) =>
    axios
      .post(endpointUrl('/routes', API_URL), values, addAuthHeaders())
      .then(response => response.data)
};

export const trips = {
  hotels: (values, API_URL) =>
    loadWithParams('/ev-hotels', { ...values, API_URL }),
  list: (values, API_URL) => loadWithParams('/ev-trips', { ...values, API_URL })
};

export const story = {
  list: API_URL =>
    axios
      .get(endpointUrl('/stories', API_URL), addAuthHeaders())
      .then(response => response.data),
  load: (id, API_URL) =>
    axios
      .get(endpointUrl(`/stories/${id}`, API_URL), addAuthHeaders())
      .then(response => response.data),
  save: (values, API_URL) =>
    axios
      .post(endpointUrl('/stories', API_URL), values, addAuthHeaders())
      .then(response => response.data),
  pointLoad: (storyId, pointId) =>
    axios
      .get(
        endpointUrl(`/stories/${storyId}/point/${pointId}/`),
        addAuthHeaders()
      )
      .then(response => response.data),
  deletePoint: (values, API_URL) =>
    axios
      .delete(endpointUrl('/stories/point', API_URL), {
        ...addAuthHeaders(),
        data: values
      })
      .then(response => response.data),
  savePoint: (values, API_URL) =>
    axios
      .post(endpointUrl('/stories/point', API_URL), values, addAuthHeaders())
      .then(response => response.data)
};
