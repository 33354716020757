import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';

import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import Section from '../../components/Section';
import styles from './charge.module.scss';
import { sendMessage } from '../../../lib/api';

import metaValues from '../../../config';

const getJsonIndented = obj => JSON.stringify(obj, null, 4).replace(/"\]/g, '');

const JSONDisplayer = ({ children }: any) => (
  <div>
    <pre>{getJsonIndented(children)}</pre>
  </div>
);

const ChartsScreen = () => {
  const { defaultTitle } = metaValues;
  const common = useSelector(state => state.common);
  const { API_URL } = common;

  const exampleStation = {
    sid: '1',
    name: 'CHARGER NAME',
    address: 'charger address',
    lat: 0.0,
    lng: 0.0
  };

  const examplePorts = {
    sid: '1',
    ports: [
      {
        name: 'CCS2',
        price: '9.00 UAH',
        status: 'charging',
        time: '00:12:00',
        percent: 68,
        currentPower: 110,
        maxPower: 120
      },
      {
        name: 'Chademo',
        price: '4.50 UAH',
        status: 'available',
        maxPower: 50
      }
    ]
  };

  const exampleCharging = {
    ...exampleStation,
    ...examplePorts
  };

  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [text, setText] = useState();
  const [error, setError] = useState();
  const [isSent, setSent] = useState();

  const [way1, switchWay1] = useState(false);
  const [way2, switchWay2] = useState(false);
  const [way3, switchWay3] = useState(false);

  const handleSend = async () => {
    const message = {
      email,
      phone,
      text
    };

    try {
      const { sent } = await sendMessage({ API_URL, message });
      if (sent) {
        setSent(true);
      }
    } catch (err) {
      const { message: errMsg } = err;
      setError(errMsg);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Add your chargers to our search - {defaultTitle}</title>
        <meta
          name="description"
          content="There you can add your chargers to our search"
        />
        <meta property="keywords" content="ev-charging, charge points" />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:image" content="/assets/svg/monitor-window.svg" />
      </Helmet>

      <Section className={styles.content}>
        <Link
          to="/chargers"
          onClick={() => Scroll.animateScroll.scrollToTop({ duration: 100 })}
        >
          Back to home
        </Link>
        <h1>Add stations for our monitoring </h1>
        <p>You can easy add your charging network to our monitoring system.</p>
        <p>
          There are a few ways how you can send stations data to our system.
        </p>

        <div className={styles.howTo}>
          <h3>How to share your statuses:</h3>
          <ul>
            <li>
              1. Provide an access to endpoint that will be providing to our
              system data about station states and we&apos;ll create convertion
              mapper for your data to our system (it will take around 10-30
              minutes on our end)
            </li>
            <li>
              2. You can send data to us in 3 different ways (with API
              integration key)
            </li>
            <code>
              <JSONDisplayer>
                [POST] https://api.ev-ua.net/send-station-state
              </JSONDisplayer>
            </code>
            <h3>
              Way #1
              <Button onClick={() => switchWay1(!way1)} size="small">
                Show/hide
              </Button>
            </h3>
            {way1 && (
              <div>
                <p>
                  You can send full station information (station info and port
                  states):
                </p>
                <code>
                  <JSONDisplayer>{exampleCharging}</JSONDisplayer>
                </code>
              </div>
            )}

            <h3>
              Way #2
              <Button onClick={() => switchWay2(!way2)} size="small">
                Show/hide
              </Button>
            </h3>
            {way2 && (
              <div>
                <p>
                  You can send station information (it will update/create absent
                  station record):
                </p>
                <code>
                  <JSONDisplayer>{exampleStation}</JSONDisplayer>
                </code>
              </div>
            )}

            <h3>
              Way #3
              <Button onClick={() => switchWay3(!way3)} size="small">
                Show/hide
              </Button>
            </h3>
            {way3 && (
              <div>
                <p>You can send just port states info for one station:</p>
                <JSONDisplayer>{examplePorts}</JSONDisplayer>
              </div>
            )}

            <li>3. All other needed action we&apos;ll do on our side</li>
            <li>4. Users will see your stations in search results</li>
          </ul>
        </div>

        {!isSent && !error && (
          <div>
            <h2>Send message to us</h2>
            <Input
              name="email"
              placeholder="Your email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
            <Input
              name="phone"
              value={phone}
              placeholder="Your phone (telegram, viber)"
              onChange={event => setPhone(event.target.value)}
            />

            <Textarea
              name="text"
              placeholder="Your message"
              value={text}
              onChange={event => setText(event.target.value)}
            />
            <Button onClick={() => handleSend()}>Send</Button>
          </div>
        )}

        {isSent && (
          <div className={styles.notification}>Your message was sent</div>
        )}
        {error && (
          <div className={styles.notification}>
            Can&apos;t send the message due to error
          </div>
        )}
      </Section>
    </div>
  );
};

export default ChartsScreen;
